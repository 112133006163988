import { useContext, useState } from "react";
import Cookies from "js-cookie";
import Swal from "sweetalert2";
import apiUrls from "../../ApiUrl";
import "../Modals.css";
import "../Routers.css";
import { SoundMainContext } from "./Sound";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
export default function AddSound({ edit }) {
    const { showComponent, setShowComponent, formData, setFormData, retryCount, setRetryCount, items, formDataCheck, setFormDataCheck } = useContext(SoundMainContext);
    const [onHole, setOnHole] = useState(true);
    const [onFetch, setOnFetch] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const [error, setError] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const token = Cookies.get("accessToken");

    const handleChange = (event) => {
        setError(false);
        const { name, value } = event.target;
        if (name === "fileName") {
            const file = event.target.files[0];
            setFormData((prevFormData) => ({
                ...prevFormData,
                ["fileName"]: file,
            }));
        } else {
            setFormData((prevFormData) => ({
                ...prevFormData,
                [name]: value,
            }));
        }
    };

    function SubmitData() {
        // set data body to fetch
        const formDataSound = new FormData();
        if (formData.fileName) { formDataSound.append("fileName", formData.fileName); }
        formDataSound.append("sound_name", formData.sound_name);
        // ======================

        const options = {
            method: `${edit ? "PUT" : "POST"}`,
            headers: {
                // "content-type": "multipart/form-data;",
                // "content-length": `${fileName.size + soundName.length}`, // 👈 Headers need to be a string
                Authorization: `Bearer ${token}`,
            },
        };

        setOnFetch(true);
        fetch(apiUrls.Sound, { ...options, body: formDataSound })
            .then((response) => response.json())
            .then((result) => {
                if (result.created_at) {
                    console.log("organization success submit:", result);
                    Swal.fire({
                        icon: "success",
                        title: "Upload Successful!",

                    });
                    setRetryCount(retryCount + 1);
                    setOnFetch(false);
                    apiUrls.setUserRequest3("");
                    closeModals();
                } else {
                    console.log("organization error submit:", result);
                    Swal.fire({
                        icon: "error",
                        title: "Upload Failed",

                    });
                    setOnFetch(false);
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            })
            .finally(() => {
                setOnFetch(false);
            });
    }

    const handleModalClick = (e) => {
        e.stopPropagation();
        setOnHole(true);
    };

    const closeModal = () => {
        if (onHole !== false) {
            setShowComponent(false);
        }
        setOnHole(true);
    };

    const closeModals = () => {
        setShowComponent(false);
    };

    const CheckSubmit = () => {
        let keysExist = false;
        const areAllInputsFilled = Object.values(formData).every((input) => input !== "");
        if (areAllInputsFilled && errorMessage === false) {
            setError(false);
            Object.keys(formData).forEach((key) => {
                if (formData[key] === formDataCheck[key]) {
                }
                else if (error != "Password-Fail") {
                    keysExist = true;
                }
            })
            if (keysExist) {
                SubmitData();
            }
            else {
                setError("No Edit");
            }
        }
        else {
            setError(true);
        }
    }

    const Unique = () => {
        const Found = items.find(item => item.sound_name.toUpperCase() === formData.sound_name.toUpperCase());
        if (Found && edit === false) {
            setErrorMessage(true);
        } else {
            setErrorMessage(false);
        }
    }
    console.log(formData)

    return (
        <>
            {showComponent && (
                <div className="overlay" onClick={closeModal}>
                    <div className="bodyModal " aria-autocomplete="off">
                        <div
                            className="modals-password"
                            onClick={handleModalClick}
                            onMouseDown={() => setOnHole(false)}
                            onMouseUp={() => setOnHole(false)}
                        >
                            <p className="topic-modal">
                                <img src="/image/volume.png" alt="sound" width="18px" />{" "}
                                Add notification sound
                                <img
                                    className="close-modal"
                                    src="/image/close-button.png"
                                    alt="close"
                                    width="18px"
                                    onClick={closeModals}
                                />
                            </p>
                            <hr />
                            <label>Sound name</label>
                            <input
                                type="text"
                                name="sound_name"
                                onChange={handleChange}
                                onBlur={Unique}
                                value={formData.sound_name || ""}

                            />

                            {errorMessage && <div className="erorr-message ">Sound name already exists</div>}

                            <div className="file-input-container">
                                <label htmlFor="inputFile" className="file-input-label">
                                    <CloudUploadIcon />
                                    {formData.fileName ? formData.fileName.name : 'Upload File'}
                                </label>
                                <input
                                    type="file"
                                    accept=".wav,.mp3"
                                    name="fileName"
                                    onChange={handleChange}
                                    id="inputFile"
                                    style={{ display: 'none' }}
                                />
                            </div>
                            {error === true && <div className="erorr-message ">Please enter all information.</div> || error === "No Edit" && <div className="erorr-message ">The information has not changed.
                            </div>}

                            <button
                                className="submit-btn"
                                onClick={CheckSubmit}
                                disabled={onFetch}
                            >
                                {onFetch ? <span className="loader2"></span> : "SUBMIT"}
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}
