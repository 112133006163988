import React, { createContext, useEffect, useState } from "react";
import "../Routers.css";
import DataTable from 'react-data-table-component';
import Cookies from 'js-cookie';
import Container from "../Container";
import apiUrls from "../../ApiUrl";
import { customStyles } from "../DataTableStyle";
import UpdateDevice from "./UpdateDevice";
import CloseIcon from "@mui/icons-material/Close";
import AddDevice from "../organization/all-setting/SettingDevice/AddDevice";
import {
    Alert,
    Box,
    Button,
    Card,
    CardContent,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    IconButton,
    InputAdornment,


    List,
    ListItem,
    ListItemText,
    Modal,
    OutlinedInput,
    Skeleton,
    Snackbar,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import EditDevice from "../organization/all-setting/SettingDevice/EditDevice";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import Swal from "sweetalert2";
import { urlLightningConfig } from "../../configs/app";
import ConfirmDelete from "../ConfirmDelete";
import CopyAllIcon from "@mui/icons-material/CopyAll";
import moment from "moment";
const config = require("./../../configs/app");

const DeviceContext = createContext();
export default function DeviceStatus({ create, update, remove }) {
    const [pending, setPending] = useState(true);
    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState([]);
    const [retryCount, setRetryCount] = useState(0);
    const [errorFetch, setErrorFetch] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const [deviceOption, setDeviceOption] = useState('');
    const [isAddOpen, setIsAddOpen] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [idDelete, setIdDelete] = useState(false);
    const [isRelayOpen, setIsRelayOpen] = useState({
        open: false,
        uid: "",
    });
    const [isDelete, setIsDelete] = useState({
        open: false,
        data: {},
    });
    const [openAlert, setOpenAlert] = useState({
        open: false,
        message: "",
        severity: "",
    });
    const [isEdit, setIsEdit] = useState({
        open: false,
        id: "",
    });
    const token = (Cookies.get('accessToken'));
    const [formData, setFormData] = useState(
        {
            "nameDevice": "",
            "activeDay": "",
            "activeTimeStart": "00:00",
            "activeTimeEnd": "23:59",
            "manualOff": "",
            "statusSimulation": "",
            "lat": "",
            "lon": "",
            "keyName": "",
            "lineToken": "",
            "relay": [],
        }
    );

    const fetchData = () => {
        const url = apiUrls.Device;
        fetch(url,
            {
                method: 'GET', headers: {
                    Authorization: `Bearer ${token}`,
                }
            })
            .then(res => res.json())
            .then(data => {
                if (data.status === 200) {
                    setIsLoaded(true);
                    setItems(data);
                } else {
                    setIsLoaded(false);

                }
            })
            .catch(error => {
                setIsLoaded(false);
                setErrorFetch(error);
                const retryDelay = 3000;
                setTimeout(() => setRetryCount(retryCount + 1), retryDelay);
            });
    };

    const handleClose = () => {
        setRetryCount(retryCount + 1);
        setIsAddOpen(false);
        setIsRelayOpen({
            open: false,
            uid: "",
        });
        setIsDelete({
            open: false,
            data: {},
        });
        setIsEdit({
            open: false,
            id: "",
        });
    };

    const openDelete = (request) => {
        setShowDelete(true);
        setIdDelete(request)
    };

    async function handleDelete(request) {
        try {
            setDeleteLoading(true);
            const response = await fetch(`${config.urlDevice}/api/v1/device/${request}`, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            });
            if (response.ok) {
                Swal.fire({
                    icon: "success",
                    title: "Delete Successful!",
                    text: "Device has been Deleted",
                });
                fetchData();
            } else {
                Swal.fire({
                    icon: "error",
                    title: "Delete Failed",
                    text: "something went wrong!",
                });
            }
        } catch (error) {
            console.error("Failed to delete item:", error);
        } finally {
            setShowDelete(false);
            setDeleteLoading(false);
        }
    }

    useEffect(() => {
        fetchData();
    }, [retryCount]);

    const columns = [
        {
            name: '#',
            selector: row => row.ide,
            sortable: true,
            width: '57px'

        },
        {
            name: 'UUID',
            selector: row => row.uid,
            width: '100px'
        },
        {
            name: 'Enable',
            cell: row => {
                if (row.enable && row.enable === true) {
                    return <CheckCircleIcon color="success" fontSize="medium" />
                } else {
                    return <ErrorIcon color="error" fontSize="medium" />
                }
            }
            ,
            width: '100px'
        },
        {
            name: 'Device',
            selector: row => row.nameDevice,
            width: '160px'
        },

        {
            name: 'Last Check',
            selector: row => row.lastCheck,
            width: '200px'
        },

        // {
        //     name: 'Mode',
        //     cell: row => {
        //         if (row.statusSimulation
        //             === true) {
        //             return <span>Simulation Mode</span>;
        //         } else if (row.statusSimulation
        //             === false) {
        //             return <span>Normal Mode</span>;
        //         }
        //     },
        //     width: '150px'
        // },
        {
            name: 'Status',
            cell: (row) => {
                if (row.status === true) {
                    return <img src="/image/lamp.png" width="30px" alt="Status" />
                } else {
                    return <img src="/image/lampRed.png" width="30px" alt="Status" />
                }
            },
            width: 'auto'
        },
        // {
        //     name: 'ติดต่อล่าสุด',
        //     cell: () => '21/05/2021 15:05:42',
        //     width: '200px'
        // },
        // {
        //     name: 'การใช้งาน',
        //     cell: row => {
        //         if (row.manualOff === true) {
        //             return <span>ใช้งานอยู่</span>;
        //         } else if (row.manualOff === false) {
        //             return <span>ไม่ได้ใช้งาน</span>;
        //         }
        //     }
        // },

    ];

    if (update) {
        columns.push({
            name: "",
            cell: (row) => (
                <>
                    <button className="play-btn " onClick={() =>
                        setIsRelayOpen({
                            open: true,
                            uid: row.uid,
                        })}>RELAY</button>
                </>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            width: "90px",
        });

        columns.push({
            name: "",
            cell: (row) => (
                <>
                    <button className="edit-btn" onClick={() => setIsEdit({
                        open: true,
                        id: row.id,
                    })}>Edit</button>
                </>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            width: "90px",
        });
    }

    if (remove) {
        columns.push({
            name: "",
            cell: row => (
                <>
                    <button className="delete-btn" onClick={() => openDelete(row.id)}>Delete</button>
                </>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            width: "90px",
        },);
    }

    const CustomLoader = () => (
        <>
            <div style={{ padding: '40px' }}>
                <span className="loader"></span>
                <p>{errorFetch.message}</p>
            </div>
        </>
    );

    if (token != null && isLoaded === true) {
        const data = items.message;
        const dataWithIds = data.map((item, index) => ({ ide: index + 1, ...item }));
        const filteredData = dataWithIds.filter((item) => {
            const { keyName, nameDevice } = item;
            const searchValue = searchQuery.toLowerCase();
            return keyName.toLowerCase().includes(searchValue) || nameDevice.toLowerCase().includes(searchValue);
        });
        return (
            <DeviceContext.Provider value={{ setDeviceOption, formData, setFormData }}>
                {deviceOption === "เพิ่ม" && <UpdateDevice edit={false} /> || deviceOption === "แก้ไข" && <UpdateDevice edit={true} />}

                <Dialog
                    open={isAddOpen}
                    onClose={handleClose}
                    fullWidth
                    maxWidth="md"
                    onClick={(event, reason) => {
                        if (reason === "backdropClick") {
                            handleClose();
                        }
                    }}
                >
                    <DialogTitle>
                        <Grid container>
                            <Grid item xs={6}>
                                <span fontWeight="bold">Add Device</span>
                            </Grid>
                            <Grid item xs={6} style={{ textAlign: "right" }}>
                                <IconButton disableFocusRipple disableRipple size="small" onClick={handleClose}>
                                    <CloseIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </DialogTitle>
                    <DialogContent dividers>
                        <AddDevice admin={true} handleClose={handleClose} fetchDevice={fetchData} setAlert={setOpenAlert} />
                    </DialogContent>
                </Dialog>

                <Dialog open={isEdit.open} onClose={handleClose} fullWidth maxWidth="md">
                    <DialogTitle>
                        <Grid container>
                            <Grid item xs={6}>
                                <span fontWeight="bold">Edit Device</span>
                            </Grid>
                            <Grid item xs={6} style={{ textAlign: "right" }}>
                                <IconButton disableFocusRipple disableRipple size="small" onClick={handleClose}>
                                    <CloseIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </DialogTitle>

                    <DialogContent dividers>
                        <EditDevice id={isEdit.id} handleClose={handleClose} fetchDevice={fetchData} setAlert={setOpenAlert} />
                    </DialogContent>
                </Dialog>

                <Dialog open={isRelayOpen.open} onClose={handleClose} fullWidth maxWidth="sm">
                    <DialogTitle>
                        <Grid container>
                            <Grid item xs={6}>
                                <span fontWeight="bold">Relay Information</span>
                            </Grid>
                            <Grid item xs={6} style={{ textAlign: "right" }}>
                                <IconButton disableFocusRipple disableRipple size="small" onClick={handleClose}>
                                    <CloseIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </DialogTitle>

                    <DialogContent dividers>
                        <Relay uid={isRelayOpen.uid} handleClose={handleClose} fetchDevice={fetchData} />
                    </DialogContent>
                    <DialogActions>
                        <Button variant="text" disableRipple onClick={handleClose} color="error">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>




                <Snackbar
                    open={openAlert.open}
                    autoHideDuration={6000}
                    onClose={() => {
                        setOpenAlert({
                            open: false,
                            message: "",
                            severity: "",
                        });
                    }}
                    position="top-right"
                >
                    <Alert onClose={handleClose} severity={openAlert.severity || "success"} sx={{ width: "100%" }}>
                        {openAlert.message}
                    </Alert>
                </Snackbar>

                {showDelete ? (
                    <ConfirmDelete
                        topic="Deive"
                        closeModals={() => setShowDelete(false)}
                        confirm={() => handleDelete(idDelete)}
                        loadding={deleteLoading}
                    />
                ) : (
                    <></>
                )}

                <Container
                    title={<>Device IOT</>}
                    btn={create ? <>Add Device IOT</> : null}
                    action={() => setIsAddOpen(!isAddOpen)
                        // setDeviceOption("เพิ่ม")
                    }
                    components={
                        <>
                            <input className="Search" type="text" onChange={e => setSearchQuery(e.target.value)} placeholder="Search" />
                            <DataTable
                                columns={columns}
                                data={filteredData}
                                pagination
                                fixedHeader
                                customStyles={customStyles}
                                highlightOnHover
                                noHeader
                                striped
                            />
                        </>
                    }
                />
            </DeviceContext.Provider>
        );
    }
    else {
        return (
            <Container
                title={<>Device IOT</>}
                components={
                    <>
                        <DataTable
                            progressPending={pending}
                            progressComponent={<CustomLoader />}
                        />
                    </>
                }
            />
        )
    }
};

const Relay = ({ uid, handleClose, fetchDevice }) => {
    const [relayData, setRelayData] = useState({});
    const [isCopy, setIsCopy] = useState(false);

    const fetchRelay = () => {
        fetch(`${config.urlDevice}/api/device/send/${uid}`)
            // .then((response) => response.json())
            .then((response) => {
                if (response.status === 200) {
                    return response.json();
                } else {
                    return {};
                }
            })
            .then((res) => {
                setRelayData(res);
            })
            .catch((error) => console.error("Error fetching data:", error));
    };

    useEffect(() => {
        fetchRelay();
    }, []);

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography>Link Get Relay For Device UID: {uid}</Typography>
                    <FormControl fullWidth variant="standard">
                        <OutlinedInput
                            type="text"
                            size="small"
                            readOnly
                            value={`${config.urlDevice}/api/device/send/${uid}`}
                            endAdornment={
                                <InputAdornment position="end">
                                    <Tooltip title={isCopy ? "Copied" : "Copy"} placement="top">
                                        <IconButton
                                            disableFocusRipple
                                            edge="end"
                                            onClick={() => {
                                                navigator.clipboard.writeText(`${config.urlDevice}/api/device/send/${uid}`);
                                                setIsCopy(true);
                                                setTimeout(() => {
                                                    setIsCopy(false);
                                                }, 3000);
                                            }}
                                        >
                                            <CopyAllIcon />
                                        </IconButton>
                                    </Tooltip>
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                </Grid>

                <Grid item xs={12}>
                    <Typography>Status Relay At {moment().format("YYYY-MM-D HH:MM:ss")}</Typography>
                    <Card sx={{ minWidth: 275 }} mt={2}>
                        <CardContent>
                            {relayData && (
                                <pre>
                                    <Typography variant="body2" color="text.secondary" style={{ whiteSpace: "pre-wrap" }}>
                                        {JSON.stringify(relayData, undefined, 8)}
                                    </Typography>
                                </pre>
                            )}
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </>
    );
};


export { DeviceContext }