import "./navigation-css/Menubar.css";
import Cookies from "js-cookie";
import React, { useEffect, useState, useRef, createContext, useContext } from "react";
import useDate from "./navigation-js/useTime";
import CurrentDate from "./navigation-js/useDate";
import PasswordSet from "../page/PasswordSetting";
import "../page/Routers.css";
import { showSidebar } from "../kumwell";
const PasswordContext = createContext();

export default function Menubar() {
    const token = Cookies.get("accessToken");
    const [profile, setProfile] = useState("profile-unActive");
    const [isMenuClick, setIsMenuClick] = useState(false);
    const [showComponent, setShowComponent] = useState(false);
    const menuRef = useRef(null);
    const currentTime = useDate();
    const formattedTime = currentTime.toLocaleTimeString();
    const { setAuth, auth } = useContext(showSidebar);

    const handleClose = () => {
        setAuth('');
        Cookies.remove("accessToken");
        window.location.href = "/";
    };

    const handleClick = () => {
        setShowComponent(!showComponent);
    };

    const updateMenu = () => {
        if (!isMenuClick) {
            setProfile("profile-Active");
        } else {
            setProfile("profile-unActive");
        }
        setIsMenuClick(!isMenuClick);
    };

    const handleClickOutside = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
            setProfile("profile-unActive");
            setIsMenuClick(false);
        }
    };

    useEffect(() => {
        document.addEventListener("click", handleClickOutside);
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, []);

    return (
        <>
            <PasswordContext.Provider value={{ showComponent, setShowComponent }}>
                <nav className="container-Menu">
                    <div className="MenuBar">
                        <div
                            className="logo-menu"
                        >
                            <img src="/image/Logo-Kumwell.png" alt="logo" onClick={() => (window.location.href = "/HomePage")} />
                        </div>
                        {auth.Role ? (
                            <span className="role-name">[{auth.Role.name}]</span>
                        ) : (
                            <></>
                        )}
                        <div className="logout-btn" onClick={updateMenu} ref={menuRef}>
                            {auth.firstName ? <>{auth.firstName.slice(0, 5)}...</> : <>loading...</>}
                            <div className="profile-icon">
                                <img src="/image/user.png" alt="profile" />
                            </div>
                        </div>
                        <div className="font-container">
                            <p className="moving-font">
                                {/* Lightning Warning System  */}
                                "Safety to Society"
                            </p>
                        </div>
                        <div className="timeNow">
                            {formattedTime}
                            <CurrentDate />
                        </div>
                    </div>
                </nav>
                <div className={profile}>
                    <div className="profile-menu">
                        <div className="item-profile">
                            {token && auth && (
                                <div onClick={handleClick} style={{ whiteSpace: "nowrap" }}>
                                    <button align="center">
                                        <img
                                            src="/image/lock.png"
                                            alt="lock"
                                            width="15px"

                                        />
                                    </button>
                                    &nbsp;&nbsp;Password Setting
                                </div>
                            )}
                            <div onClick={handleClose} style={{ whiteSpace: "nowrap" }}>
                                <button align="center">
                                    <img src="/image/sign-out-alt.png" alt="logout"
                                        width="15px"
                                        style={{ paddingTop: "5px", paddingRight: "4px" }} />
                                </button>
                                &nbsp;&nbsp;Logout
                            </div>
                        </div>
                    </div>
                </div>
                {showComponent && (
                    <>
                        {" "}
                        <PasswordSet />
                    </>
                )}
            </PasswordContext.Provider>
        </>
    );
}


export { PasswordContext };
