import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "../../../Routers.css";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Button,
  Modal,
  Box,
  Grid,
  CircularProgress,
} from "@mui/material";
import EditSetting from "./Edit";
import Delete from "./Delete";
import AddSetting from "./Add";
import Cookies from "js-cookie";

const config = require("./../../../../configs/app");

const modalStyled = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: 300,
  maxWidth: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 3,
  borderRadius: 1,
};

export default function Setting({ org }) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [allowAdd, setAllowAdd] = useState(true);
  const [editingSettingId, setEditingSettingId] = useState(null);
  const [deletingSettingId, setDeletingSettingId] = useState(null);
  const [deletingSetting, setDeletingSetting] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isAddOpen, setIsAddOpen] = useState(false);
  const key = Cookies.get("pathName");

  const fetchUser = () => {
    setLoading(true);
    fetch(`${config.urlAuth}/api/v1/user/${key}`)
      .then((response) => response.json())
      .then((data) => {
        setData(data);
        setAllowAdd(data.message?.length >= org?.maxUser);
      })
      .catch((error) => console.error("Error fetching data:", error))
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchUser();
  }, []);

  const handleEditClick = (id, user) => {
    setEditingSettingId(id);
  };

  const handleDeleteClick = (id, user) => {
    setIsDeleteModalOpen(true);
    setDeletingSettingId(id);
    setDeletingSetting(user);
  };

  const handleCloseModal = () => {
    setEditingSettingId(null);
  };

  const handleCloseDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };

  const handleOpen = () => setIsAddOpen(true);
  const handleClose = () => {
    fetchUser();
    setIsAddOpen(false);
  };

  return (
    <React.Fragment>
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item>
          <Typography>Setting User : Max {org?.maxUser} User</Typography>
        </Grid>
        <Grid item>
          <Button
            disableRipple
            size="small"
            variant="contained"
            color="success"
            onClick={() => setIsAddOpen(true)}
            disabled={allowAdd}
          >
            Add User
          </Button>
        </Grid>
      </Grid>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center"></TableCell>
              <TableCell align="center">Name</TableCell>
              <TableCell align="center">Username</TableCell>
              <TableCell align="center">Email</TableCell>
              <TableCell align="center">Role</TableCell>
              <TableCell align="center">Status</TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.message ? (
              data.message.map((item, index) => (
                <TableRow key={item.userId}>
                  <TableCell align="center">{index + 1}</TableCell>
                  <TableCell align="center">{`${item.firstName} ${item.lastName}`}</TableCell>
                  <TableCell align="center">{item.username}</TableCell>
                  <TableCell align="center">{item.email}</TableCell>
                  <TableCell align="center">{item.Role && item.Role.name}</TableCell>
                  <TableCell align="center">
                    {item.activeStatus ? (
                      <span style={{ color: "green" }}>Active</span>
                    ) : (
                      <span style={{ color: "red" }}>Inactive</span>
                    )}
                  </TableCell>
                  <TableCell align="right" sx={{ whiteSpace: "nowrap", width: 50 }}>
                    <Button
                      variant="contained"
                      color="warning"
                      size="small"
                      sx={{ mr: 1 }}
                      onClick={() => handleEditClick(item.userId, item)}
                    >
                      Edit
                    </Button>

                    <Button
                      variant="contained"
                      color="error"
                      size="small"
                      onClick={() => handleDeleteClick(item.id, item)}
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={7} align="center">
                  <Typography variant="h6" component="div">
                    {loading ? <CircularProgress color="inherit" size={20} /> : "No data"}
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Modal
        open={editingSettingId !== null}
        onClose={handleCloseModal}
        aria-labelledby="edit-setting-modal-title"
        aria-describedby="edit-setting-modal-description"
      >
        <Box sx={modalStyled}>
          <EditSetting userId={editingSettingId} fetchUser={fetchUser} onCloseModal={handleCloseModal} />
        </Box>
      </Modal>

      <Modal
        open={isDeleteModalOpen}
        onClose={handleCloseDeleteModal}
        aria-labelledby="delete-setting-modal-title"
        aria-describedby="delete-setting-modal-description"
      >
        <Box sx={modalStyled}>
          <Delete
            settingId={deletingSettingId}
            fetchUser={fetchUser}
            onCloseModal={handleCloseDeleteModal}
            user={deletingSetting}
          />
        </Box>
      </Modal>

      <Modal
        open={isAddOpen}
        onClose={handleClose}
        aria-labelledby="delete-setting-modal-title"
        aria-describedby="delete-setting-modal-description"
      >
        <Box sx={modalStyled}>
          <AddSetting onCloseModal={handleClose} fetchUser={fetchUser} />
        </Box>
      </Modal>
    </React.Fragment>
  );
}
