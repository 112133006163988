import { useContext, useState } from "react";
import { OrganizationInput, TypeContext } from "./AddOrganization";
import { OrganizationContext } from "../Organization";

export default function InformationOrg() {
    const { items } = useContext(OrganizationContext);
    const [daysRemaining, setDaysRemaining] = useState('');
    const { selectedDate, setSelectedDate, formData, setFormData, addMaptype, setMaptype, setUserData, error, setError, setBadRequest, badResquest } = useContext(OrganizationInput);
    const KeyNameBase = items.message;
    const handleChange = (event) => {
        const { name, value, type } = event.target;
        setError(false);
        let parsedValue;
        if (type === "number" && name !== "mapType") {
            parsedValue = parseFloat(value);
            if (name === "lat") {
                parsedValue = parseFloat(parsedValue.toFixed(6));
            }
            else if (name === "lon") {
                parsedValue = parseFloat(parsedValue.toFixed(6));
            }
        }
        else if (name === "mapType") {
            const input = parseFloat(event.target.value);
            setMaptype(input);
            parsedValue = parseFloat(value);

            setUserData((prevFormData) => ({
                ...prevFormData,
                viewEField: parsedValue !== 1 ? true : false,
            }));

        }
        else if (name === "keyName") {
            const cleanedValue = value.replace(/[^A-Za-z0-9]/g, '');
            parsedValue = cleanedValue.toUpperCase();
        }
        else {
            parsedValue = value;
        }
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: parsedValue,
        }));
    };

    const handleBlur = () => {
        if (formData.keyName !== '' && KeyNameBase.some(item => item.keyName === formData.keyName)) {
            setBadRequest(true);
        }
        else {
            setBadRequest(false);
        }
    };

    const handleDateChange = (event) => {
        const selectedDate = event.target.value;
        setSelectedDate(selectedDate);
        setFormData((prevFormData) => ({
            ...prevFormData,
            packageEnd: selectedDate,
        }));
        // const todayNow = new Date().toISOString().split('T')[0];
        // if (selectedDate >= todayNow) {
        //     setSelectedDate(selectedDate);
        //     const today = new Date();
        //     const deadline = new Date(selectedDate);
        //     const timeDifference = deadline.getTime() - today.getTime();
        //     const daysRemaining = Math.ceil(timeDifference / (1000 * 3600 * 24));
        //     setDaysRemaining(daysRemaining);
        //     setFormData((prevFormData) => ({
        //         ...prevFormData,
        //         packagePlan: daysRemaining,
        //     }));
        // }
    };

    return (
        <>
            <form autoComplete="off" style={{ height: '500px', overflowY: 'auto' }}>
                <p>Location information</p>
                <div className="input-inline" align="right">
                    <span>Location name : </span>
                    <input
                        className="input-half"
                        type="text"
                        name="nameLocation"
                        value={formData.nameLocation || ""}
                        onChange={handleChange}
                    />
                </div>

                <div className="input-inline" align="right">
                    <span>Key name : </span>
                    <input
                        className="input-half"
                        type="text"
                        name="keyName"
                        value={formData.keyName || ""}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />

                </div>
                {badResquest === true ? <div className="input-center" style={{ marginLeft: '65%', color: 'red' }}>
                    <span>Key name is already exists</span>
                </div>
                    : <></>}

                <div className="input-inline" align="right">
                    <span>Latitude : </span>
                    <input
                        className="input-half"
                        type="number"
                        name="lat"
                        value={formData.lat || ""}
                        onChange={handleChange}
                    />
                </div>

                <div className="input-inline" align="right">
                    <span>Longitude : </span>
                    <input
                        className="input-half"
                        type="number"
                        name="lon"
                        value={formData.lon || ""}
                        onChange={handleChange}
                    />
                </div>

                <div className="input-Full" align="right">
                    <span>Address : </span>
                    <textarea
                        name="address"
                        value={formData.address || ""}
                        onChange={handleChange}
                    />
                </div>

                <div className="input-inline" align="right">
                    <span>MapType : </span>
                    <select
                        name="mapType"
                        onChange={handleChange}
                        value={addMaptype || ""}
                    >
                        <option value={""}></option>
                        <option value={1}> M-Field </option>
                        <option value={2}> E-Field </option>
                        <option value={3}> M-Field,E-Field </option>
                    </select>
                </div>

                <div className="input-center" align="right">
                    <span>Display (metre) : </span>
                    <input
                        className="input-full"
                        type="number"
                        required
                        name="display"
                        value={formData.display || ""}
                        onChange={handleChange}
                    />
                </div>

                <div className="input-center" align="right">
                    <span>ActiveZone R (metre) : </span>
                    <input
                        className="input-full"
                        type="number"
                        name="activeZoneR"
                        value={formData.activeZoneR || ""}
                        onChange={handleChange}
                    />
                </div>

                <div className="input-center" align="right">
                    <span>ActiveZone A (metre) : </span>
                    <input
                        className="input-full"
                        type="number"
                        name="activeZoneA"
                        value={formData.activeZoneA || ""}
                        onChange={handleChange}
                    />
                </div>

                <div className="input-center" align="right">
                    <span>ActiveZone B (metre) : </span>
                    <input
                        className="input-full"
                        type="number"
                        name="activeZoneB"
                        value={formData.activeZoneB || ""}
                        onChange={handleChange}
                    />
                </div>

                <div className="input-center" align="right">
                    <span>Line Token : </span>
                    <input
                        className="input-full"
                        type="text"
                        name="lineToken"
                        value={formData.lineToken || ""}
                        onChange={handleChange} />
                </div>

                <div className="input-center" align="right">
                    <span>Expiration date : </span>
                    <input
                        className="input-full"
                        type="date"
                        name="packageEnd"
                        value={selectedDate}
                        onChange={handleDateChange} />
                </div>
                {/* <div className="input-center" align="right">อายุการใช้งาน {formData.packagePlan} </div> */}
                {error ?
                    <div className="alert-input" align='center' style={{ marginBottom: '20px' }}>
                        Please enter complete information.
                    </div> : <></>
                }

            </form>
        </>
    );
}
