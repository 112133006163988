import React, { useState, useEffect } from "react";
import { TextField, Button, Switch, FormControlLabel, Grid, Modal, Box, Typography } from "@mui/material";
import Swal from "sweetalert2";

const config = require("./../../../../configs/app");

const textFiledStyle = {
  my: 1,
};

const EditSetting = ({ settingId, keyNames, onSave, onCloseModal }) => {
  const [data, setData] = useState(null);

  useEffect(() => {
    fetch(`${config.urlSettingNotification}/api/v1/setting-notifications/${settingId}`)
      .then((response) => response.json())
      .then((data) => setData(data))
      .catch((error) => console.error("Error fetching data:", error));
  }, [settingId]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (value.length <= 1000) {

      setData({
        ...data,
        [name]: value,
      });
    }
  };

  const handleSwitchChange = (event) => {
    const { name, checked } = event.target;
    setData({
      ...data,
      [name]: checked,
    });
  };

  const handleSave = () => {
    fetch(`${config.urlSettingNotification}/api/v1/setting-notifications/${settingId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.ok) {
          Swal.fire({
            icon: "success",
            title: "Edit Successful!",
            text: "data has been edited",
          });
          onSave(data);
        } else {
          Swal.fire({
            icon: "error",
            title: "Edit Failed!",
            text: response.status,
          });
        }
      })
      .catch((error) => {
        console.error("เกิดข้อผิดพลาดในการบันทึกข้อมูล:", error);
      });
  };

  const handleCloseModal = () => {
    onCloseModal();
  };

  const characterCountStyle = {
    position: 'absolute',
    top: 8,
    right: 5,
    color: 'gray',
    fontSize: '14px',
  };


  return (
    <div>
      {data ? (
        <>
          <h2>
            แก้ไขข้อมูลสำหรับ {keyNames} {settingId}
          </h2>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <h4>ข้อความแจ้งเตือนไลน์</h4>
              <div style={{ position: 'relative' }}>
                <TextField
                  label="ข้อความแจ้งเตือนไลน์ แจ้งเตือนระดับที่ 3"
                  name="lineTextZoneR"
                  id="lineTextZoneR"
                  value={data.lineTextZoneR}
                  onChange={handleInputChange}
                  fullWidth
                  multiline
                  minRows={1}
                  sx={textFiledStyle}
                />
                <div style={characterCountStyle}>
                  {data.lineTextZoneR && <>{data.lineTextZoneR.length}/1000</>}
                </div>
              </div>
              <div style={{ position: 'relative' }}>
                <TextField
                  label="ข้อความแจ้งเตือนไลน์ แจ้งเตือนระดับที่ 2"
                  name="lineTextZoneA"
                  value={data.lineTextZoneA}
                  onChange={handleInputChange}
                  fullWidth
                  multiline
                  minRows={1}
                  sx={textFiledStyle}
                />
                <div style={characterCountStyle}>
                  {data.lineTextZoneA && <>{data.lineTextZoneA.length}/1000</>}
                </div>
              </div>
              <div style={{ position: 'relative' }}>
                <TextField
                  label="ข้อความแจ้งเตือนไลน์ แจ้งเตือนระดับที่ 1"
                  name="lineTextZoneB"
                  value={data.lineTextZoneB}
                  onChange={handleInputChange}
                  fullWidth
                  multiline
                  minRows={1}
                  sx={textFiledStyle}
                />
                <div style={characterCountStyle}>
                  {data.lineTextZoneB && <>{data.lineTextZoneB.length}/1000</>}
                </div>
              </div>

              <div style={{ position: 'relative' }}>
                <TextField
                  label="ข้อความแจ้งเตือนไลน์ แจ้งเตือนระดับที่ 0"
                  name="lineTextZoneC"
                  value={data.lineTextZoneC}
                  onChange={handleInputChange}
                  fullWidth
                  multiline
                  minRows={1}
                  sx={textFiledStyle}
                />
                <div style={characterCountStyle}>
                  {data.lineTextZoneC && <>{data.lineTextZoneC.length}/1000</>}
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <h4>ข้อความแจ้งเตือนอีเมล</h4>
              <div style={{ position: 'relative' }}>
                <TextField
                  label="ข้อความแจ้งเตือนอีเมล แจ้งเตือนระดับที่ 3"
                  name="emailTextZoneR"
                  value={data.emailTextZoneR}
                  onChange={handleInputChange}
                  fullWidth
                  multiline
                  minRows={1}
                  sx={textFiledStyle}
                  required
                />
                <div style={characterCountStyle}>
                  {data.emailTextZoneR && <>{data.emailTextZoneR.length}/1000</>}
                </div>
              </div>
              <div style={{ position: 'relative' }}>
                <TextField
                  label="ข้อความแจ้งเตือนอีเมล แจ้งเตือนระดับที่ 2"
                  name="emailTextZoneA"
                  value={data.emailTextZoneA}
                  onChange={handleInputChange}
                  fullWidth
                  multiline
                  minRows={1}
                  sx={textFiledStyle}
                />
                <div style={characterCountStyle}>
                  {data.emailTextZoneA && <>{data.emailTextZoneA.length}/1000</>}
                </div>
              </div>
              <div style={{ position: 'relative' }}>
                <TextField
                  label="ข้อความแจ้งเตือนอีเมล แจ้งเตือนระดับที่ 1"
                  name="emailTextZoneB"
                  value={data.emailTextZoneB}
                  onChange={handleInputChange}
                  fullWidth
                  sx={textFiledStyle}
                />
                <div style={characterCountStyle}>
                  {data.emailTextZoneB && <>{data.emailTextZoneB.length}/1000</>}
                </div>
              </div>
              <div style={{ position: 'relative' }}>
                <TextField
                  label="ข้อความแจ้งเตือนอีเมล แจ้งเตือนระดับที่ 0"
                  name="emailTextZoneC"
                  value={data.emailTextZoneC}
                  onChange={handleInputChange}
                  fullWidth
                  multiline
                  minRows={1}
                  sx={textFiledStyle}
                />
                <div style={characterCountStyle}>
                  {data.emailTextZoneC && <>{data.emailTextZoneC.length}/1000</>}
                </div>
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={2} display="flex">
            <Grid item xs={12}>
              <h4>ตั้งค่าการแจ้งเตือน</h4>
              <FormControlLabel
                control={
                  <Switch checked={data.emailNotification} onChange={handleSwitchChange} name="emailNotification" />
                }
                label="การแจ้งเตือนอีเมล"
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Switch checked={data.lineNotification} onChange={handleSwitchChange} name="lineNotification" />
                }
                label="การแจ้งเตือนไลน์"
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={<Switch checked={data.iotNotification} onChange={handleSwitchChange} name="iotNotification" />}
                label="การแจ้งเตือน IOT"
              />
            </Grid>
            <Grid item xs={12} display="flex" justifyContent="space-between">
              <Grid item xs={5.5} display="flex" alignItems="center" justifyContent="center">
                <Button variant="contained" onClick={handleSave} fullWidth>
                  บันทึก
                </Button>
              </Grid>
              <Grid item xs={5.5} display="flex" alignItems="center" justifyContent="center">
                <Button variant="contained" sx={{ bgcolor: "#F37026" }} onClick={handleCloseModal} fullWidth>
                  ยกเลิก
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </>
      ) : (
        <p>กำลังโหลดข้อมูล...</p>
      )}
    </div>
  );
};

export default EditSetting;
