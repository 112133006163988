import { Document, Page, Text, View, StyleSheet, Font } from "@react-pdf/renderer";

Font.register({
  family: "Kanit",
  src: process.env.PUBLIC_URL + "/font/Kanit-Medium.ttf",
});



const PDFDocument = ({ data, start, end, now, keyName, dataLength, user }) => {
  const TableHeader = () => (
    <View style={styles.tableRow}>
      <Text style={styles.tableHeaderCell}>No.</Text>
      {/* <Text style={styles.tableHeaderCell}>Date</Text>
      <Text style={styles.tableHeaderCell}>Level</Text> */}
      {!user && <Text Text style={styles.tableHeaderCell}>Zone</Text>}
      {!user && <Text Text style={styles.tableHeaderCell} > Distance</Text >}
      <Text style={styles.tableHeaderCell}>LAT</Text>
      <Text style={styles.tableHeaderCell}>LON</Text>
      <Text style={styles.tableHeaderCell}>Type</Text>
      <Text style={styles.tableHeaderCell}>Height</Text>
      <Text style={styles.tableHeaderCell}>Current</Text>
      <Text style={styles.tableHeaderCell}>Error</Text>
      <Text style={styles.tableHeaderCell}>Lightning Date</Text>
    </View >
  );
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.container}>
          <Text style={styles.title}>Notification Data From Alarm Viewer</Text>
        </View>
        <View style={styles.subcontainer}>
          <Text style={styles.subtitle}>Site name: {keyName}</Text>
          <Text style={styles.subtitle}>
            Alarm Date: Start {start} - To {end}
          </Text>
          <Text style={styles.subtitle}>Create: {now}</Text>
          <Text style={styles.subtitle}>Total notification: {dataLength}</Text>
        </View>
        <View style={styles.table}>
          <TableHeader />
          {data &&
            data.map((item, index) => (
              <View key={index} style={styles.tableRow}>
                <Text style={styles.tableCell}>{item["No."]}</Text>
                {/* <Text style={styles.tableCell}>{item.Date}</Text>
              <Text style={styles.tableCell}>{item.Level}</Text> */}
                {!user && <Text style={styles.tableCell}>{item.zone}</Text>}
                {!user && <Text style={styles.tableCell}>{item.distance}</Text>}
                <Text style={styles.tableCell}>{item.LAT}</Text>
                <Text style={styles.tableCell}>{item.LON}</Text>
                <Text style={styles.tableCell}>{item.Type}</Text>
                <Text style={styles.tableCell}>{item.Height}</Text>
                <Text style={styles.tableCell}>{item.Current}</Text>
                <Text style={styles.tableCell}>{item.Error}</Text>
                <Text style={styles.tableCell}>{item["Lightning Date"]}</Text>
              </View>
            ))}
        </View>
      </Page>
    </Document>
  )
};

const styles = StyleSheet.create({
  page: {
    padding: 30,
  },
  container: {
    fontFamily: "Kanit",
    textAlign: "center",
  },
  subcontainer: {
    fontFamily: "Kanit",
    textAlign: "left",
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderTopColor: "#000",
    borderTopWidth: 0.8,
    borderLeftColor: "#000",
    borderLeftWidth: 0.8,
    textAlign: "center",
    borderCollapse: "collapse",
  },
  tableRow: {
    flexDirection: "row",
  },
  tableCell: {
    flex: 1,
    padding: 2,
    fontFamily: "Kanit",
    fontSize: 7,
    borderBottomColor: "#000",
    borderBottomWidth: 0.8,
    borderRightColor: "#000",
    borderRightWidth: 0.8,
  },
  tableHeaderCell: {
    flex: 1,
    padding: 2,
    backgroundColor: "#ccc",
    fontWeight: "bold",
    fontFamily: "Kanit",
    fontSize: 8,
    borderBottomColor: "#000",
    borderBottomWidth: 0.8,
    borderRightColor: "#000",
    borderRightWidth: 0.8,
  },
  title: {
    fontSize: 12,
    fontWeight: "bold",
    marginBottom: 10,
  },
  subtitle: {
    fontSize: 8,
    marginBottom: 10,
  },
});

export default PDFDocument;
