import { useContext, useEffect, useState } from "react";
import "../../Routers.css";
import apiUrls from "../../../ApiUrl";
import Cookies from "js-cookie";

const config = require("./../../../configs/app");

export default function User({ context, admin, efield, edit, superAdmin }) {
  const {
    userData,
    setUserData,
    error,
    setError,
    setconfirmPassword,
    confirmPassword,
    setConfirmPassword,
    EditUserData,
    setEditUserData,
    errorEmail,
    setErrorEmail,
    errorUsername,
    setErrorUsername,
    EditUserDataCheck,
  } = useContext(context);

  const [erorrPassword, setErorrPassword] = useState("");
  const [emailCheck, setEmailCheck] = useState("");
  const [allUsers, setAllUsers] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [item, setItem] = useState(false);
  const [roleId, setRoleId] = useState("");
  const [allRole, setAllRole] = useState("");
  const [keyOrg, setKeyOrg] = useState("");
  const [viewEField, setViewEField] = useState("");
  const token = Cookies.get("accessToken");
  const regex = /^[a-zA-Z0-9\s @]*$/;

  const handleChange = (event) => {
    const { name, value, type } = event.target;
    setError(false);
    let parsedValue;
    if (type === "number") {
      parsedValue = parseFloat(value);
    } else if (name === "password") {
      if (regex.test(value)) {
        parsedValue = value;
      }
    } else if (name === "RoleId") {
      parsedValue = parseFloat(value);
      setRoleId(parsedValue);
    } else if (name === "keyName") {
      parsedValue = value;
      setKeyOrg(value);
      const option = item.message.find((items) => items.keyName === value);
      if (value !== "") {
        setUserData((prevFormData) => ({
          ...prevFormData,
          ["activeStatus"]: option.activeStatus,
        }));
      }
    } else if (name === "viewEField") {
      if (value === "true") {
        parsedValue = true;
      } else {
        parsedValue = false;
      }
      setViewEField(value);
    } else {
      parsedValue = value;
    }
    setUserData((prevFormData) => ({
      ...prevFormData,
      [name]: parsedValue,
    }));
  };

  const checkMail = () => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isAvailable = !allUsers.message.some((item) => item.email === userData.email);
    if (!emailPattern.test(userData.email) && userData.email !== "") {
      setErrorEmail("Email is invalid");
    } else {
      if (!isAvailable) {
        setErrorEmail("Email is already exists");
      } else {
        setErrorEmail(false);
      }
    }
  };

  const checkMailEdit = () => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isAvailable = !allUsers.message.some(
      (item) => item.email === EditUserData.email && item.email !== EditUserDataCheck.email
    );
    if (!emailPattern.test(EditUserData.email) && EditUserData.email !== "") {
      setErrorEmail("Email is invalid");
    } else {
      if (!isAvailable) {
        setErrorEmail("Email is already exists");
      } else {
        setErrorEmail(false);
      }
    }
  };

  const checkUserAll = () => {
    const isAvailable = !allUsers.message.some((item) => item.username === userData.username);
    if (isAvailable) {
      setErrorUsername(false);
    } else {
      setErrorUsername(true);
    }
  };
  const checkUserAllEdit = () => {
    const isAvailable = !allUsers.message.some(
      (item) => item.username === EditUserData.username && item.username !== EditUserDataCheck.username
    );
    if (isAvailable) {
      setErrorUsername(false);
    } else {
      setErrorUsername(true);
    }
  };

  const handleChangeEdit = (event) => {
    const { name, value, type } = event.target;
    setError(false);
    let parsedValue;
    if (type === "number") {
      parsedValue = parseFloat(value);
    } else if (name === "RoleId") {
      parsedValue = parseFloat(value);
    } else if (name === "keyName") {
      parsedValue = value;
      // const option = item.message.find(items => items.keyName === value);
    } else if (name === "viewEField" || name === "activeStatus") {
      if (value == "true") {
        parsedValue = true;
      } else if (value == "false") {
        parsedValue = false;
      } else {
        parsedValue = "";
      }
      setViewEField(value);
    } else {
      parsedValue = value;
    }
    setEditUserData((prevFormData) => ({
      ...prevFormData,
      [name]: parsedValue,
    }));
  };

  const EditUserPassword = (event) => {
    const { value } = event.target;
    setError(false);
    if (regex.test(value)) {
      if (value !== "") {
        setEditUserData({ ...EditUserData, password: value });
      } else {
        const { password, ...updatedData } = EditUserData;
        setEditUserData(updatedData);
      }
    }
  };

  const CheckPassword = () => {
    if (userData.password !== undefined) {
      if (userData.password.length < 8 && userData.password !== "") {
        setErorrPassword("Password should be at least 8 characters long");
        setconfirmPassword(false);
      } else if (userData.password !== confirmPassword && confirmPassword !== "") {
        setErorrPassword("Passwords dont match");
        setconfirmPassword(false);
      } else if (confirmPassword !== userData.password) {
        setconfirmPassword(false);
      } else if (confirmPassword === "" || userData.password === "") {
        setconfirmPassword(false);
      } else {
        setErorrPassword("");
        setconfirmPassword(true);
      }
    }
  };

  const EditCheckPasword = () => {
    if (EditUserData.password !== undefined) {
      if (EditUserData.password.length < 8 && EditUserData.password !== "") {
        setErorrPassword("Password should be at least 8 characters long");
        setconfirmPassword(false);
        setError("Password-Fail");
      } else {
        setErorrPassword("");
        setconfirmPassword(true);
      }
    } else {
      setErorrPassword("");
      setconfirmPassword(true);
    }
  };

  const Erorrs = (e) => {
    setConfirmPassword(e.target.value);
    setError(false);
  };

  const fetchKeyName = async () => {
    try {
      const response = await fetch(apiUrls.OrganizationUrl_GET, {
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
      });
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }
      const data = await response.json();
      setItem(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchDataUser = async () => {
    try {
      const response = await fetch(`${config.urlAuth}/api/v1/user/`, {
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
      });
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }
      const data = await response.json();
      setAllUsers(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const fetchRole = async () => {
    try {
      const response = await fetch(`${config.urlAuth}/api/v1/role/`, {
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
      });
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }
      const data = await response.json();
      const Rolename = Object.values(data.message).map((item) => ({
        name: item.name,
        roleId: item.roleId,
      }));
      setAllRole(Rolename);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (admin === true && !item) {
      fetchKeyName();
    }
  }, [admin]);

  useEffect(() => {
    fetchRole();
    fetchDataUser();
  }, []);

  return (
    <>
      <form autoComplete="off">
        <p>Account</p>
        {admin && (
          <div className="input-oneline " align="right">
            <span>Organization : </span>
            <select
              name="keyName"
              value={edit ? EditUserData.keyName || "" : keyOrg}
              onChange={edit ? handleChangeEdit : handleChange}
            >
              <option value={""}></option>
              {item && (
                <>
                  {item.message.map((items, index) => (
                    <option value={items.keyName} key={index}>
                      {items.keyName}
                    </option>
                  ))}
                </>
              )}
            </select>
          </div>
        )}
        <div className="input-oneline " align="right">
          <span>First name : </span>
          <input
            className="summary"
            type="text"
            name="firstName"
            value={edit ? EditUserData.firstName || "" : userData.firstName || ""}
            onChange={edit ? handleChangeEdit : handleChange}
            placeholder="First Name"
          />
        </div>

        <div className="input-oneline" align="right">
          <span>Last name : </span>
          <input
            type="text"
            name="lastName"
            value={edit ? EditUserData.lastName || "" : userData.lastName || ""}
            onChange={edit ? handleChangeEdit : handleChange}
            placeholder="Last Name"
          />
        </div>

        <div className="input-oneline" align="right">
          <span>Email : </span>
          <input
            type="email"
            name="email"
            value={edit ? EditUserData.email || "" : userData.email || ""}
            onChange={edit ? handleChangeEdit : handleChange}
            onBlur={edit ? checkMailEdit : checkMail}
            placeholder="example@email.com"
            pattern=".+@beststartupever\.com"
            required
          />
        </div>

        {errorEmail === "Email ไม่ถูกต้อง" && (
          <div style={{ color: "red", marginLeft: "155px", fontSize: "12px" }}>Email is invalid</div>
        )}
        {errorEmail === "Email ถูกใช้งานแล้ว" && (
          <div style={{ color: "red", marginLeft: "155px", fontSize: "12px" }}>Email is already exists</div>
        )}

        <div className="input-oneline" align="right">
          <span>Username : </span>
          <input
            type="text"
            name="username"
            autoComplete="off"
            value={edit ? EditUserData.username || "" : userData.username || ""}
            onChange={edit ? handleChangeEdit : handleChange}
            onBlur={edit ? checkUserAllEdit : checkUserAll}
            placeholder="username"
          />
        </div>
        {errorUsername && (
          <div style={{ color: "red", marginLeft: "155px", fontSize: "12px" }}>Username is already exists</div>
        )}
        <div className="input-oneline" align="right">
          <span>{edit ? "New Password" : "Password"} : </span>
          <input
            className="input-half"
            onBlur={edit ? EditCheckPasword : CheckPassword}
            autoComplete="off"
            type={showPassword ? "text" : "password"}
            placeholder="password"
            name="password"
            value={edit ? EditUserData.password || "" : userData.password || ""}
            onChange={edit ? EditUserPassword : handleChange}
          />
        </div>

        {!edit && (
          <>
            <div className="input-oneline" align="right">
              <span>Confirm Password : </span>
              <input
                onBlur={CheckPassword}
                onChange={Erorrs}
                className="input-half"
                type={showPassword ? "text" : "password"}
                placeholder="confirm password"
                name="password"
                value={confirmPassword}
              />
            </div>
          </>
        )}

        {erorrPassword ? <div className="erorr-password input-oneline">{erorrPassword}</div> : <></>}

        <div className="input-oneline" align="right">
          <span>Phone number : </span>
          <input
            type="text"
            name="phoneNumber"
            value={edit ? EditUserData.phoneNumber || "" : userData.phoneNumber || ""}
            placeholder="mobile number"
            onChange={edit ? handleChangeEdit : handleChange}
          />
        </div>
        <div className="input-oneline" align="right">
          <span>Role : </span>
          <select
            name="RoleId"
            value={edit ? EditUserData.RoleId || "" : userData.RoleId}
            onChange={edit ? handleChangeEdit : handleChange}
          >
            <option value={""}></option>
            {allRole &&
              allRole
                .filter((item) => (superAdmin ? item.roleId : item.roleId !== 29 && item.roleId !== 24))
                .map((items, index) => (
                  <option key={index} value={items.roleId}>
                    {items.name}
                  </option>
                ))}
          </select>
        </div>
        {/* {edit &&
                    <div className="input-oneline" align="right">
                        <span>lineToken : </span>
                        <input type="text" name="lineToken" value={EditUserData.lineToken} onChange={handleChangeEdit} placeholder="lineToken" />
                    </div>
                } */}
        {efield && (
          <div className="input-oneline" align="right">
            <span>E Field : </span>
            <select
              name="viewEField"
              value={edit ? EditUserData.viewEField : viewEField}
              onChange={edit ? handleChangeEdit : handleChange}
            >
              <option value={""}></option>
              <option value={"true"}>True</option>
              <option value={"false"}>False</option>
            </select>
          </div>
        )}
        {edit && (
          <div className="input-oneline" align="right">
            <span>activeStatus : </span>
            <select name="activeStatus" value={EditUserData.activeStatus} onChange={handleChangeEdit}>
              <option value={""}></option>
              <option value={"true"}> True</option>
              <option value={"false"}> False</option>
            </select>
          </div>
        )}

        {(error === true && (
          <div className="alert-input" align="center">
            please provide complete information.
          </div>
        )) ||
          (error === "No Edit" && (
            <div className="alert-input" align="center">
              The information has not been altered yet.
            </div>
          ))}
      </form>
    </>
  );
}
