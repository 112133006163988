import React from "react";
import { useRef } from "react";
import ReactApexChart from "react-apexcharts";

const config = require("./../../configs/app");

const processData = (data) => {
  const processedData = [];
  let sum = 0;
  let count = 0;

  for (let i = 0; i < data.length; i++) {
    const [time, value] = data[i];
    sum += value;
    count++;

    if (time >= 86400) break;

    if (i === data.length - 1 || time % 60 === 0) {
      const mean = sum / count;
      processedData.push([time, mean.toFixed(4)]);
      sum = 0;
      count = 0;
    }
  }

  return processedData;
};

const ChartComponent = ({ dataOrg }) => {

  const [dataEField, setDataEField] = React.useState([]);
  const [series, setSeries] = React.useState([
    {
      name: "Electric Field",
      data: [],
      color: "#d71d24",
    },
  ]);

  const [minMax, setMinMax] = React.useState(0); // Set the initial y-axis range
  const canvasRef = useRef(null);

  const fetchData = () => {
    const warningPointId = dataOrg;
    fetch(`${config.urlLightningConfig}/api/v1/e-field/${warningPointId}`)
      .then(async (res) => {
        const json = await res.json();
        const processedData = processData(json?.message?.d);
        setDataEField(processedData);
        const maxDataValue = Math.abs(Math.max(...processedData.map((point) => point[1]))); // Get the maximum y-axis value
        const minDataValue = Math.abs(Math.min(...processedData.map((point) => point[1]))); // Get the minimum y-axis value
        // console.log(Math.max(maxDataValue, minDataValue));
        if (maxDataValue !== Infinity) {
          setMinMax(Math.max(maxDataValue, minDataValue)); // Set the initial y-axis range
        }
        setSeries([
          {
            name: "Electric Field",
            data: processedData,
            color: "#d71d24",
          },
        ]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  React.useEffect(() => {
    fetchData();
  }, [dataOrg]);

  React.useEffect(() => {
    const interval = setInterval(() => {
      fetchData();
    }, 60000);
    return () => clearInterval(interval);
  }, [dataOrg]);

  const options = {
    chart: {
      id: "time-value-chart",
      type: "line",
      zoom: {
        enabled: false,
      },
    },
    xaxis: {
      type: "numeric",
      labels: {
        formatter: (value) => {
          const date = new Date(value * 1000); // Convert seconds to milliseconds
          const hours = date.getUTCHours().toString().padStart(2, "0");
          const minutes = date.getUTCMinutes().toString().padStart(2, "0");
          return `${hours}:${minutes}`;
        },
      },
    },

    yaxis: {
      min: -minMax - 0.2,
      max: minMax + 0.2,
      tickAmount: 4,
      forceNiceScale: true,

      labels: {
        formatter: (value) => {
          return `${value.toFixed(2)} `;
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      // title: {
      //   text: "kV/m", 
      //   offsetY: -15, 
      // },
    },


    grid: {
      show: true,
      borderColor: "#90A4AE",
      strokeDashArray: 1,
      position: "front",
      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },

      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 10,
      },
    },
    annotations: {
      yaxis: [
        {
          y: 0,
          borderColor: "#000", // Color of the line
          borderWidth: 0.5, // Thickness of the line
          strokeDashArray: 0, // Length of the dashes
          opacity: 0.5, // Opacity of the line+

          label: {
            text: "kV/m", // Text label for the regulatory unit
            position: "top",
            offsetY: -43, // Position the label at the top of the y-axis
          },
        },
      ],
    },
    events: {
      zoomed: function (chartContext, { xaxis, yaxis }) {
        chartContext.updateOptions({
          xaxis: {
            min: xaxis.min,
            max: xaxis.max,
          },
          yaxis: {
            min: yaxis.min,
            max: yaxis.max,
          },
        });
      },
    },
    stroke: {
      curve: "smooth",
      width: 2,
    },
  };

  return (
    <div style={{ height: "84%", width: "100%" }}>
      {/* <button onClick={fetchData}>Refresh Chart</button> */}
      <ReactApexChart options={options} series={series} type="line" height="100%" ref={canvasRef} />
    </div>
  );
};

export default ChartComponent;
