import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Button,
  Modal,
  Box,
  Grid,
  Card,
  CardContent,
  CircularProgress,
} from "@mui/material";
import EditSetting from "./EditNotification";
import Delete from "./Delete";
import AddSetting from "./AddSetting";
import Cookies from "js-cookie";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import NotificationsOffIcon from "@mui/icons-material/NotificationsOff";

const config = require("./../../../../configs/app");

const modalStyled = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: { xs: 320, sm: 600, lg: 600 },
  maxWidth: { xs: 320, sm: 600, lg: 700 },
  minHeight: { xs: "80%", sm: "70%", lg: "80%" },
  maxHeight: { xs: "80%", sm: "70%", lg: "85%" },
  bgcolor: "background.paper",
  borderRadius: "30px",
  boxShadow: "none",
  p: { xs: 2, sm: 3.5 },
};

const deleteStyled = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: 700,
  maxWidth: 700,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 4,
};

export default function Setting() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [allowAdd, setAllowAdd] = useState(true);
  const [editingSettingId, setEditingSettingId] = useState(null);
  const [deletingSettingId, setDeletingSettingId] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isAddOpen, setIsAddOpen] = useState(false);
  const [keyNames, setkeyNames] = useState(null);
  const keyName = Cookies.get("pathName");

  useEffect(() => {
    fetchData();
  }, [keyName]);

  const fetchData = () => {
    setLoading(true);
    fetch(`${config.urlSettingNotification}/api/v1/setting-notifications/keyName/${keyName}`)
      .then((response) => response.json())
      .then((data) => {
        const uniqueData = data.filter((item, index, array) => array.findIndex((obj) => obj.id === item.id) === index);
        setData(uniqueData);
        setAllowAdd(uniqueData.length > 0);
      })
      .catch((error) => console.error("Error fetching data:", error))
      .finally(() => {
        setLoading(false);
      });
  };

  const handleAddData = (newData) => {
    setData((prevData) => [...prevData, newData]);
    setIsAddOpen(false);
  };

  const handleEditClick = (id, keyNames) => {
    setEditingSettingId(id);
    setkeyNames(keyNames);
  };

  const handleDeleteClick = (id, keyNames) => {
    setIsDeleteModalOpen(true);
    setDeletingSettingId(id);
    setkeyNames(keyNames);
  };

  const handleDelete = (deletedSettingId) => {
    const updatedData = data.filter((item) => item.id !== deletedSettingId);
    setData(updatedData);
    setIsDeleteModalOpen(false);
  };

  const handleSaveClick = (editedData) => {
    fetch(`${config.urlSettingNotification}/api/v1/setting-notifications/${editedData.id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(editedData),
    })
      .then((response) => {
        if (response.ok) {
          fetch(`${config.urlSettingNotification}/api/v1/setting-notifications/keyName/${keyName}`)
            .then((response) => response.json())
            .then((data) => setData(data))
            .catch((error) => console.error("Error fetching data:", error));
        } else {
          console.error("ไม่สามารถบันทึกข้อมูลได้");
        }
      })
      .catch((error) => {
        console.error("เกิดข้อผิดพลาดในการบันทึกข้อมูล:", error);
      });
    setEditingSettingId(null);
  };

  const handleCloseModal = () => {
    setEditingSettingId(null);
  };

  const handleCloseDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };

  const handleClose = () => setIsAddOpen(false);

  return (
    <React.Fragment>
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item>
          <Typography>Setting Notification</Typography>
        </Grid>
        <Grid item>
          <Button
            disableRipple
            size="small"
            variant="contained"
            color="success"
            onClick={() => setIsAddOpen(true)}
            disabled={allowAdd}
          >
            Add Setting
          </Button>
        </Grid>
      </Grid>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center"></TableCell>
              <TableCell align="center">Email Notification</TableCell>
              <TableCell align="center">Line Notification</TableCell>
              <TableCell align="center">IoT Notification</TableCell>
              <TableCell align="center"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.length > 0 ? (
              data.map((item, index) => (
                <TableRow key={item.id}>
                  <TableCell align="center">{index + 1}</TableCell>
                  <TableCell>
                    {item.emailNotification ? (
                      <Box display="flex" alignItems="center" justifyContent="center" gap={1}>
                        <NotificationsActiveIcon sx={{ color: "#228B22" }} fontSize="medium" />
                        <Typography>Enable</Typography>
                      </Box>
                    ) : (
                      <Box display="flex" alignItems="center" justifyContent="center" gap={1}>
                        <NotificationsOffIcon sx={{ color: "#C70039" }} fontSize="medium" />
                        <Typography>Disable</Typography>
                      </Box>
                    )}
                  </TableCell>
                  <TableCell>
                    {item.lineNotification ? (
                      <Box display="flex" alignItems="center" justifyContent="center" gap={1}>
                        <NotificationsActiveIcon sx={{ color: "#228B22" }} fontSize="medium" />
                        <Typography>Enable</Typography>
                      </Box>
                    ) : (
                      <Box display="flex" alignItems="center" justifyContent="center" gap={1}>
                        <NotificationsOffIcon sx={{ color: "#C70039" }} fontSize="medium" />
                        <Typography>Disable</Typography>
                      </Box>
                    )}
                  </TableCell>
                  <TableCell>
                    {item.iotNotification ? (
                      <Box display="flex" alignItems="center" justifyContent="center" gap={1}>
                        <NotificationsActiveIcon sx={{ color: "#228B22" }} fontSize="medium" />
                        <Typography>Enable</Typography>
                      </Box>
                    ) : (
                      <Box display="flex" alignItems="center" justifyContent="center" gap={1}>
                        <NotificationsOffIcon sx={{ color: "#C70039" }} fontSize="medium" />
                        <Typography>Disable</Typography>
                      </Box>
                    )}
                  </TableCell>
                  <TableCell align="center" sx={{ whiteSpace: "nowrap", width: 50 }}>
                    <Button
                      onClick={() => handleEditClick(item.id, item.keyName)}
                      variant="contained"
                      color="warning"
                      size="small"
                      sx={{ mr: 1 }}
                    >
                      Edit
                    </Button>
                    <Button
                      onClick={() => handleDeleteClick(item.id, item.keyName)}
                      variant="contained"
                      color="error"
                      size="small"
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell align="center" colSpan={6}>
                  {loading ? <CircularProgress color="inherit" size={20} /> : "No data"}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Modal
        open={editingSettingId !== null}
        onClose={handleCloseModal}
        aria-labelledby="edit-setting-modal-title"
        aria-describedby="edit-setting-modal-description"
      >
        <Card
          sx={{
            ...modalStyled,
            height: "auto",
            overflowY: "auto",
            "::-webkit-scrollbar": {
              width: "8px",
              backgroundColor: "transparent",
              transform: "translateX(-50px)",
            },
            "::-webkit-scrollbar-thumb": {
              backgroundColor: "#B1B2B2",
              borderRadius: "5px",
            },
            "::-webkit-scrollbar-track": {
              backgroundColor: "transparent",
            },
            "::-webkit-scrollbar-track-piece:start": {
              marginTop: "20px",
            },
            "::-webkit-scrollbar-track-piece:end": {
              marginBottom: "20px",
            },
            "::-webkit-scrollbar-thumb:hover": {
              backgroundColor: "#B1B2B2",
            },
          }}
        >
          <CardContent sx={{ height: "100%" }}>
            <EditSetting
              settingId={editingSettingId}
              keyNames={keyNames}
              onSave={handleSaveClick}
              onCloseModal={handleCloseModal}
            />
          </CardContent>
        </Card>
      </Modal>

      <Modal
        open={isDeleteModalOpen}
        onClose={handleCloseDeleteModal}
        aria-labelledby="delete-setting-modal-title"
        aria-describedby="delete-setting-modal-description"
      >
        <Box sx={deleteStyled}>
          <Delete
            settingId={deletingSettingId}
            onDelete={handleDelete}
            onCloseModal={handleCloseDeleteModal}
            keyNames={keyNames}
          />
        </Box>
      </Modal>

      <Modal
        open={isAddOpen}
        onClose={handleClose}
        aria-labelledby="delete-setting-modal-title"
        aria-describedby="delete-setting-modal-description"
      >
        <Card
          sx={{
            ...modalStyled,
            height: "auto",
            overflowY: "auto",
            "::-webkit-scrollbar": {
              width: "8px",
              backgroundColor: "transparent",
              transform: "translateX(-50px)",
            },
            "::-webkit-scrollbar-thumb": {
              backgroundColor: "#B1B2B2",
              borderRadius: "5px",
            },
            "::-webkit-scrollbar-track": {
              backgroundColor: "transparent",
            },
            "::-webkit-scrollbar-track-piece:start": {
              marginTop: "20px",
            },
            "::-webkit-scrollbar-track-piece:end": {
              marginBottom: "20px",
            },
            "::-webkit-scrollbar-thumb:hover": {
              backgroundColor: "#B1B2B2",
            },
          }}
        >
          <CardContent sx={{ height: "100%" }}>
            <AddSetting onCloseModal={handleClose} keyName={keyName} onDataAdded={handleAddData} />
          </CardContent>
        </Card>
      </Modal>
    </React.Fragment>
  );
}
