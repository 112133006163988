import "../Routers.css";
import React, { useState, useEffect, useContext, createContext } from "react";
import { useNavigate } from "react-router-dom";
import SettingUser from "./all-setting/SettingUser/SettingUser";
import EditOrganization from "./all-setting/EditOrganization/EditOrganization";
import SettingLevel from "./all-setting/SettingLevel/SettingLevel";
import Map from "./all-setting/Map";
import Setting from "./all-setting/setting-notification/SettingNotification";
import Report from "./all-setting/Report";
import ReportMField from "./all-setting/ReportMField";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../../theme";
import { showSidebar } from "../../kumwell";
import Cookies from "js-cookie";
import Device from "./all-setting/SettingDevice/Device.jsx";
const config = require("./../../configs/app");

export default function SetOrganization() {
  const [activeItem, setActiveItem] = useState(1);
  const [dataOrg, setDataOrg] = useState("");
  const marker = document.querySelector("#marker");
  const item = document.querySelectorAll(".organization-menu");
  const navigate = useNavigate();
  const { showSidebars } = useContext(showSidebar);
  const keyName = Cookies.get("pathName");
  const token = Cookies.get("accessToken");
  const indicator = (e) => {
    marker.style.left = e.offsetLeft + "px";
    marker.style.width = e.offsetWidth + "px";
  };

  item.forEach((link) => {
    link.addEventListener("click", (e) => {
      indicator(e.target);
    });
  });

  const handleClick = (itemId) => {
    setActiveItem(itemId);
  };

  const hasPermission = (itemId) => {
    const allowedIds = [null];
    return allowedIds.includes(itemId);
  };

  const fetchOrg = () => {
    fetch(`${config.urlAuth}/api/v1/organization/${keyName}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === 200) {
          setDataOrg(data.message);
        } else {
        }
      })
      .catch((error) => {});
  };

  useEffect(() => {
    fetchOrg();
  }, []);

  const componentMapping = {
    1: Component1,
    2: Component2,
    3: Component3,
    4: Component4,
    5: Component5,
    6: Component6,
    7: Component7,
  };

  const items = [
    { id: 1, text: "Map" },
    { id: 2, text: "Export report" },
    { id: 3, text: "Warning level" },
    { id: 4, text: "Notification" },
    { id: 5, text: "Device IOT" },
    { id: 6, text: "Users" },
    { id: 7, text: "Edit" },
  ].filter((item) => !hasPermission(item.id));

  const ActiveComponent = componentMapping[activeItem] || null;
  return (
    <div className={`organization ${activeItem === 1 ? "route-container-map" : "route-container"} ${showSidebars}`}>
      <div className="route-backgorund">
        <div className="topic-title">
          <span
            className="backOrganizion"
            style={{ cursor: "pointer", color: "#1d4fd7" }}
            onClick={() => navigate("/organization")}
          >
            Location
          </span>{" "}
          {dataOrg && `/ Location Information : ${dataOrg.nameLocation} [${keyName}] `}
          {dataOrg ? (
            <img
              src={dataOrg.activeStatus ? "/image/dot-green.png" : "/image/dot-red.png"}
              alt="active"
              style={{ width: "12px", height: "12px" }}
            />
          ) : null}
        </div>

        <div className="organization-setting">
          <nav>
            <div id="marker"></div>
            {items.map((item) => (
              <span
                key={item.id}
                className={activeItem === item.id ? "organization-menu active" : "organization-menu"}
                onClick={() => handleClick(item.id)}
              >
                {item.text}
              </span>
            ))}
          </nav>
        </div>
        <ThemeProvider theme={theme}>
          <div className={activeItem === 1 ? "route-item-map" : "route-item"}>
            {ActiveComponent && <ActiveComponent />}
          </div>
        </ThemeProvider>
      </div>
    </div>
  );

  function Component1() {
    return <Map dataOrg={dataOrg} />;
  }

  function Component2() {
    // return <Report />;
    return <ReportMField />;
  }

  function Component3() {
    return <SettingLevel />;
  }

  function Component4() {
    return <Setting />;
  }

  function Component5() {
    return <Device org={dataOrg} />;
  }

  function Component6() {
    return <SettingUser org={dataOrg} />;
  }

  function Component7() {
    return <EditOrganization fetchOrg={fetchOrg} />;
  }
}
