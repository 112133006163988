import "./index.css";
import Sidebar from "./navigation/Sidebar";
import Menubar from "./navigation/Menubar";
import Cookies from "js-cookie";
import LoginPage from "./page/LoginPage";
import { useState, useEffect, createContext, useRef } from "react";
import UserSetting from "./page/user-information/UserSetting";
import { Route, Routes } from "react-router-dom";
import MapView from "./page/MainPage/MapView";
import Organization from "./page/organization/Organization";
import SoundPage from "./page/notification-sound/Sound";
import Efield from "./page/E-Field/E-Field";
import DeviceStatus from "./page/device/DeviceStatus";
import Report from "./page/report-information/Report";
import Line from "./page/line-send/Line";
import Permission from "./page/permission/Permission";
import apiUrls from "./ApiUrl";
import BurgerMenu from "./navigation/navigation-js/BurgerMenu";
import SetOrganization from "./page/organization/SettingOrganization";
import AlertEfield from "./page/Alert";
import WarningReport from "./page/report-information/WarningReport";
const config = require("./configs/app");
const showSidebar = createContext();
function App() {
  const [showSidebars, setShowSidebar] = useState("sidebar-responsive");
  const [auth, setAuth] = useState("");
  const [errorfix, setErrorfix] = useState(0);
  const [soundWarning, setSoundWarning] = useState(true);
  const [warningData, setWarningData] = useState(null);
  const [Organization_location, setOrganization_location] = useState([]);
  const [organization_setting, setOrganization_Setting] = useState([]);
  const [reportWarn, setReportWarn] = useState([]);
  const [RouteKeyName, setRouteKeyName] = useState("");
  const [soundUrl, setSoundUrl] = useState("");
  const token = Cookies.get("accessToken");
  const pathName = Cookies.get("pathName");
  const [alertData, setAlertData] = useState([]);
  const socketRef = useRef(null);

  const updateMenu = () => {
    if (showSidebars === "sidebar-On") {
      setShowSidebar("sidebar-responsive");
    } else {
      setShowSidebar("sidebar-On");
    }
    return false;
  };

  const fetchData = () => {
    const url = apiUrls.myAccount;
    fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        const matchingPermissions = data.Role.Permissions.filter((item) => item.name === "Home").map(
          (permission) => permission
        );
        const CheckRole = matchingPermissions.find((item) => item.type === "update");
        setAuth(data);
        if (!CheckRole) {
          apiUrls.setRequestOrganization_GET(data.keyName);
        } else {
          apiUrls.setRequestOrganization_GET("?user=true");
        }
        apiUrls.setLightningRequest(data.keyName);
        const organiztion = apiUrls.OrganizationUrl_getLocation;
        return fetch(organiztion, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      })
      .then((organiztionRes) => organiztionRes.json())
      .then((organiztionData) => {
        setOrganization_location(organiztionData);
      })
      .catch((error) => {});
  };

  const fetchSound = async () => {
    try {
      const response = await fetch(
        `${config.urlSound}/api/v1/alarm-sound/${Organization_location.message.soundWarning}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.blob();
      setSoundUrl(URL.createObjectURL(data));
    } catch (error) {}
  };

  useEffect(() => {
    let intervalId;
    if (token && !auth.Role) {
      intervalId = setInterval(fetchData, 1000);
    }
    return () => {
      clearInterval(intervalId);
    };
  }, [auth]);

  useEffect(() => {
    let intervalId;
    if (token && Organization_location.status === 200 && !Organization_location.message[1] && !soundUrl) {
      intervalId = setInterval(fetchSound, 1000);
    }
    return () => {
      clearInterval(intervalId);
    };
  }, [soundUrl, Organization_location.status]);

  // useEffect(() => {
  //   socketRef.current = new WebSocket(`${config.urlWebsocket}`);
  //   if (auth) {
  //     if (token && auth.Role.roleId !== 24 && auth.Role.roleId !== 29) {
  //       socketRef.current.onmessage = (event) => {
  //         const data = JSON.parse(event.data);
  //         setWarningData(data);
  //       };
  //     }
  //   }

  //   return () => {
  //     if (socketRef.current) {
  //       socketRef.current.close();
  //     }
  //   };
  // }, [auth]);

  // useEffect(() => {
  //   const filteredDataWarning = warningData && warningData[0] && warningData.filter(item => item.keyName === auth.keyName);
  //   const highestLevelObject = filteredDataWarning ? filteredDataWarning.reduce((acc, obj) => {
  //     if (!acc || obj.level > acc.level) {
  //       return obj;
  //     }
  //     return acc;
  //   }, null) : null;
  //   const result = highestLevelObject ? [highestLevelObject] : [];
  //   let intervalId;
  //   if (warningData && errorfix > 0 && result[0]) {
  //     setAlertData((prevAlerts) => {
  //       let updatedAlerts = [...prevAlerts, result[0]];
  //       let updatedData = updatedAlerts.map((item) => ({ ...item, time: 15 }));
  //       return updatedData;
  //     });
  //   } else if (warningData) {
  //     setErrorfix(1);
  //   }
  //   return () => {
  //     clearInterval(intervalId);
  //   };
  // }, [warningData]);

  if (!token && !auth) {
    return <LoginPage />;
  }

  if (auth.Role) {
    const permission = auth.Role.Permissions;
    const hasPermission = (permissionName, permissionType) => {
      return permission.some((permission) => permission.name === permissionName && permission.type === permissionType);
    };

    return (
      <showSidebar.Provider
        value={{
          reportWarn,
          setReportWarn,
          showSidebars,
          setShowSidebar,
          auth,
          setAuth,
          Organization_location,
          warningData,
          soundUrl,
          alertData,
          setAlertData,
          organization_setting,
          setOrganization_Setting,
          soundWarning,
          setSoundWarning,
          RouteKeyName,
          setRouteKeyName,
        }}
      >
        <div className="background-page">
          <Menubar />
          <BurgerMenu isOpen={updateMenu} status={showSidebars} />
          <Sidebar status={showSidebars} router={true} />
          {/* {alertData && !hasPermission("Home", "update") && <AlertEfield audio={soundWarning} />} */}
          <Routes>
            <Route path="HomePage" element={<MapView audioAPI={soundWarning} />} />
            {hasPermission("Location", "read") && (
              <Route
                path="organization"
                element={
                  <Organization
                    role={auth.Role.roleId !== 24 && auth.Role.roleId !== 29 && true}
                    create={hasPermission("Location", "create") && true}
                    update={hasPermission("Location", "update") && true}
                    remove={hasPermission("Location", "delete") && true}
                  />
                }
              />
            )}

            {hasPermission("Role and permission", "read") && (
              <Route
                path="Permission"
                element={
                  <Permission
                    Admin={(auth.Role.roleId === 24 || auth.Role.roleId === 29) && true}
                    superAdmin={auth.Role.roleId === 29 && true}
                    create={hasPermission("Role and permission", "create") && true}
                    update={hasPermission("Role and permission", "update") && true}
                    remove={hasPermission("Role and permission", "delete") && true}
                  />
                }
              />
            )}

            {hasPermission("Users", "read") && (
              <Route
                path="user-information"
                element={
                  <UserSetting
                    superAdmin={auth.Role.roleId === 29 && true}
                    create={hasPermission("Users", "create") && true}
                    update={hasPermission("Users", "update") && true}
                    remove={hasPermission("Users", "delete") && true}
                    role={auth.Role.roleId !== 24 && auth.Role.roleId !== 29 && true}
                  />
                }
              />
            )}

            {hasPermission("Location", "update") && (
              <Route path={`organization/setting-organization/${pathName}`} element={<SetOrganization />} />
            )}

            {hasPermission("Notifications", "read") && (
              <Route
                path="notification-sound"
                element={
                  <SoundPage
                    create={hasPermission("Notifications", "create") && true}
                    update={hasPermission("Notifications", "update") && true}
                    remove={hasPermission("Notifications", "delete") && true}
                  />
                }
              />
            )}

            {hasPermission("E-field sensor", "read") && (
              <Route
                path="E-Field"
                element={
                  <Efield
                    create={hasPermission("E-field sensor", "create") && true}
                    update={hasPermission("E-field sensor", "update") && true}
                    remove={hasPermission("E-field sensor", "delete") && true}
                  />
                }
              />
            )}

            {hasPermission("Device IOT", "read") && (
              <Route
                path="DeviceStatus"
                element={
                  <DeviceStatus
                    create={hasPermission("Device IOT", "create") && true}
                    update={hasPermission("Device IOT", "update") && true}
                    remove={hasPermission("Device IOT", "delete") && true}
                  />
                }
              />
            )}

            {hasPermission("Report", "all") && (
              <Route
                path="report"
                element={<Report role={auth.Role.roleId !== 24 && auth.Role.roleId !== 29 && true} />}
              />
            )}
            {hasPermission("Report", "all") && (
              <Route path={`report/warningReport/${reportWarn.keyName}`} element={<WarningReport user={false} />} />
            )}
            {hasPermission("Test send", "all") && <Route path="test-send" element={<Line />} />}
            <Route path="*" element={<MapView />} />
          </Routes>
        </div>
      </showSidebar.Provider>
    );
  } else if (token && !auth.Role) {
    return (
      <showSidebar.Provider
        value={{
          showSidebars,
          setShowSidebar,
          auth,
          setAuth,
          Organization_location,
          soundUrl,
        }}
      >
        <div className="background-page">
          <Menubar />
          <BurgerMenu />
          <Sidebar sidebar={showSidebars} router={true} />
        </div>
      </showSidebar.Provider>
    );
  }
}
export default App;
export { showSidebar };
