import React, { useContext, useEffect, useState } from "react";
import "../Routers.css";
import DataTable from 'react-data-table-component';
import Cookies from 'js-cookie';
import Container from "../Container";
import apiUrls from "../../ApiUrl";
import PDF from "./PDF";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { showSidebar } from "../../kumwell";
import { customStyles } from "../DataTableStyle";
import { useNavigate } from "react-router-dom";
export default function Report({ role }) {
    const [pending, setPending] = useState(true);
    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState([]);
    const [retryCount, setRetryCount] = useState(0);
    const [errorFetch, setErrorFetch] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const token = (Cookies.get('accessToken'));
    const navigate = useNavigate();
    const { setReportWarn, auth } = useContext(showSidebar)
    const fetchData = () => {
        const url = apiUrls.OrganizationUrl_byKeyName;
        fetch(url,
            {
                method: 'GET', headers: {
                    Authorization: `Bearer ${token}`,
                }
            })
            .then(res => res.json())
            .then(data => {
                if (data.status === 200) {
                    setIsLoaded(true);
                    setItems(data);
                } else {
                    setIsLoaded(false);
                    console.log(data);
                }
            })
            .catch(error => {
                setIsLoaded(false);
                setErrorFetch(error);
                const retryDelay = 3000;
                setTimeout(() => setRetryCount(retryCount + 1), retryDelay);
            });
    };

    const ReportOrganization = (data) => {
        Cookies.set("pathName", data.keyName);
        setReportWarn(data);
        navigate(`/report/warningReport/${data.keyName}`);
    }

    useEffect(() => {
        if (role) {
            apiUrls.setKeyNameReport(auth.keyName);
            fetchData();

        } else {
            fetchData();
        }
    }, [retryCount]);

    const columns = [
        {
            name: '#',
            selector: row => row.id,
            sortable: true,
            width: '57px'

        },
        {
            name: 'Location name',
            selector: row => row.nameLocation,
            sortable: true,
            width: '200px'
        },

        {
            name: 'Key name',
            selector: row => row.keyName,
            sortable: true,
            width: 'auto'
        },
        {
            name: '',
            cell: (row) => (<><button className="setting-btn" onClick={() => ReportOrganization(row)}>Warning Report</button></>),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            width: '180px'
        },
    ];

    const CustomLoader = () => (
        <div style={{ padding: '40px' }}>
            <span className="loader"></span>
            <p>{errorFetch.message}</p>
        </div>
    );

    if (token != null && isLoaded === true) {
        const data = role ? [items.message] : items.message;
        const dataWithIds = data.map((item, index) => ({ id: index + 1, ...item }));
        const filteredData = dataWithIds.filter((item) => {
            const { keyName } = item;
            const searchValue = searchQuery.toLowerCase();
            return keyName.toLowerCase().includes(searchValue);
        });
        return (
            <Container
                title={<>Report</>}
                components={
                    <>
                        {/* {warningData.status === 200 &&
                            <PDFDownloadLink document={<PDF data={warningData.message} />} fileName="OK">
                                {({ loading }) => (loading ? <button>Loading Document....</button> : <button>Download PDF</button>)}
                            </PDFDownloadLink>} */}
                        <input className="Search" type="text" onChange={e => setSearchQuery(e.target.value)} placeholder="Search" />
                        <DataTable
                            columns={columns}
                            data={filteredData}
                            pagination
                            fixedHeader
                            customStyles={customStyles}
                        />
                    </>
                }
            />
        );
    }
    else {
        return (
            <Container
                title={<>Report</>}
                components={
                    <>
                        <DataTable
                            progressPending={pending}
                            progressComponent={<CustomLoader />}
                        />
                    </>
                }
            />
        )
    }
};