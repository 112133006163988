import React , { memo }from "react";
import "../navigation-css/Sidebar.css";
import "../navigation-css/Menubar.css";
const BurgerMenu = memo(({ isOpen, status }) => {
    const burger_class = status === "sidebar-On" ? "burger-bar unclicked" : "burger-bar clicked";
    const menu_select = status === "sidebar-On" ? "berger-Active" : "berger-UnActive";

    return (
        
        <div className={menu_select}>
            <div className="burger-menu" onClick={isOpen}>
                <div className={burger_class}></div>
                <div className={burger_class}></div>
                <div className={burger_class}></div>
            </div>
        </div>
    );
});

export default BurgerMenu;