import { Alert, Button, Collapse, DialogActions, DialogContent, IconButton, Snackbar, Typography } from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";

const config = require("./../../../../configs/app");

function DeleteDevice({ handleClose, data, fetchDevice, setAlert }) {
  const [open, setOpen] = React.useState({
    open: false,
    message: "",
  });

  const handleDelete = () => {
    fetch(`${config.urlDevice}/api/v1/device/${data.id}`, {
      method: "DELETE",
    })
      .then(async (res) => {
        if (res.ok) {
          fetchDevice();
          handleClose();
          setAlert({
            open: true,
            message: `Delete ${data.nameDevice} success`,
            severity: "success",
          });
        } else {
          const error = await res.json();
          console.error(error);
          setOpen({
            open: true,
            message: `Delete fail ${error.message}`,
          });
        }
      })
      .catch((error) => {
        console.error(error);
        setOpen({
          open: true,
          message: `Delete fail`,
        });
      });
  };

  return (
    <>
      <DialogContent dividers>
        <Typography align="center" color="error">
          Are you sure you want to delete this device?
        </Typography>
        <Typography align="center">Name Device : {data.nameDevice}</Typography>
        <Typography align="center">uid Device : {data.uid}</Typography>

        {/* alert */}
        <Collapse in={open.open}>
          <Alert
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setOpen({
                    open: false,
                    message: "",
                  });
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {open.message}
          </Alert>
        </Collapse>
      </DialogContent>
      <DialogActions>
        <Button variant="text" disableRipple onClick={handleClose} color="inherit">
          Close
        </Button>
        <Button variant="text" disableRipple onClick={handleDelete} color="error">
          Delete
        </Button>
      </DialogActions>
    </>
  );
}

export default DeleteDevice;
