import ".././Routers.css";
import Container from "../Container";

import React from 'react';
import SettingsPage from "./PermissionPage";
export default function Permission({ create, update, remove ,Admin , superAdmin}) {
    return (
        <>
            <Container title={<>Role and permission</>}
                components={
                    <React.Fragment>
                        <SettingsPage create={create} update={update} remove={remove} Admin={Admin} superAdmin={superAdmin}/>
                    </React.Fragment>
                }
            />
        </>
    );
};