import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";

const config = require("./../../../../configs/app");
var numeral = require("numeral");

function converterZoneNameToNumber(zone, data) {
  if (zone === "0") {
    return 0;
  } else if (zone === "R") {
    return numeral(data.activeZoneR).format("0,0");
  } else if (zone === "A") {
    return numeral(data.activeZoneA).format("0,0");
  } else if (zone === "B") {
    return numeral(data.activeZoneB).format("0,0");
  } else {
    return zone;
  }
}

function converterZoneNameToRealNumber(zone, data) {
  if (zone === "0") {
    return 0;
  } else if (zone === "R") {
    return data.activeZoneR;
  } else if (zone === "A") {
    return data.activeZoneA;
  } else if (zone === "B") {
    return data.activeZoneB;
  } else {
    return zone;
  }
}

export default function EditSettingLevel({ warningId, fetchDataWarningConfig, onCloseModal, level, organization }) {
  const [data, setData] = useState([]);

  const fetchDataWarningConfigId = async () => {
    const res = await fetch(`${config.urlLightningConfig}/api/v1/warning-config/id/${warningId}`);
    const response = await res.json();
    const data = {
      ...response.message,
      eFieldConfig: {
        ...response.message.eFieldConfig,
        eFieldSenSor:
          response.message.eFieldConfig.eFieldSenSor && response.message.eFieldConfig.eFieldSenSor.warningPointId,
      },
    };

    setData(data);
  };

  useEffect(() => {
    fetchDataWarningConfigId();
  }, []);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name.includes(".")) {
      const [outer, inner] = name.split(".");
      setData({
        ...data,
        [outer]: {
          ...data[outer],
          [inner]: value,
        },
      });
    } else {
      setData({
        ...data,
        [name]: value,
      });
    }
  };

  const handleSwitchChange = (event) => {
    const { name, checked } = event.target;
    if (name.includes(".")) {
      const [outer, inner] = name.split(".");
      setData({
        ...data,
        [outer]: {
          ...data[outer],
          [inner]: checked,
        },
      });
    } else {
      setData({
        ...data,
        [name]: checked,
      });
    }
  };

  const handleSubmit = async () => {
    // console.log("data before PUT:", data);

    const start = converterZoneNameToRealNumber(data.activeZoneStart, organization);
    const end = converterZoneNameToRealNumber(data.activeZoneEnd, organization);

    if (data.enableActiveZone) {
      if (start === "" || end === "") {
        alert("Please select an active zone");
        return;
      }
      if (start === 0 && end === 0) {
        alert("Please select an active zone");
        return;
      }
      if (start >= end) {
        alert("Active Zone is invalid");
        return;
      }
    }

    if (data.mFieldConfig.enableMFieldType && data.mFieldConfig.mFieldType === 0) {
      alert("Please select a lightning type");
      return;
    }

    if (data.mFieldConfig.enableMFieldCounter) {
      if (data.mFieldConfig.mFieldCounterSymbol === "") {
        alert("Please select a symbol");
        return;
      }
      if (data.mFieldConfig.mFieldCounter === 0) {
        alert("Please enter the number of times lightning");
        return;
      }
      if (data.mFieldConfig.mFieldCounterInTime === 0) {
        alert("Please fill in in time");
        return;
      }
    }

    if (data.mFieldConfig.enableMFieldAmplitude) {
      if (data.mFieldConfig.mFieldAmplitudeSymbol === "") {
        alert("Please select a symbol");
        return;
      }
      if (data.mFieldConfig.mFieldAmplitude === 0) {
        alert("Please enter lightning amplitude (kA)");
        return;
      }
    }

    if (
      data.eFieldConfig.enableEFieldLevel ||
      data.eFieldConfig.enableEFieldChange ||
      data.eFieldConfig.enableEFieldValue
    ) {
      if (!data.eFieldConfig.eFieldSenSor) {
        alert("Please select Warning Point ID");
        return;
      }
    }

    if (data.eFieldConfig.enableEFieldLevel) {
      if (data.eFieldConfig.eFieldLevel === 0) {
        alert("Please select notification level");
        return;
      }
    }

    if (data.eFieldConfig.enableEFieldChange) {
      if (data.eFieldConfig.eFieldChangeAbsolute === 0) {
        alert("Please enter the electric field change");
        return;
      }
    }

    if (data.eFieldConfig.enableEFieldValue) {
      if (data.eFieldConfig.eFieldValueSymbol === "") {
        alert("Please select a symbol");
        return;
      }
      if (data.eFieldConfig.eFieldValue === 0) {
        alert("Please enter the electric field value");
        return;
      }
    }

    data.eFieldConfig = {
      ...data.eFieldConfig,
      EFieldSensorWarningPointId: data.eFieldConfig.eFieldSenSor ? data.eFieldConfig.eFieldSenSor : null,
    };

    const res = await fetch(`${config.urlLightningConfig}/api/v1/warning-config/${warningId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    const response = await res.json();
    // console.log("response:", response);
    if (response.status === 200) {
      fetchDataWarningConfig();
      onCloseModal();
    }
  };

  return (
    <>
      <Grid container direction="column" spacing={1}>
        <Grid item>
          <Typography fontWeight="bold">
            Edit Warning Level {level} ID: {warningId}
          </Typography>
        </Grid>

        {data.warningId ? (
          <Grid item>
            <TableContainer component={Paper}>
              <Table size="small">
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <FormControlLabel
                        control={
                          <Checkbox
                            type="checkbox"
                            size="small"
                            name="enableActiveZone"
                            onChange={handleSwitchChange}
                            checked={Boolean(data.enableActiveZone)}
                          />
                        }
                        label="Active Zone"
                        size="small"
                      />
                    </TableCell>
                    <TableCell sx={{ width: 250 }}>
                      <FormControl fullWidth size="small">
                        <InputLabel>Active Zone Start</InputLabel>
                        <Select
                          label="Active Zone Start"
                          size="small"
                          name="activeZoneStart"
                          value={data.activeZoneStart || "0"}
                          onChange={handleInputChange}
                        >
                          <MenuItem value={"0"}>Active Zone Start : 0 meter</MenuItem>
                          <MenuItem value={"R"}>
                            Active Zone R : {converterZoneNameToNumber("R", organization)} meter
                          </MenuItem>
                          <MenuItem value={"A"}>
                            Active Zone A : {converterZoneNameToNumber("A", organization)} meter
                          </MenuItem>
                          <MenuItem value={"B"}>
                            Active Zone B : {converterZoneNameToNumber("B", organization)} meter
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </TableCell>
                    <TableCell sx={{ width: 250 }}>
                      <FormControl fullWidth size="small">
                        <InputLabel>Active Zone End</InputLabel>
                        <Select
                          label="Active Zone End"
                          size="small"
                          name="activeZoneEnd"
                          value={data.activeZoneEnd || "0"}
                          onChange={handleInputChange}
                        >
                          <MenuItem value={"0"}>Active Zone End : 0 meter</MenuItem>
                          <MenuItem value={"R"}>
                            Active Zone R : {converterZoneNameToNumber("R", organization)} meter
                          </MenuItem>
                          <MenuItem value={"A"}>
                            Active Zone A : {converterZoneNameToNumber("A", organization)} meter
                          </MenuItem>
                          <MenuItem value={"B"}>
                            Active Zone B : {converterZoneNameToNumber("B", organization)} meter
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <FormControlLabel
                        control={
                          <Checkbox
                            type="checkbox"
                            size="small"
                            name="mFieldConfig.enableMFieldType"
                            onChange={handleSwitchChange}
                            checked={data.mFieldConfig.enableMFieldType}
                          />
                        }
                        label="M-Field : Types of Lightning"
                        size="small"
                      />
                    </TableCell>
                    <TableCell colSpan={2}>
                      <FormControl fullWidth size="small">
                        <InputLabel>Types of Lightning</InputLabel>
                        <Select
                          label="Types of Lightning"
                          size="small"
                          name="mFieldConfig.mFieldType"
                          value={data.mFieldConfig.mFieldType || 0}
                          onChange={handleInputChange}
                        >
                          <MenuItem value={0}>-</MenuItem>
                          <MenuItem value={1}>Cloud to Ground</MenuItem>
                          <MenuItem value={2}>Cloud to Cloud</MenuItem>
                        </Select>
                      </FormControl>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <FormControlLabel
                        control={
                          <Checkbox
                            type="checkbox"
                            size="small"
                            name="mFieldConfig.enableMFieldCounter"
                            onChange={handleSwitchChange}
                            checked={Boolean(data.mFieldConfig.enableMFieldCounter)}
                          />
                        }
                        label="M-Field : Counter of times lightning"
                        size="small"
                      />
                    </TableCell>
                    <TableCell sx={{ width: 250 }}>
                      <FormControl fullWidth size="small">
                        <InputLabel>symbol</InputLabel>
                        <Select
                          label="symbol"
                          size="small"
                          name="mFieldConfig.mFieldCounterSymbol"
                          value={data.mFieldConfig.mFieldCounterSymbol || ""}
                          onChange={handleInputChange}
                        >
                          <MenuItem value={""}>-</MenuItem>
                          <MenuItem value={"<="}>{"<="} : less than or equal to</MenuItem>
                          <MenuItem value={">="}>{">="} : greater than or equal to</MenuItem>
                        </Select>
                      </FormControl>
                    </TableCell>
                    <TableCell sx={{ width: 250 }}>
                      <FormControl fullWidth size="small">
                        <TextField
                          type="number"
                          label="Counter of times lightning"
                          variant="outlined"
                          size="small"
                          name="mFieldConfig.mFieldCounter"
                          value={data.mFieldConfig.mFieldCounter}
                          onChange={handleInputChange}
                        />
                      </FormControl>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell />
                    <TableCell />
                    <TableCell>
                      <FormControl fullWidth size="small">
                        <TextField
                          type="number"
                          label="in time (นาที)"
                          variant="outlined"
                          size="small"
                          name="mFieldConfig.mFieldCounterInTime"
                          value={data.mFieldConfig.mFieldCounterInTime}
                          onChange={handleInputChange}
                        />
                      </FormControl>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>
                      <FormControlLabel
                        control={
                          <Checkbox
                            type="checkbox"
                            size="small"
                            name="mFieldConfig.enableMFieldAmplitude"
                            onChange={handleSwitchChange}
                            checked={Boolean(data.mFieldConfig.enableMFieldAmplitude)}
                          />
                        }
                        label="M-Field : Amplitude"
                        size="small"
                      />
                    </TableCell>
                    <TableCell sx={{ width: 250 }}>
                      <FormControl fullWidth size="small">
                        <InputLabel>symbol</InputLabel>
                        <Select
                          label="symbol"
                          size="small"
                          name="mFieldConfig.mFieldAmplitudeSymbol"
                          value={data.mFieldConfig.mFieldAmplitudeSymbol || ""}
                          onChange={handleInputChange}
                        >
                          <MenuItem value={""}>-</MenuItem>
                          <MenuItem value={"<="}>{"<="} : less than or equal to</MenuItem>
                          <MenuItem value={">="}>{">="} : greater than or equal to</MenuItem>
                        </Select>
                      </FormControl>
                    </TableCell>
                    <TableCell sx={{ width: 250 }}>
                      <FormControl fullWidth size="small">
                        <TextField
                          label="Amplitude (kA)"
                          variant="outlined"
                          size="small"
                          type="number"
                          step="0.1"
                          name="mFieldConfig.mFieldAmplitude"
                          value={data.mFieldConfig.mFieldAmplitude}
                          onChange={handleInputChange}
                        />
                      </FormControl>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>
                      <Typography>E-Field : E-Field Sensor</Typography>
                    </TableCell>
                    <TableCell colSpan={4}>
                      <FormControl fullWidth size="small">
                        <InputLabel>Warning Point ID</InputLabel>
                        <Select
                          label="Warning Point ID"
                          size="small"
                          name="eFieldConfig.eFieldSenSor"
                          value={data.eFieldConfig.eFieldSenSor || ""}
                          onChange={handleInputChange}
                        >
                          <MenuItem value={""}>-</MenuItem>
                          {organization.EFieldSensors.map((sensor) => (
                            <MenuItem value={sensor.warningPointId} key={sensor.warningPointId}>
                              {sensor.warningPointId} Latitude: {sensor.lat} Longitude: {sensor.lon}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <FormControlLabel
                        control={
                          <Checkbox
                            type="checkbox"
                            size="small"
                            name="eFieldConfig.enableEFieldLevel"
                            onChange={handleSwitchChange}
                            checked={Boolean(data.eFieldConfig.enableEFieldLevel)}
                          />
                        }
                        label="E-Field : Level"
                        size="small"
                      />
                    </TableCell>
                    <TableCell colSpan={4}>
                      <FormControl fullWidth size="small">
                        <InputLabel>Level</InputLabel>
                        <Select
                          label="Level"
                          size="small"
                          name="eFieldConfig.eFieldLevel"
                          value={data.eFieldConfig.eFieldLevel || 0}
                          onChange={handleInputChange}
                        >
                          <MenuItem value={0}>-</MenuItem>
                          <MenuItem value={1}>1</MenuItem>
                          <MenuItem value={2}>2</MenuItem>
                          <MenuItem value={3}>3</MenuItem>
                        </Select>
                      </FormControl>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>
                      <FormControlLabel
                        control={
                          <Checkbox
                            type="checkbox"
                            size="small"
                            name="eFieldConfig.enableEFieldChange"
                            onChange={handleSwitchChange}
                            checked={data.eFieldConfig.enableEFieldChange}
                          />
                        }
                        label="E-Field : Change in the electric field"
                        size="small"
                      />
                    </TableCell>
                    <TableCell colSpan={4}>
                      <FormControl fullWidth size="small">
                        <TextField
                          label="Change in the electric field value (V/m)"
                          variant="outlined"
                          size="small"
                          type="number"
                          step="0.1"
                          name="eFieldConfig.eFieldChangeAbsolute"
                          value={data.eFieldConfig.eFieldChangeAbsolute}
                          onChange={handleInputChange}
                        />
                      </FormControl>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>
                      <FormControlLabel
                        control={
                          <Checkbox
                            type="checkbox"
                            size="small"
                            name="eFieldConfig.enableEFieldValue"
                            onChange={handleSwitchChange}
                            checked={Boolean(data.eFieldConfig.enableEFieldValue)}
                          />
                        }
                        label="E-Field : greater than the electric field (absolute)"
                        size="small"
                      />
                    </TableCell>
                    <TableCell colSpan={4}>
                      <FormControl fullWidth size="small">
                        <TextField
                          label="greater than the electric field (absolute) (V/m)"
                          variant="outlined"
                          size="small"
                          type="number"
                          step="0.1"
                          name="eFieldConfig.eFieldValue"
                          value={data.eFieldConfig.eFieldValue}
                          onChange={handleInputChange}
                        />
                      </FormControl>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>

            <Grid container justifyContent="flex-end" mt={2}>
              <Grid item>
                <Button variant="outlined" color="error" onClick={onCloseModal}>
                  Cancel
                </Button>

                <Button variant="contained" color="success" sx={{ marginLeft: 2 }} onClick={handleSubmit}>
                  Save
                </Button>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <>
            {/* loading spiner */}
            <Stack
              sx={{
                height: "50vh",
                zIndex: 9999,
              }}
              justifyContent="center"
              alignItems="center"
            >
              <Box sx={{ display: "flex" }}>
                <CircularProgress color="inherit" />
              </Box>
            </Stack>
          </>
        )}
      </Grid>
    </>
  );
}
