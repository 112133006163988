import {
  Alert,
  Button,
  Checkbox,
  Collapse,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import apiUrls from "../../../../ApiUrl";

const config = require("./../../../../configs/app");
function AddDevice({ handleClose, fetchDevice, setAlert, admin }) {
  const [error, setError] = useState({
    isError: false,
    message: "",
  });
  const token = Cookies.get("accessToken");
  const key = Cookies.get("pathName");
  const [keySelect, setKeySelect] = useState([]);
  const [items, setItem] = useState([]);
  const { control, handleSubmit, getValues } = useForm();

  const onSubmit = (data) => {
    // console.log(data);
    addDevice(data);
  };

  const fetchKeyName = async () => {
    try {
      const response = await fetch(apiUrls.OrganizationUrl_GET, {
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
      });
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }
      const data = await response.json();
      const orgselect = data.message.map((item) => item.keyName);
      setItem(orgselect);
      setKeySelect(orgselect[0]);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (admin) {
      fetchKeyName();
    }
  }, []);

  const addDevice = async (data) => {
    // analyze data

    const obj = {
      ...data,
      keyName: admin ? keySelect : key,
      activeDay: [data.Monday, data.Tuesday, data.Wednesday, data.Thursday, data.Friday, data.Saturday, data.Sunday]
        .map((item) => (item ? 1 : 0))
        .join(""),
      relay: Array(8)
        .fill(0)
        .map((item, index) => ({
          channel: index + 1,
          normalOn: data[`relayActiveHighCH${index + 1}`] ? 1 : 0,
          normalOff: data[`relayActiveHighCH${index + 1}`] ? 0 : 1,
          enableActiveTime: data[`enableActiveTimeCH${index + 1}`],
          triggerTime: data[`triggerTimeCH${index + 1}`],
          level3: data[`level3CH${index + 1}`],
          level2: data[`level2CH${index + 1}`],
          level1: data[`level1CH${index + 1}`],
          level0: data[`level0CH${index + 1}`],
        })),
    };

    const res = await fetch(`${config.urlDevice}/api/v1/device/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(obj),
    });

    if (res.ok) {
      // console.log("Add Device Success",await res.json());
      fetchDevice();
      handleClose();
      setAlert({
        open: true,
        message: `Add ${data.nameDevice} success`,
        severity: "success",
      });
    } else {
      const resJson = await res.json();
      setError({
        isError: true,
        message: resJson.message,
      });
    }
  };
  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <form onSubmit={handleSubmit(onSubmit)}>
            {/* <Divider
              textAlign="center"
              sx={{
                marginBottom: 2,
              }}
            >
              <Typography>ข้อมูลอุปกรณ์</Typography>
            </Divider> */}
            {
              admin && items && (
                <div>
                  <InputLabel>Organization</InputLabel>
                  <Select
                    value={keySelect || ""}
                    fullWidth
                    sx={{ height: "45px" }}
                    onChange={(e) => setKeySelect(e.target.value)}
                  >
                    {items.map((item, index) => (
                      <MenuItem key={index} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              )

              // <Controller
              //   name="nameDevice"
              //   control={control}
              //   defaultValue=""
              //   rules={{ required: true }}
              //   render={({ field, fieldState }) => (
              //     <FormControl fullWidth size="small">
              //       <TextField
              //         type="text"
              //         label="Name Device"
              //         required
              //         error={fieldState.invalid}
              //         helperText={fieldState.invalid ? "Name Device is required" : ""}
              //         variant="outlined"
              //         size="small"
              //         {...field}
              //       />
              //     </FormControl>
              //   )}
              // />
            }
            <Controller
              name="nameDevice"
              control={control}
              defaultValue=""
              rules={{ required: true }}
              render={({ field, fieldState }) => (
                <FormControl fullWidth size="small" style={admin && { marginTop: "10px" }}>
                  <TextField
                    type="text"
                    label="Name Device"
                    required
                    error={fieldState.invalid}
                    helperText={fieldState.invalid ? "Name Device is required" : ""}
                    variant="outlined"
                    size="small"
                    {...field}
                  />
                </FormControl>
              )}
            />

            <Grid container display="flex" spacing={1} justifyContent="center">
              <Grid item xs={6}>
                <Controller
                  name="lat"
                  defaultValue=""
                  control={control}
                  render={({ field, fieldState }) => (
                    <FormControl size="small" fullWidth sx={{ marginTop: 1.5 }}>
                      <TextField
                        type="number"
                        step="0.1"
                        label="Latitude"
                        variant="outlined"
                        size="small"
                        error={fieldState.invalid}
                        helperText={fieldState.invalid ? "latitude is required" : ""}
                        {...field}
                      />
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="lon"
                  defaultValue=""
                  control={control}
                  render={({ field, fieldState }) => (
                    <FormControl size="small" fullWidth sx={{ marginTop: 1.5 }}>
                      <TextField
                        type="number"
                        step="0.1"
                        label="Longitude"
                        variant="outlined"
                        size="small"
                        error={fieldState.invalid}
                        helperText={fieldState.invalid ? "longitude is required" : ""}
                        {...field}
                      />
                    </FormControl>
                  )}
                />
              </Grid>
            </Grid>

            <Controller
              name="description"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <FormControl fullWidth size="small" sx={{ marginTop: 1.5 }}>
                  <TextField type="text" label="Description" variant="outlined" size="small" {...field} />
                </FormControl>
              )}
            />

            <Divider
              textAlign="center"
              sx={{
                marginTop: 2,
              }}
            >
              <Typography>Active Date Time</Typography>
            </Divider>

            <Grid container display="flex" spacing={1} justifyContent="center">
              <Grid item xs={12} display="flex" alignItems="center" justifyContent="space-between">
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center"></TableCell>
                      <TableCell align="center">
                        <Typography>Monday</Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography>Tuesday</Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography>Wednesday</Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography>Thursday</Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography>Friday</Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography>Saturday</Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography>Sunday</Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell align="center">Enable Active Day</TableCell>
                      {["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"].map(
                        (item, index) => (
                          <TableCell align="center" key={index}>
                            <Controller
                              name={item}
                              control={control}
                              size="small"
                              defaultValue={false}
                              // rules={{ required: true }}
                              render={({ field }) => <Checkbox type="checkbox" size="medium" {...field} />}
                            />
                          </TableCell>
                        )
                      )}
                    </TableRow>
                  </TableBody>
                </Table>
              </Grid>
            </Grid>

            <Grid container display="flex" spacing={1} justifyContent="center">
              <Grid item xs={6}>
                <Controller
                  name="activeTimeStart"
                  control={control}
                  // rules={{ required: true }}
                  defaultValue={"00:00"}
                  render={({ field }) => (
                    <FormControl size="small" fullWidth sx={{ marginTop: 1.5 }}>
                      <TextField type="time" label="Time Start" variant="outlined" size="small" {...field} />
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="activeTimeEnd"
                  control={control}
                  // rules={{ required: true }}
                  defaultValue={"23:59"}
                  render={({ field }) => (
                    <FormControl size="small" fullWidth sx={{ marginTop: 1.5 }}>
                      <TextField type="time" label="Time End" variant="outlined" size="small" {...field} />
                    </FormControl>
                  )}
                />
              </Grid>
            </Grid>

            <Divider
              textAlign="center"
              sx={{
                marginTop: 2,
              }}
            >
              <Typography>notification information (In the event that the device stops working)</Typography>
            </Divider>

            <Grid container alignItems="center">
              <Grid item xs={4}>
                <Typography>Can't contact for more than (minutes)</Typography>
              </Grid>
              <Grid item xs={8}>
                <Controller
                  name="timeOutError"
                  control={control}
                  defaultValue={1}
                  rules={{ required: true }}
                  render={({ field, fieldState }) => (
                    <FormControl size="small" fullWidth sx={{ marginTop: 1.5 }}>
                      <TextField
                        type="number" // Changed to "number" type for numeric input
                        step="1"
                        label="Can't contact for more than (minutes)"
                        required
                        error={fieldState.invalid}
                        helperText={fieldState.invalid ? "Timeout Error is required" : ""}
                        variant="outlined"
                        size="small"
                        {...field}
                      />
                    </FormControl>
                  )}
                />
              </Grid>
            </Grid>

            <Grid container mt={2}>
              <Grid item xs={4}>
                <Controller
                  name="lineSent"
                  control={control}
                  defaultValue={false}
                  render={({ field }) => (
                    <FormControlLabel
                      control={<Checkbox type="checkbox" size="small" {...field} />}
                      label="Sent Line Notify"
                      size="small"
                    />
                  )}
                />
              </Grid>

              <Grid item xs={8}>
                <Controller
                  name="lineToken"
                  control={control}
                  defaultValue=""
                  rules={{
                    validate: {
                      requiredIfLineSent: (value) => {
                        const isLineSent = getValues("lineSent");
                        if (isLineSent && !value) {
                          return "required";
                        }
                        return true;
                      },
                    },
                  }}
                  render={({ field, fieldState }) => (
                    <FormControl size="small" fullWidth sx={{ marginTop: 1.5 }}>
                      <TextField
                        type="text"
                        label="Line Token"
                        variant="outlined"
                        size="small"
                        error={!!fieldState.error} // Show error state based on validation
                        helperText={fieldState.error && fieldState.error.message}
                        {...field}
                      />
                    </FormControl>
                  )}
                />
                <Controller
                  name="lineText"
                  control={control}
                  defaultValue=""
                  rules={{
                    validate: {
                      requiredIfLineSent: (value) => {
                        const isLineSent = getValues("lineSent");
                        if (isLineSent && !value) {
                          return "required";
                        }
                        return true;
                      },
                    },
                  }}
                  render={({ field, fieldState }) => (
                    <FormControl size="small" fullWidth sx={{ marginTop: 1.5 }}>
                      <TextField
                        type="text"
                        label="Message"
                        variant="outlined"
                        size="small"
                        error={!!fieldState.error} // Show error state based on validation
                        helperText={fieldState.error && fieldState.error.message}
                        {...field}
                      />
                    </FormControl>
                  )}
                />
              </Grid>
            </Grid>

            <Grid container mt={2}>
              <Grid item xs={12}>
                <Controller
                  name="enable"
                  control={control}
                  defaultValue={true}
                  render={({ field }) => (
                    <FormControlLabel
                      control={<Checkbox type="checkbox" defaultChecked size="small" {...field} />}
                      label="Enable Device"
                      size="small"
                    />
                  )}
                />
                {/* <Controller
                  name="statusSimulation"
                  control={control}
                  defaultValue={false}
                  render={({ field }) => (
                    <FormControlLabel
                      control={<Checkbox type="checkbox" size="small" {...field} />}
                      label="โหมดจำลองการทำงาน"
                      size="small"
                    />
                  )}
                /> */}
              </Grid>
            </Grid>

            <Divider
              textAlign="center"
              sx={{
                marginTop: 2,
              }}
            >
              <Typography>Setting Channel Relay</Typography>
            </Divider>

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center"></TableCell>
                      <TableCell align="center">CH1</TableCell>
                      <TableCell align="center">CH2</TableCell>
                      <TableCell align="center">CH3</TableCell>
                      <TableCell align="center">CH4</TableCell>
                      <TableCell align="center">CH5</TableCell>
                      <TableCell align="center">CH6</TableCell>
                      <TableCell align="center">CH7</TableCell>
                      <TableCell align="center">CH8</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell align="center">Enable Active Time</TableCell>
                      {[
                        "enableActiveTimeCH1",
                        "enableActiveTimeCH2",
                        "enableActiveTimeCH3",
                        "enableActiveTimeCH4",
                        "enableActiveTimeCH5",
                        "enableActiveTimeCH6",
                        "enableActiveTimeCH7",
                        "enableActiveTimeCH8",
                      ].map((item, index) => (
                        <TableCell align="center" key={index}>
                          <Controller
                            name={item}
                            control={control}
                            defaultValue={false}
                            render={({ field }) => <Checkbox type="checkbox" size="medium" {...field} />}
                          />
                        </TableCell>
                      ))}
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">Relay Active High</TableCell>
                      {[
                        "relayActiveHighCH1",
                        "relayActiveHighCH2",
                        "relayActiveHighCH3",
                        "relayActiveHighCH4",
                        "relayActiveHighCH5",
                        "relayActiveHighCH6",
                        "relayActiveHighCH7",
                        "relayActiveHighCH8",
                      ].map((item) => (
                        <TableCell align="center" key={item}>
                          <Controller
                            name={item}
                            control={control}
                            defaultValue={false}
                            render={({ field }) => <Switch type="checkbox" size="medium" {...field} />}
                          />
                        </TableCell>
                      ))}
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">Delay Time (second)</TableCell>
                      {[
                        "triggerTimeCH1",
                        "triggerTimeCH2",
                        "triggerTimeCH3",
                        "triggerTimeCH4",
                        "triggerTimeCH5",
                        "triggerTimeCH6",
                        "triggerTimeCH7",
                        "triggerTimeCH8",
                      ].map((item) => (
                        <TableCell align="center" key={item}>
                          <Controller
                            name={item}
                            control={control}
                            rules={{ min: 1 }}
                            defaultValue={1}
                            render={({ field, fieldState }) => (
                              <FormControl size="small" fullWidth sx={{ marginTop: 1.5 }}>
                                <TextField
                                  type="number"
                                  variant="outlined"
                                  size="small"
                                  error={!!fieldState.error}
                                  helperText={fieldState.error && "Minimum value is 1"}
                                  step={1}
                                  {...field}
                                />
                              </FormControl>
                            )}
                          />
                        </TableCell>
                      ))}
                    </TableRow>

                    <TableRow>
                      <TableCell align="center">Level 3</TableCell>
                      {[
                        "level3CH1",
                        "level3CH2",
                        "level3CH3",
                        "level3CH4",
                        "level3CH5",
                        "level3CH6",
                        "level3CH7",
                        "level3CH8",
                      ].map((item) => (
                        <TableCell align="center" key={item}>
                          <Controller
                            name={item}
                            control={control}
                            defaultValue={false}
                            render={({ field }) => <Switch type="checkbox" size="medium" {...field} />}
                          />
                        </TableCell>
                      ))}
                    </TableRow>

                    <TableRow>
                      <TableCell align="center">Level 2</TableCell>
                      {[
                        "level2CH1",
                        "level2CH2",
                        "level2CH3",
                        "level2CH4",
                        "level2CH5",
                        "level2CH6",
                        "level2CH7",
                        "level2CH8",
                      ].map((item) => (
                        <TableCell align="center" key={item}>
                          <Controller
                            name={item}
                            control={control}
                            defaultValue={false}
                            render={({ field }) => <Switch type="checkbox" size="medium" {...field} />}
                          />
                        </TableCell>
                      ))}
                    </TableRow>

                    <TableRow>
                      <TableCell align="center">Level 1</TableCell>
                      {[
                        "level1CH1",
                        "level1CH2",
                        "level1CH3",
                        "level1CH4",
                        "level1CH5",
                        "level1CH6",
                        "level1CH7",
                        "level1CH8",
                      ].map((item) => (
                        <TableCell align="center" key={item}>
                          <Controller
                            name={item}
                            control={control}
                            defaultValue={false}
                            render={({ field }) => <Switch type="checkbox" size="medium" {...field} />}
                          />
                        </TableCell>
                      ))}
                    </TableRow>

                    <TableRow>
                      <TableCell align="center">Level 0</TableCell>
                      {[
                        "level0CH1",
                        "level0CH2",
                        "level0CH3",
                        "level0CH4",
                        "level0CH5",
                        "level0CH6",
                        "level0CH7",
                        "level0CH8",
                      ].map((item) => (
                        <TableCell align="center" key={item}>
                          <Controller
                            name={item}
                            control={control}
                            defaultValue={false}
                            render={({ field }) => <Switch type="checkbox" size="medium" {...field} />}
                          />
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableBody>
                </Table>
              </Grid>
            </Grid>

            <Grid container spacing={2} mt={1}>
              <Grid item xs={12}>
                <Collapse in={error.isError}>
                  <Alert
                    onClose={() =>
                      setError({
                        isError: false,
                        message: "",
                      })
                    }
                    severity="error"
                  >
                    {error.message}
                  </Alert>
                </Collapse>
              </Grid>
            </Grid>

            <Grid container display="flex" justifyContent="flex-end" mt={3}>
              <Button
                variant="text"
                disableRipple
                onClick={handleClose}
                color="error"
                size="small"
                sx={{ marginRight: 3 }}
              >
                Cancel
              </Button>
              <Button type="submit" variant="contained" size="small" color="success">
                Save
              </Button>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </>
  );
}

export default AddDevice;
