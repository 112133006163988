import React from "react";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";

const config = require("./../../../../configs/app");

const SortSettingLevel = ({ level, onCloseModal, fetchDataWarningConfig, organization }) => {
  const [data, setData] = React.useState({});
  const [sortData, setSortData] = React.useState({});

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setData({ ...data, [name]: value });
  };

  const fetchData = async () => {
    const response = await fetch(`${config.urlLightningConfig}/api/v1/warning-config/keyName/${organization.keyName}`, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((res) => res.message.filter((item) => item.levelWarning === level))
      .catch((error) => {
        console.error("เกิดข้อผิดพลาดในการดึงข้อมูล:", error);
      });
    if (response) {
      let sort = response.map((item) => {
        return {
          warningId: item.warningId,
          groupWarning: item.groupWarning,
        };
      });
      setSortData(sort);
      setData(response);
    }
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  const handleClose = () => {
    onCloseModal();
  };

  const updateOrder = async (item) => {
    const response = await fetch(`${config.urlLightningConfig}/api/v1/warning-config/${item.warningId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        groupWarning: item.groupWarning,
      }),
    })
      .then((res) => res.json())
      .catch((error) => {
        console.error("เกิดข้อผิดพลาดในการอัพเดตข้อมูล:", error);
      });

    // console.log(response);

    if (response.status === 200) {
      return true;
    } else {
      console.error("ไม่สามารถอัพเดตข้อมูลได้");
      return false;
    }
  };

  const handleSubmit = async () => {
    // console.log(sortData);

    // sortData don't repeat
    // if repeat => alert
    let checkRepeat = sortData.map((item) => item.groupWarning);
    let check = new Set(checkRepeat);
    if (checkRepeat.length !== check.size) {
      alert("The notification order is duplicated.");
      return;
    }

    // get max groupWarning in sortData

    let max = Math.max.apply(
      Math,
      sortData.map((item) => item.groupWarning)
    );

    for (let i = 0; i < sortData.length; i++) {
      await updateOrder({
        warningId: sortData[i].warningId,
        groupWarning: max + i + 1,
      });
    }

    // loop update sortData
    let checkUpdate = true;
    for (let i = 0; i < sortData.length; i++) {
      let update = await updateOrder(sortData[i]);
      if (!update) {
        checkUpdate = false;
        break;
      }
    }
    if (checkUpdate) {
      fetchDataWarningConfig();
      onCloseModal();
    }
  };

  return (
    <>
      {data.length > 0 ? (
        <>
          <Typography fontWeight="bold">Sort notification level {level}</Typography>
          <Grid item xs={6} display="flex" justifyContent="center" mb={2} mt={2}>
            <TableContainer component={Paper}>
              <Table>
                <TableBody>
                  {data.length > 0 &&
                    data.map((item, index) => (
                      <TableRow key={item.warningId}>
                        <TableCell>
                          <Typography>original order : {item.groupWarning} </Typography>
                        </TableCell>
                        <TableCell>
                          <FormControl fullWidth size="small">
                            <InputLabel>new order</InputLabel>
                            <Select
                              label="new order"
                              size="small"
                              value={sortData[index].groupWarning}
                              onChange={(e) => {
                                let newData = [...sortData];
                                newData[index].groupWarning = e.target.value;
                                setSortData(newData);
                              }}
                            >
                              {data.map((item, index) => {
                                if (item.groupWarning !== 1) {
                                  return Array.from({ length: item.groupWarning }, (_, i) => i + 1).map((item) => {
                                    return (
                                      <MenuItem key={item} value={item}>
                                        {item}
                                      </MenuItem>
                                    );
                                  });
                                } else {
                                  return (
                                    <MenuItem key={item.warningId} value={item.groupWarning}>
                                      {item.groupWarning}
                                    </MenuItem>
                                  );
                                }
                              })}
                            </Select>
                          </FormControl>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={6} display="flex" justifyContent="space-between" sx={{ gap: 2 }}>
            <Button variant="contained" color="inherit" onClick={handleClose} fullWidth>
              Cancel
            </Button>
            <Button variant="contained" color="success" fullWidth onClick={handleSubmit}>
              Save
            </Button>
          </Grid>
        </>
      ) : (
        <>
          {/* loading spiner */}
          <Stack
            sx={{
              MinHeight: "60vh",
              zIndex: 9999,
            }}
            justifyContent="center"
            alignItems="center"
          >
            <Box sx={{ display: "flex" }}>
              <CircularProgress color="inherit" />
            </Box>
          </Stack>
        </>
      )}
    </>
  );
};

export default SortSettingLevel;
