import "./navigation-css/Sidebar.css";
import React, { useContext } from "react";
import SidebarItem from "./navigation-js/SidebarItem";
import Cookies from "js-cookie";
import { showSidebar } from "../kumwell";
// import { BurgerContext } from "./navigation-js/BurgerMenu";
export default function Sidebar({ status }) {
  const token = Cookies.get("accessToken");
  const { auth } = useContext(showSidebar);

  if (token !== null && auth.Role) {
    const permission = auth.Role.Permissions;

    const hasPermission = (permissionName, permissionType) => {
      return permission.some((permission) => permission.name === permissionName && permission.type === permissionType);
    };

    const sidebarItems = [
      { title: "Home", route: "/HomePage", permission: { name: "Home", type: "read" } },
      { title: "Locations", route: "/organization", permission: { name: "Location", type: "read" } },
      {
        title: "Notification sounds",
        route: "/notification-sound",
        permission: { name: "Notifications", type: "read" },
      },
      { title: "E-Field sensors & M-Field", route: "/E-Field", permission: { name: "E-field sensor", type: "read" } },
      { title: "Users", route: "/user-information", permission: { name: "Users", type: "read" } },
      { title: "Device IOT", route: "/DeviceStatus", permission: { name: "Device IOT", type: "read" } },
      { title: "Report", route: "/report", permission: { name: "Report", type: "all" } },
      { title: "Testing send", route: "/test-send", permission: { name: "Test send", type: "all" } },
      { title: "Role and permission", route: "/permission", permission: { name: "Role and permission", type: "read" } },
    ];

    return (
      <div className={status}>
        <div className="sidebar">
          <div className="setTopSidebar" />
          <nav>
            {sidebarItems.map(
              (item) =>
                hasPermission(item.permission.name, item.permission.type) && (
                  <SidebarItem key={item.title} title={item.title} route={item.route} />
                )
            )}
          </nav>
        </div>
      </div>
    );
  }

  return null;
}
