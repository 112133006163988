import React from 'react';
class CurrentDate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentDate: new Date()
    };
  }
  render() {
    const { currentDate } = this.state;
    const day = currentDate.getDate();
    const month = currentDate.getMonth() + 1;
    const year = currentDate.getFullYear();
    return (
      <div>
        {day}/{month}/{year}
      </div>
    );
  }
}

export default CurrentDate;
