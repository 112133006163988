import React, { useEffect, useState } from "react";
import "../Routers.css";
import DataTable from "react-data-table-component";
import Cookies from "js-cookie";
import Container from "../Container";
import ConfirmDelete from "../ConfirmDelete";
import apiUrls from "../../ApiUrl";
import { customStyles } from "../DataTableStyle";
import { createContext } from "react";
import AddSound from "./AddSound";
import Swal from "sweetalert2";

const config = require("./../../configs/app");

const SoundMainContext = createContext();
export default function SoundPage({ create, update, remove }) {
  const [items, setItems] = useState([]);
  const [retryCount, setRetryCount] = useState(0);
  const [isLoaded, setIsLoaded] = useState(false);
  const [pending, setPending] = useState(true);
  const [showComponent, setShowComponent] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [key, setKey] = useState("");
  const [soundUrl, setSoundUrl] = useState("");
  const [onPlay, setOnplay] = useState("");
  const [errorFetch, setErrorFetch] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const token = Cookies.get("accessToken");

  const [formData, setFormData] = useState({
    sound_name: "",
    fileName: "",
  });

  const [formDataCheck, setFormDataCheck] = useState({
    sound_name: "",
    fileName: "",
  });

  const fetchData = () => {
    apiUrls.setSoundRequeset("");
    const url = apiUrls.Sound_Get;
    fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setIsLoaded(true);
        setItems(data);
      })
      .catch((error) => {
        setIsLoaded(false);
        setErrorFetch(error);
        const retryDelay = 3000;
        setTimeout(() => setRetryCount(retryCount + 1), retryDelay);
      });
  };

  async function handleDelete(request) {
    try {
      apiUrls.setSoundRequeset(request);
      setDeleteLoading(true);
      const response = await fetch(apiUrls.Sound, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.ok) {
        Swal.fire({
          icon: "success",
          title: "Delete Successful!",
          text: "Sound has been Deleted",
        });
        fetchData();
      } else {
        Swal.fire({
          icon: "error",
          title: "Delete Failed",
          text: response.message,
        });
      }
    } catch (error) {
      console.error("Failed to delete item:", error);
    } finally {
      setShowDelete(false);
      setDeleteLoading(false);
    }
  }

  const openDelete = (request) => {
    setKey(request);
    setShowDelete(true);
  };

  const closeModals = () => {
    setShowDelete(false);
  };

  const OpenModals = () => {
    apiUrls.setSoundRequeset("");
    setFormData({
      sound_name: "",
      fileName: "",
    });
    setShowComponent("create");
  };

  const fetchSound = async (name) => {
    setSoundUrl("");
    try {
      const response = await fetch(`${config.urlSound}/api/v1/alarm-sound/${name}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.blob();
      setSoundUrl(URL.createObjectURL(data));
      setOnplay(name);
    } catch (error) {
      console.error("Error fetching sound:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [retryCount]);

  const columns = [
    {
      name: '#',
      selector: row => row.number,
      sortable: true,
      width: '57px'

    },
    {
      name: 'Sound',
      selector: row => row.sound_name,
      sortable: true,
      width: '200px'
    },
    {
      name: 'File',
      selector: row => row.name,
      width: ' auto'
    },
    {
      cell: row => (
        <>
          {soundUrl && row.name === onPlay &&
            <audio controls autoPlay >
              <source src={soundUrl} type="audio/wav" />
            </audio>
          }
        </>
      )
      ,
      width: '220px'
    },
    {
      name: "",
      cell: (row) => (
        <button onClick={() => fetchSound(row.name)} className="play-btn">Play</button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      width: "90px",
    }
  ];

  if (update) {
    columns.push({
      name: "",
      cell: (row) => (
        <>
          <button
            className="edit-btn"
            onClick={() => {
              apiUrls.setSoundRequeset(row.id);
              setFormDataCheck(
                {
                  "sound_name": row.sound_name,
                  "fileName": row.name,
                }
              );
              setFormData(
                {
                  "sound_name": row.sound_name,
                  "fileName": row.name,
                }
              );
              setShowComponent("edit");
            }}
          >Edit</button>
        </>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      width: "90px",
    });
  }

  if (remove) {
    columns.push({
      name: "",
      cell: row => (
        <>
          <button className="delete-btn" onClick={() => openDelete(row.id)}>Delete</button>
        </>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      width: "90px",
    },);
  }

  const CustomLoader = () => (
    <>
      <div style={{ padding: "40px" }}>
        <span className="loader"></span>
        <p>{errorFetch.message}</p>
      </div>
    </>
  );

  if (token != null && isLoaded === true) {
    const data = items;
    const dataWithIds = data.map((item, index) => ({ number: index + 1, ...item }));
    const filteredData = dataWithIds.filter((item) => {
      const { name, sound_name } = item;
      const searchValue = searchQuery.toLowerCase();
      return name.toLowerCase().includes(searchValue) || sound_name.toLowerCase().includes(searchValue);
    });
    return (
      <SoundMainContext.Provider value={{ showComponent, setShowComponent, formData, setFormData, retryCount, setRetryCount, items, formDataCheck, setFormDataCheck }}>
        {showComponent === "create" && <AddSound edit={false} /> || showComponent === "edit" && <AddSound edit={true} />}
        <Container
          title={<>Notification sounds</>}
          btn={create ? <>Add Sound</> : null}
          action={OpenModals}
          components={
            <>

              {showDelete ? <ConfirmDelete topic="Sound" closeModals={closeModals} confirm={() => handleDelete(key)} loadding={deleteLoading} /> : <></>}
              <input className="Search" type="text" onChange={e => setSearchQuery(e.target.value)} placeholder="Search" />
              <DataTable
                columns={columns}
                data={filteredData}
                pagination
                fixedHeader
                customStyles={customStyles}
                highlightOnHover
                noHeader
                striped
              />
            </>
          }
        />
      </SoundMainContext.Provider>
    );
  }

  else {
    return (
      <Container
        title={<>Notification sounds</>}
        components={
          <DataTable
            progressPending={pending}
            progressComponent={<CustomLoader />}
          />
        }
      />
    )
  }
};
export {SoundMainContext}