import React from "react";
import { Button, Grid } from "@mui/material";

const config = require("./../../../../configs/app");

const Delete = ({ settingId, fetchUser, onCloseModal, user }) => {
  const handleDelete = () => {
    fetch(`${config.urlAuth}/api/v1/user/${user.keyName}/${user.userId}`, {
      method: "DELETE",
    })
      .then((response) => {
        if (response.ok) {
          // console.log("ลบข้อมูลสำเร็จ");
          fetchUser();
          onCloseModal(settingId);
        } else {
          console.error("ไม่สามารถลบข้อมูลได้");
        }
      })
      .catch((error) => {
        console.error("เกิดข้อผิดพลาดในการลบข้อมูล:", error);
      });
  };

  const handleClose = () => {
    onCloseModal();
  };

  return (
    <div>
      <h2>ยืนยันการลบข้อมูล</h2>
      <Grid item xs={12} display="flex" justifyContent="center" mb={2}>
        <h3>
          ท่านต้องการลบผู้ใช้ชื่อ {user.firstName} {user.lastName} ตำแหน่ง {user.Role.name && user.Role.name} หรือไม่?
        </h3>
      </Grid>
      <Grid item xs={12} display="flex" justifyContent="space-between" sx={{ gap: 2 }}>
        <Button variant="outlined" color="error" onClick={handleClose} fullWidth>
          ยกเลิก
        </Button>
        <Button variant="contained" color="error" onClick={handleDelete} fullWidth>
          ยืนยันการลบ
        </Button>
      </Grid>
    </div>
  );
};

export default Delete;
