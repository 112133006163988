import React, { useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import {
  TextField,
  Button,
  Switch,
  FormControlLabel,
  Grid,
  Select,
  MenuItem,
  Alert,
  Collapse,
  IconButton,
} from "@mui/material";
import Cookies from "js-cookie";

const config = require("./../../../../configs/app");

const textFiledStyle = {
  my: 1,
};

const inputStyle = {
  // Your custom styles for the input element go here
  height: "100%", // Set the desired input height here
  fontSize: "14px", // Set the desired font size for the input text here
  // Add more styles as needed
};

function Edit({ userId, onCloseModal, fetchUser }) {
  const [data, setData] = useState(null);
  const [roles, setRoles] = useState([]);
  const [error, setError] = useState(false);
  const key = Cookies.get("pathName");
  const { keyName } = useParams();

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setData({
      ...data,
      [name]: value,
    });
  };

  const handleSwitchChange = (event) => {
    const { name, checked } = event.target;
    setData({
      ...data,
      [name]: checked,
    });
  };

  //   fetch role
  useEffect(() => {
    fetch(`${config.urlAuth}/api/v1/role/`)
      .then((res) => res.json())
      .then((res) => setRoles(res))
      .catch((err) => console.error(`Error Fetch Role: ${err}`));
  }, []);

  //   fetch user
  useEffect(() => {
    fetch(`${config.urlAuth}/api/v1/user/${key}/${userId}`)
      .then((res) => res.json())
      .then((res) => res.message)
      .then((res) => {
        return {
          ...res,
          password: "",
          confirmPassword: "",
          RoleId: res.Role?.roleId,
        };
      })
      .then((res) => setData(res))
      .catch((err) => console.error(`Error Fetch Role: ${err}`));
  }, []);

  const addDataToSystem = (e) => {
    e.preventDefault();
    // console.log(data);

    // if (data.password !== data.confirmPassword) {
    //   setError(true);
    //   return;
    // }

    if (data.password === "") {
      delete data.password;
      delete data.confirmPassword;
    }

    fetch(`${config.urlAuth}/api/v1/user/${key}/${userId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 200) {
          // console.log("Data added successfully:", data);
          fetchUser();
          handleCloseModal();
        } else {
          console.error("Data added fail:", data);
        }
      })
      .catch((error) => {
        console.error("Error adding data:", error);
      });
  };

  const handleCloseModal = () => {
    onCloseModal();
  };

  return (
    <>
      {data ? (
        <form onSubmit={addDataToSystem}>
          <Grid container>
            <h2>Edit User : {data.keyName} </h2>

            <TextField
              label="Username"
              name="username"
              type="text"
              autoComplete="new-password"
              fullWidth
              sx={textFiledStyle}
              required
              value={data.username}
              onChange={handleInputChange}
              InputProps={{
                sx: inputStyle,
              }}
            />
            <TextField
              label="Email"
              name="email"
              type="email"
              autoComplete="new-password"
              fullWidth
              sx={textFiledStyle}
              required
              value={data.email}
              onChange={handleInputChange}
              InputProps={{
                sx: inputStyle,
              }}
            />

            <Grid container spacing={2} display="flex" justifyContent="space-between">
              <Grid item xs={6} display="flex" alignItems="center" justifyContent="center">
                <TextField
                  label="First Name"
                  name="firstName"
                  type="text"
                  autoComplete="new-password"
                  fullWidth
                  sx={textFiledStyle}
                  required
                  value={data.firstName}
                  onChange={handleInputChange}
                  InputProps={{
                    sx: inputStyle,
                  }}
                />
              </Grid>
              <Grid item xs={6} display="flex" alignItems="center" justifyContent="center">
                <TextField
                  label="Last Name"
                  name="lastName"
                  type="text"
                  autoComplete="new-password"
                  fullWidth
                  sx={textFiledStyle}
                  required
                  value={data.lastName}
                  onChange={handleInputChange}
                  InputProps={{
                    sx: inputStyle,
                  }}
                />
              </Grid>
            </Grid>
            <TextField
              label="Phone Number"
              name="phoneNumber"
              type="text"
              autoComplete="new-password"
              fullWidth
              sx={textFiledStyle}
              required
              value={data.phoneNumber}
              onChange={handleInputChange}
              InputProps={{
                sx: inputStyle,
              }}
            />

            <Select name="RoleId" fullWidth value={data.RoleId} required onChange={handleInputChange}>
              {roles.status === 200 &&
                roles.message.map((item, index) => (
                  <MenuItem key={index} value={item?.roleId}>
                    {item.name} {item.description}
                  </MenuItem>
                ))}
            </Select>

            <FormControlLabel
              control={<Switch name="viewEField" checked={data.viewEField} onChange={handleSwitchChange} />}
              label="View E-Field"
            />
            <FormControlLabel
              control={<Switch name="activeStatus" checked={data.activeStatus} onChange={handleSwitchChange} />}
              label="Active"
            />
          </Grid>
          {/* <Collapse in={error}>
            <Alert
              variant="outlined"
              severity="error"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setError(false);
                  }}
                >
                  X
                </IconButton>
              }
            >
              Password Not match
            </Alert>
          </Collapse> */}
          <Grid container spacing={2} display="flex" justifyContent="space-between" mt={1}>
            <Grid item xs={6} display="flex" alignItems="center" justifyContent="center">
              <Button variant="outlined" color="error" fullWidth onClick={handleCloseModal}>
                Close
              </Button>
            </Grid>
            <Grid item xs={6} display="flex" alignItems="center" justifyContent="center">
              <Button type="submit" variant="contained" fullWidth color="success">
                Save
              </Button>
            </Grid>
          </Grid>
        </form>
      ) : (
        <h5>Loading.....</h5>
      )}
    </>
  );
}

export default Edit;
