import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { TextField, Button, Switch, FormControlLabel, Grid } from "@mui/material";
import Swal from "sweetalert2";
import Typography from '@mui/material/Typography';
const config = require("./../../../../configs/app");

const textFiledStyle = {
  my: 1,
};

const AddSetting = ({ onCloseModal, keyName, onDataAdded }) => {
  const [inputValue, setInputValue] = useState('');
  const { control, handleSubmit } = useForm({
    defaultValues: {
      lineTextZoneR: "",
      lineTextZoneA: "",
      lineTextZoneB: "",
      emailTextZoneR: "",
      emailTextZoneR: "",
      emailTextZoneR: "",
      emailNotification: false,
      lineNotification: false,
      iotNotification: false,
      keyName: keyName,
    },
  });

  const handleChange = (event) => {
    const newValue = event.target.value;
    console.log(newValue)
    if (newValue.length <= 1000) {
      setInputValue(newValue);
    }
  };


  const onSubmit = (formData) => {
    addDataToSystem(formData);
  };

  const addDataToSystem = (data) => {
    fetch(`${config.urlSettingNotification}/api/v1/setting-notifications/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (!response.ok) {
          //   throw new Error('Network response was not ok');
          Swal.fire({
            icon: "error",
            title: "Create Failed!",
            text: response.status,
          });
        }
        return response.json();
      })
      .then((data) => {
        onDataAdded(data);
        handleCloseModal();
        Swal.fire({
          icon: "success",
          title: "Create Successful!",
          text: "data has been created",
        });
      })
      .catch((error) => {
        console.error("Error adding data:", error);
      });
  };

  const characterCountStyle = {
    position: 'absolute',
    top: 8,
    right: 5,
    color: 'gray',
    fontSize: '14px',
  };

  const handleCloseModal = () => {
    onCloseModal();
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container>
          <h2>Add Notifications</h2>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <h4>Line Message</h4>
              <Controller
                name="lineTextZoneR"
                control={control}
                rules={{ required: true }}
                render={({ field, fieldState }) => (
                  <>
                    <div style={{ position: 'relative' }}>
                      <TextField
                        label="Message Level 3"
                        fullWidth
                        sx={textFiledStyle}
                        error={fieldState.invalid}
                        helperText={fieldState.invalid ? "Please fill in message Level 3" : ""}
                        multiline
                        minRows={1}
                        {...field}
                        onChange={(e) => {
                          if (e.target.value.length <= 1000) {
                            field.onChange(e);
                          }
                        }}
                      />
                      <div style={characterCountStyle}>
                        {field.value && <>{field.value.length}/1000</>}
                      </div>
                    </div>
                  </>
                )}
              />
              <Controller
                name="lineTextZoneA"
                control={control}
                rules={{ required: true }}
                render={({ field, fieldState }) => (
                  <div style={{ position: 'relative' }}>
                    <TextField
                      label="Message Level 2"
                      fullWidth
                      sx={textFiledStyle}
                      error={fieldState.invalid}
                      helperText={fieldState.invalid ? "Please fill in message Level 2" : ""}
                      multiline
                      minRows={1}
                      {...field}
                      onChange={(e) => {
                        if (e.target.value.length <= 1000) {
                          field.onChange(e);
                        }
                      }}
                    />
                    <div style={characterCountStyle}>
                      {field.value && <>{field.value.length}/1000</>}
                    </div>

                  </div>
                )}
              />
              <Controller
                name="lineTextZoneB"
                control={control}
                rules={{ required: true }}
                render={({ field, fieldState }) => (
                  <div style={{ position: 'relative' }}>
                    <TextField
                      label="Message Level 1"
                      fullWidth
                      sx={textFiledStyle}
                      error={fieldState.invalid}
                      helperText={fieldState.invalid ? "Please fill in message Level 1" : ""}
                      multiline
                      minRows={1}
                      {...field}
                      onChange={(e) => {
                        if (e.target.value.length <= 1000) {
                          field.onChange(e);
                        }
                      }}
                    />
                    <div style={characterCountStyle}>
                      {field.value && <>{field.value.length}/1000</>}
                    </div>

                  </div>
                )}
              />
              <Controller
                name="lineTextZoneC"
                control={control}
                rules={{ required: true }}
                render={({ field, fieldState }) => (
                  <div style={{ position: 'relative' }}>
                    <TextField
                      label="Message Level 0"
                      fullWidth
                      sx={textFiledStyle}
                      error={fieldState.invalid}
                      helperText={fieldState.invalid ? "Please fill in message Level 0" : ""}
                      multiline
                      minRows={1}
                      {...field}
                      onChange={(e) => {
                        if (e.target.value.length <= 1000) {
                          field.onChange(e);
                        }
                      }}
                    />
                    <div style={characterCountStyle}>
                      {field.value && <>{field.value.length}/1000</>}
                    </div>


                  </div>
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <h4>Email Message</h4>
              <Controller
                name="emailTextZoneR"
                control={control}
                rules={{ required: true }}
                render={({ field, fieldState }) => (
                  <div style={{ position: 'relative' }}>
                    <TextField
                      label="Message Level 3"
                      fullWidth
                      sx={textFiledStyle}
                      error={fieldState.invalid}
                      helperText={fieldState.invalid ? "Please fill in message Level 3" : ""}
                      multiline
                      minRows={1}
                      {...field}
                      onChange={(e) => {
                        if (e.target.value.length <= 1000) {
                          field.onChange(e);
                        }
                      }}
                    />
                    <div style={characterCountStyle}>
                      {field.value && <>{field.value.length}/1000</>}
                    </div>
                  </div>
                )}
              />
              <Controller
                name="emailTextZoneA"
                control={control}
                rules={{ required: true }}
                render={({ field, fieldState }) => (
                  <div style={{ position: 'relative' }}>
                    <TextField
                      label="Message Level 2"
                      fullWidth
                      sx={textFiledStyle}
                      error={fieldState.invalid}
                      helperText={fieldState.invalid ? "Please fill in message Level 2" : ""}
                      multiline
                      minRows={1}
                      {...field}
                      onChange={(e) => {
                        if (e.target.value.length <= 1000) {
                          field.onChange(e);
                        }
                      }}
                    />
                    <div style={characterCountStyle}>
                      {field.value && <>{field.value.length}/1000</>}
                    </div>
                  </div>
                )}
              />
              <Controller
                name="emailTextZoneB"
                control={control}
                rules={{ required: true }}
                render={({ field, fieldState }) => (
                  <div style={{ position: 'relative' }}>
                    <TextField
                      label="Message Level 1"
                      fullWidth
                      sx={textFiledStyle}
                      error={fieldState.invalid}
                      helperText={fieldState.invalid ? "Please fill in message Level 1" : ""}
                      multiline
                      minRows={1}
                      {...field}
                      onChange={(e) => {
                        if (e.target.value.length <= 1000) {
                          field.onChange(e);
                        }
                      }}
                    />
                    <div style={characterCountStyle}>
                      {field.value && <>{field.value.length}/1000</>}
                    </div>
                  </div>
                )}
              />
              <Controller
                name="emailTextZoneC"
                control={control}
                rules={{ required: true }}
                render={({ field, fieldState }) => (
                  <div style={{ position: 'relative' }}>
                    <TextField
                      label="Message Level 0"
                      fullWidth
                      sx={textFiledStyle}
                      error={fieldState.invalid}
                      helperText={fieldState.invalid ? "Please fill in message Level 0" : ""}
                      multiline
                      minRows={1}
                      {...field}
                      onChange={(e) => {
                        if (e.target.value.length <= 1000) {
                          field.onChange(e);
                        }
                      }}
                    />
                    <div style={characterCountStyle}>
                      {field.value && <>{field.value.length}/1000</>}
                    </div>
                  </div>
                )}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <h4>Setting Notification</h4>
              <Controller
                name="emailNotification"
                control={control}
                render={({ field }) => <FormControlLabel control={<Switch {...field} />} label="Email" />}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="lineNotification"
                control={control}
                render={({ field }) => <FormControlLabel control={<Switch {...field} />} label="Line" />}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="iotNotification"
                control={control}
                render={({ field }) => <FormControlLabel control={<Switch {...field} />} label="IOT" />}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={2} display="flex" justifyContent="space-between" mt={1}>
          <Grid item xs={6} display="flex" alignItems="center" justifyContent="center">
            <Button type="submit" variant="contained" sx={{ bgcolor: "#2E86C1" }} fullWidth>
              SUBMIT
            </Button>
          </Grid>
          <Grid item xs={6} display="flex" alignItems="center" justifyContent="center">
            <Button variant="contained" sx={{ bgcolor: "#F37026" }} fullWidth onClick={handleCloseModal}>
              CLOSE
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default AddSetting;
