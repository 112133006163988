import { useContext, useState } from "react";
import { userContext } from "./UserSetting";
import User from "../organization/add-organization/user";
import Swal from "sweetalert2";
import apiUrls from "../../ApiUrl";
import Cookies from "js-cookie";

export default function AddUser({ superAdmin }) {
    const {
        showComponent,
        setShowComponent,
        onFetch,
        setOnFetch,
        confirmpassword,
        userData,
        setError,
        error,
        setRetryCount,
        retryCount, errorEmail, errorUsername,
    } = useContext(userContext);
    const [onHole, setOnHole] = useState(true);
    const token = Cookies.get("accessToken");

    // * Handle Function Start
    const handleModalClick = (e) => {
        e.stopPropagation();
        setOnHole(true);
    };

    const closeModal = () => {
        if (onHole !== false) {
            setShowComponent(false);
        }
        setOnHole(true);
    };

    const closeModals = () => {
        setShowComponent(false);
    };

    const handleSubmit = () => {
        const areAllInputsFilled = Object.values(userData).every(
            (input) => input !== ""
        ); //* จำเป็นต้องกรอกข้อมูลทั้งหมด
        if (areAllInputsFilled && confirmpassword === true && !error && errorEmail === false && errorUsername === false) {
            apiUrls.setUserRequest3(userData.keyName);
            apiUrls.setUserRequest4("");
            SubmitData();
        } else {
            setError(true);
        }
    };
    // * Handle Function End

    function SubmitData() {
        const options = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        };
        const bodyUser = JSON.stringify(userData);
        setOnFetch(true);
        fetch(apiUrls.user_Request, { ...options, body: bodyUser })
            .then((response) => response.json())
            .then((result) => {
                if (result.status === 201 || result.status === 200) {
                    console.log("organization error submit:", result);
                    Swal.fire({
                        icon: "success",
                        title: "Submission Successful!",
                        // text: "user has been created",
                    });
                    setOnFetch(false);
                    setRetryCount(retryCount + 1);
                    apiUrls.setUserRequest3("");
                    closeModals();
                } else {
                    console.log("organization error submit:", result);
                    Swal.fire({
                        icon: "error",
                        title: "Submission Failed",
                        // text: result.message,
                    });
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            })
            .finally(() => {
                setOnFetch(false);
                apiUrls.setUserRequest3("");
            });
    }

    return (
        <>
            {showComponent && (
                <div className="overlay">
                    <div className="bodyModal" onClick={closeModal}>
                        <div
                            className="modals-organization"
                            onClick={handleModalClick}
                            onMouseDown={() => setOnHole(false)}
                            onMouseUp={() => setOnHole(false)}
                        >
                            <div className="menu-bar-addOrganization">Add New User</div>
                            <nav className="status-sidebar-addOrganization" align="center">
                                <div className="active">User Information</div>
                            </nav>

                            <div className="Router-addOrganization">
                                <User context={userContext} admin={true} efield={true} superAdmin={superAdmin} />
                            </div>

                            <div className="menu-bar-addOrganization">
                                <button className="btn-addOrg close" onClick={closeModals}>
                                    Close
                                </button>
                                <button
                                    className="btn-addOrg next"
                                    onClick={handleSubmit}
                                    disabled={onFetch}
                                    align="center"
                                >
                                    {onFetch ? <span className="loader2"></span> : "SUBMIT"}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}
