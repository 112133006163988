
import { useContext } from 'react';
import './Routers.css';
import { showSidebar } from '../kumwell';
import { useNavigate } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material';
import { Button } from "@mui/material";
export default function Container({ title, components, btn, action, pathBefore, }) {
    const navigate = useNavigate();
    const { showSidebars } = useContext(showSidebar);
    const customTheme = createTheme({
        typography: {
            fontFamily: 'Kanit, Prompt',
        },
    });
    return (
        <>
            <div className={`route-container ${showSidebars}`}>
                <div className="route-backgorund">
                    <div className="topic-title"><span onClick={pathBefore ? () => navigate(-1) : null} style={{ cursor: "pointer", color: " #1d4fd7" }}>{pathBefore}</span> {pathBefore && "/"} {title}</div>
                    {btn ? <button className="btn-add" onClick={action}>{btn}</button> : <></>}
                    <div className="route-item ">
                        <ThemeProvider theme={customTheme}>
                            {components}
                        </ThemeProvider>
                    </div>
                </div>
            </div>
        </>
    );
};