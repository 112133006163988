import React, { useContext, useState } from "react";
import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";
import { showSidebar } from "../../kumwell";


const styles = StyleSheet.create({
    page: {
        padding: 20,
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
    },
    table: {
        fontSize: 8,
        display: 'table',
        width: '70%',
        marginBottom: 10,
        borderStyle: 'solid',
        borderWidth: 0.5,
        borderColor: '#000',
        borderTop: '#000',

        borderCollapse: 'collapse',

    },
    tableRow: {
        flexDirection: 'row',
    },
    tableHeaderNo: {
        borderCollapse: 'collapse',
        width: '25%',
        borderStyle: 'solid',
        borderWidth: 0.5,
        borderRightColor: '#000',
        borderBottom: '#000',
        padding: 5,
        textAlign: 'center',
    },
    tableHeaderDate: {
        width: '50%',
        borderStyle: 'solid',
        borderWidth: 0.5,
        borderRightColor: '#000',
        borderBottom: '#000',
        padding: 5,
        textAlign: 'center',
    },
    tableHeaderLevel: {
        width: '25%',
        borderStyle: 'solid',
        borderWidth: 0.5,
        borderRightColor: '#000',
        borderBottom: '#000',
        padding: 5,
        textAlign: 'center',
    },
});

const ITEMS_PER_PAGE = 38;
export default function PDF({ data }) {
    const [currentPage, setCurrentPage] = useState(1);
    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;
    const displayedData = data.slice(startIndex, endIndex);
    const totalPages = Math.ceil(data.length / ITEMS_PER_PAGE);
    return (
        // <Document></Document>
        //     <Page size="A4" style={styles.page} wrap>
        //         <View style={styles.table}>

        //             <View style={styles.tableRow}>
        //                 <View style={styles.tableHeaderNo}>
        //                     <Text>No.</Text>
        //                 </View>
        //                 <View style={styles.tableHeaderDate}>
        //                     <Text>Date</Text>
        //                 </View>
        //                 <View style={styles.tableHeaderLevel}>
        //                     <Text>Level</Text>
        //                 </View>
        //             </View>

        //             {data.message.map((item) => (
        //                 <View style={styles.tableRow} key={item.id}>
        //                     <View style={styles.tableHeaderNo}>
        //                         <Text>{item.id}</Text>
        //                     </View>
        //                     <View style={styles.tableHeaderDate}>
        //                         <Text>{item.keyName}</Text>
        //                     </View>
        //                     <View style={styles.tableHeaderLevel}>
        //                         <Text>{item.levelWarning}</Text>
        //                     </View>
        //                 </View>
        //             ))}

        //         </View>
        //     </Page>
        // </Document>

        <Document>
            {Array.from({ length: totalPages }, (_, index) => (
                <Page key={index + 1} size="A4" style={styles.page}>
                    <View style={styles.table}>
                        <View style={styles.tableRow}>
                            <View style={styles.tableHeaderNo}>
                                <Text>No.</Text>
                            </View>
                            <View style={styles.tableHeaderDate}>
                                <Text>Date</Text>
                            </View>
                            <View style={styles.tableHeaderLevel}>
                                <Text>Level</Text>
                            </View>
                        </View>
                        {displayedData.map((item) => (
                            <View style={styles.tableRow} key={item.id}>
                                <View style={styles.tableHeaderNo}>
                                    <Text>{item.id}</Text>
                                </View>
                                <View style={styles.tableHeaderDate}>
                                    <Text>{item.keyName}</Text>
                                </View>
                                <View style={styles.tableHeaderLevel}>
                                    <Text>{item.levelWarning}</Text>
                                </View>
                            </View>
                        ))}
                    </View>
                </Page>
            ))}
        </Document>
    );
}