const config = require("./configs/app");
const address = config.urlAuth;//urlAuth
const address2 = config.urlLightningConfig;//urlLightningConfig
const address3 = config.urlSound;//urlSound
const address4 = config.urlDevice;//urlDevice
const httpType = "";
let request = "";
let request2 = "";
let organization_keyName = "";
let organization_keyName_GET = "";
let organization_keyName_Report = "";
let user_requeset1 = "";
let user_requeset2 = "";
let user_requeset3 = "";
let user_requeset4 = "";
let weather_lat = "";
let weather_lon = "";
let keyName_lightning = "";
let Efield_request1 = "";
let sound_request = "";
const weater_id = "43df35651f83242ac275ea501eb96ca9";
const apiUrls = {
    OrganizationUrl_GET: `${httpType}${address}/api/v1/organization/?user=true&log=false`,
    OrganizationUrl_POST: `${httpType}${address}/api/v1/organization/`,
    myAccount: `${httpType}${address}/api/v1/auth/my-account`,
    loginUrl: `${httpType}${address}/api/v1/auth/login`,

    get lightning() {
        return `${httpType}${address2}/api/v1/lightningWarning/${keyName_lightning}`;
    },

    get weather() {
        return `${httpType}${address2}/api/v1/weather?type=weather&lat=${weather_lat}&lon=${weather_lon}&appid=${weater_id}`;
    },

    get pm25() {
        return `${httpType}${address2}/api/v1/weather?type=air_pollution&lat=${weather_lat}&lon=${weather_lon}&appid=43df35651f83242ac275ea501eb96ca9`;
    },

    get OrganizationUrl_getLocation() {
        return `${httpType}${address}/api/v1/organization/${organization_keyName_GET}?log=false`;
    },

    get OrganizationUrl_byKeyName() {
        return `${httpType}${address}/api/v1/organization/${organization_keyName_Report}?log=false`;
    },

    get OrganizationUrl_UPDATE() {
        return `${httpType}${address}/api/v1/organization/${organization_keyName}`;
    },

    get OrganizationUrl_DELETE() {
        return `${httpType}${address}/api/v1/organization/${organization_keyName}`;
    },

    get user_GET() {
        return `${httpType}${address}/api/v1/user/${user_requeset1}${user_requeset1 && user_requeset2 !== " " ? `/${user_requeset2}` : " "}`;
    },

    get user_Request() {
        return `${httpType}${address}/api/v1/user/${user_requeset3}${user_requeset3 && user_requeset3 !== " " ? `/${user_requeset4}` : " "
            }`;
    },

    get Efield() {
        return `${httpType}${address2}/api/v1/e-field-sensor/${Efield_request1}`;
    },
    get Efield_Get() {
        return `${httpType}${address2}/api/v1/e-field-sensor/`;
    },

    get Sound() {
        return `${httpType}${address3}/api/v1/alarm-sound/${sound_request}`;
    },

    get Sound_Get() {
        return `${httpType}${address3}/api/v1/alarm-sound`;
    },

    get Device() {
        return `${httpType}${address4}/api/v1/device/${request}${request && request2 !== " " ? `/${request2}` : " "
            }`;
    },

    setRequest(newRequest) {
        request = newRequest;
    },

    setKeyNameReport(newRequest) {
        organization_keyName_Report = newRequest;
    },
    setRequest2(newRequest) {
        request2 = newRequest;
    },
    setWeatherLat(newRequest) {
        weather_lat = newRequest;
    },
    setWeatherLon(newRequest) {
        weather_lon = newRequest;
    },
    setRequestOrganization(newRequest) {
        organization_keyName = newRequest;
    },
    setRequestOrganization_GET(newRequest) {
        organization_keyName_GET = newRequest;
    },
    setUserRequest1(newRequest) {
        user_requeset1 = newRequest;
    },
    setUserRequest2(newRequest) {
        user_requeset2 = newRequest;
    },

    setUserRequest3(newRequest) {
        user_requeset3 = newRequest;
    },
    setUserRequest4(newRequest) {
        user_requeset4 = newRequest;
    },
    setLightningRequest(newRequest) {
        keyName_lightning = newRequest;
    },
    setEfieldRequest(newRequest) {
        Efield_request1 = newRequest;
    },
    setSoundRequeset(newRequest) {
        sound_request = newRequest;
    },

};

export default apiUrls;
