import {
  Alert,
  Button,
  Checkbox,
  Collapse,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import Cookies from "js-cookie";
import { useState } from "react";
import { useEffect } from "react";

const config = require("./../../../../configs/app");

function EditDevice({ id, handleClose, fetchDevice, setAlert }) {
  const [data, setData] = useState({});
  const [error, setError] = useState({
    isError: false,
    message: "",
  });

  const key = Cookies.get("pathName");

  const { control, handleSubmit, getValues } = useForm();

  const fetchDeviceById = async () => {
    const res = await fetch(`${config.urlDevice}/api/v1/device/id/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (res.ok) {
      const resJson = await res.json();
      const obj = resJson.message;

      obj.activeDay = obj.activeDay.split("").map((item) => (item === "1" ? true : false));

      for (let i = 0; i < obj.Relay.length; i++) {
        const relay = obj.Relay[i];

        obj[`relayActiveHighCH${relay.channel}`] = relay.normalOn === 1 ? true : false;
        obj[`enableActiveTimeCH${relay.channel}`] = relay.enableActiveTime;
        obj[`triggerTimeCH${relay.channel}`] = relay.triggerTime;
        obj[`level3CH${relay.channel}`] = relay.level3;
        obj[`level2CH${relay.channel}`] = relay.level2;
        obj[`level1CH${relay.channel}`] = relay.level1;
        obj[`level0CH${relay.channel}`] = relay.level0;
      }
      setData(obj);
      // console.log(obj);
    } else {
      const resJson = await res.json();
      handleClose();
      setAlert({
        open: true,
        message: resJson.message,
        severity: "error",
      });
    }
  };

  useEffect(() => {
    fetchDeviceById();
  }, []);

  const onSubmit = (data) => {
    // console.log(data);
    saveDevice(data);
  };

  const saveDevice = async (data) => {
    // analyze data

    const obj = {
      ...data,
      keyName: key,
      activeDay: [data.Monday, data.Tuesday, data.Wednesday, data.Thursday, data.Friday, data.Saturday, data.Sunday]
        .map((item) => (item ? 1 : 0))
        .join(""),
      relay: Array(8)
        .fill(0)
        .map((item, index) => ({
          channel: index + 1,
          normalOn: data[`relayActiveHighCH${index + 1}`] ? 1 : 0,
          normalOff: data[`relayActiveHighCH${index + 1}`] ? 0 : 1,
          enableActiveTime: data[`enableActiveTimeCH${index + 1}`],
          triggerTime: data[`triggerTimeCH${index + 1}`],
          level3: data[`level3CH${index + 1}`],
          level2: data[`level2CH${index + 1}`],
          level1: data[`level1CH${index + 1}`],
          level0: data[`level0CH${index + 1}`],
        })),
    };

    // console.log(obj);

    const res = await fetch(`${config.urlDevice}/api/v1/device/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(obj),
    });

    if (res.ok) {
      // console.log("Update Device Success", await res.json());
      fetchDevice();
      handleClose();
      setAlert({
        open: true,
        message: `Update ${data.nameDevice} Success`,
        severity: "success",
      });
    } else {
      const resJson = await res.json();
      setError({
        isError: true,
        message: resJson.message,
      });
    }
  };

  return (
    <>
      <Grid container spacing={1}>
        {data.nameDevice ? (
          <Grid item xs={12}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Controller
                name="uid"
                control={control}
                rules={{ required: true }}
                defaultValue={data.uid || ""}
                render={({ field, fieldState }) => (
                  <FormControl fullWidth size="small">
                    <TextField
                      type="text"
                      label="UID"
                      required
                      error={fieldState.invalid}
                      helperText={fieldState.invalid ? "UID is required" : ""}
                      variant="outlined"
                      size="small"
                      // defaultValue={data.nameDevice || ""}
                      {...field}
                    />
                  </FormControl>
                )}
              />

              <Controller
                name="nameDevice"
                control={control}
                rules={{ required: true }}
                defaultValue={data.nameDevice || ""}
                render={({ field, fieldState }) => (
                  <FormControl fullWidth size="small" sx={{ marginTop: 1.5 }}>
                    <TextField
                      type="text"
                      label="Name Device"
                      required
                      error={fieldState.invalid}
                      helperText={fieldState.invalid ? "Name Device is required" : ""}
                      variant="outlined"
                      size="small"
                      // defaultValue={data.nameDevice || ""}
                      {...field}
                    />
                  </FormControl>
                )}
              />

              <Grid container display="flex" spacing={1} justifyContent="center">
                <Grid item xs={6}>
                  <Controller
                    name="lat"
                    control={control}
                    defaultValue={data.lat || ""}
                    render={({ field, fieldState }) => (
                      <FormControl size="small" fullWidth sx={{ marginTop: 1.5 }}>
                        <TextField
                          type="number"
                          step="0.1"
                          label="Latitude"
                          variant="outlined"
                          size="small"
                          error={fieldState.invalid}
                          helperText={fieldState.invalid ? "latitude is required" : ""}
                          {...field}
                        />
                      </FormControl>
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    name="lon"
                    control={control}
                    defaultValue={data.lon || ""}
                    render={({ field, fieldState }) => (
                      <FormControl size="small" fullWidth sx={{ marginTop: 1.5 }}>
                        <TextField
                          type="number"
                          step="0.1"
                          label="Longitude"
                          variant="outlined"
                          size="small"
                          error={fieldState.invalid}
                          helperText={fieldState.invalid ? "longitude is required" : ""}
                          {...field}
                        />
                      </FormControl>
                    )}
                  />
                </Grid>
              </Grid>

              <Controller
                name="description"
                control={control}
                defaultValue={data.description || ""}
                render={({ field }) => (
                  <FormControl fullWidth size="small" sx={{ marginTop: 1.5 }}>
                    <TextField type="text" label="Description" variant="outlined" size="small" {...field} />
                  </FormControl>
                )}
              />

              <Divider
                textAlign="center"
                sx={{
                  marginTop: 2,
                }}
              >
                <Typography>Active Date Time</Typography>
              </Divider>

              <Grid container display="flex" spacing={1} justifyContent="center">
                <Grid item xs={12} display="flex" alignItems="center" justifyContent="space-between">
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell align="center"></TableCell>
                        <TableCell align="center">
                          <Typography>Monday</Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography>Tuesday</Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography>Wednesday</Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography>Thursday</Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography>Friday</Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography>Saturday</Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography>Sunday</Typography>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell align="center">Enable Active Day</TableCell>
                        {["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"].map(
                          (item, index) => (
                            <TableCell align="center" key={index}>
                              <Controller
                                name={item}
                                control={control}
                                size="small"
                                defaultValue={data.activeDay[index] || false}
                                render={({ field }) => (
                                  <Checkbox type="checkbox" size="medium" {...field} checked={field.value} />
                                )}
                              />
                            </TableCell>
                          )
                        )}
                      </TableRow>
                    </TableBody>
                  </Table>
                </Grid>
              </Grid>

              <Grid container display="flex" spacing={1} justifyContent="center">
                <Grid item xs={6}>
                  <Controller
                    name="activeTimeStart"
                    control={control}
                    // rules={{ required: true }}
                    defaultValue={data.activeTimeStart || "00:00"}
                    render={({ field }) => (
                      <FormControl size="small" fullWidth sx={{ marginTop: 1.5 }}>
                        <TextField type="time" label="Time Start" variant="outlined" size="small" {...field} />
                      </FormControl>
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    name="activeTimeEnd"
                    control={control}
                    // rules={{ required: true }}
                    defaultValue={data.activeTimeEnd || "23:59"}
                    render={({ field }) => (
                      <FormControl size="small" fullWidth sx={{ marginTop: 1.5 }}>
                        <TextField type="time" label="Time End" variant="outlined" size="small" {...field} />
                      </FormControl>
                    )}
                  />
                </Grid>
              </Grid>

              <Divider
                textAlign="center"
                sx={{
                  marginTop: 2,
                }}
              >
                <Typography>notification information (In the event that the device stops working)</Typography>
              </Divider>

              <Grid container alignItems="center" mt={2}>
                <Grid item xs={4}>
                  <Typography>Can't contact for more than (minutes)</Typography>
                </Grid>
                <Grid item xs={8}>
                  <Controller
                    name="timeOutError"
                    control={control}
                    defaultValue={data.timeOutError || 0}
                    rules={{ required: true }}
                    render={({ field, fieldState }) => (
                      <FormControl size="small" fullWidth sx={{ marginTop: 1.5 }}>
                        <TextField
                          type="number" // Changed to "number" type for numeric input
                          step="1"
                          label="Can't contact for more than (minutes)"
                          variant="outlined"
                          required
                          size="small"
                          {...field}
                          error={!!fieldState.error} // Show error state based on validation
                          helperText={fieldState.invalid ? "Timeout Error is required" : ""}
                        />
                      </FormControl>
                    )}
                  />
                </Grid>
              </Grid>

              <Grid container mt={2}>
                <Grid item xs={4}>
                  <Controller
                    name="lineSent"
                    control={control}
                    defaultValue={data.lineSent !== undefined ? data.lineSent : false}
                    render={({ field }) => (
                      <FormControlLabel
                        control={<Checkbox type="checkbox" size="small" {...field} checked={field.value} />}
                        label="Sent Line Notify"
                        size="small"
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={8}>
                  <Controller
                    name="lineToken"
                    control={control}
                    defaultValue={data.lineToken || ""}
                    rules={{
                      validate: {
                        requiredIfLineSent: (value) => {
                          const isLineSent = getValues("lineSent");
                          if (isLineSent && !value) {
                            return "required";
                          }
                          return true;
                        },
                      },
                    }}
                    render={({ field, fieldState }) => (
                      <FormControl size="small" fullWidth sx={{ marginTop: 1.5 }}>
                        <TextField
                          type="text"
                          label="Line Token"
                          variant="outlined"
                          size="small"
                          error={!!fieldState.error} // Show error state based on validation
                          helperText={fieldState.error && fieldState.error.message}
                          {...field}
                        />
                      </FormControl>
                    )}
                  />
                  <Controller
                    name="lineText"
                    control={control}
                    defaultValue={data.lineText || ""}
                    rules={{
                      validate: {
                        requiredIfLineSent: (value) => {
                          const isLineSent = getValues("lineSent");
                          if (isLineSent && !value) {
                            return "required";
                          }
                          return true;
                        },
                      },
                    }}
                    render={({ field, fieldState }) => (
                      <FormControl size="small" fullWidth sx={{ marginTop: 1.5 }}>
                        <TextField
                          type="text"
                          label="Message"
                          variant="outlined"
                          size="small"
                          error={!!fieldState.error} // Show error state based on validation
                          helperText={fieldState.error && fieldState.error.message}
                          {...field}
                        />
                      </FormControl>
                    )}
                  />
                </Grid>
              </Grid>

              <Grid container mt={2}>
                <Grid item xs={12}>
                  <Controller
                    name="enable"
                    control={control}
                    defaultValue={data.enable || false}
                    render={({ field }) => (
                      <FormControlLabel
                        control={<Checkbox type="checkbox" size="small" {...field} checked={field.value} />}
                        label="Enable Device"
                        size="small"
                      />
                    )}
                  />
                </Grid>
              </Grid>

              <Divider
                textAlign="center"
                sx={{
                  marginTop: 2,
                }}
              >
                <Typography>Setting Channel Relay</Typography>
              </Divider>

              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell align="center"></TableCell>
                        <TableCell align="center">CH1</TableCell>
                        <TableCell align="center">CH2</TableCell>
                        <TableCell align="center">CH3</TableCell>
                        <TableCell align="center">CH4</TableCell>
                        <TableCell align="center">CH5</TableCell>
                        <TableCell align="center">CH6</TableCell>
                        <TableCell align="center">CH7</TableCell>
                        <TableCell align="center">CH8</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell align="center">Enable Active Time</TableCell>
                        {[
                          "enableActiveTimeCH1",
                          "enableActiveTimeCH2",
                          "enableActiveTimeCH3",
                          "enableActiveTimeCH4",
                          "enableActiveTimeCH5",
                          "enableActiveTimeCH6",
                          "enableActiveTimeCH7",
                          "enableActiveTimeCH8",
                        ].map((item, index) => (
                          <TableCell align="center" key={index}>
                            <Controller
                              name={item}
                              control={control}
                              defaultValue={data[item] || false}
                              render={({ field }) => (
                                <Checkbox type="checkbox" size="medium" {...field} checked={field.value} />
                              )}
                            />
                          </TableCell>
                        ))}
                      </TableRow>
                      <TableRow>
                        <TableCell align="center">Relay Active High</TableCell>
                        {[
                          "relayActiveHighCH1",
                          "relayActiveHighCH2",
                          "relayActiveHighCH3",
                          "relayActiveHighCH4",
                          "relayActiveHighCH5",
                          "relayActiveHighCH6",
                          "relayActiveHighCH7",
                          "relayActiveHighCH8",
                        ].map((item) => (
                          <TableCell align="center" key={item}>
                            <Controller
                              name={item}
                              control={control}
                              defaultValue={data[item] || false}
                              render={({ field }) => (
                                <Switch type="checkbox" size="medium" {...field} checked={field.value} />
                              )}
                            />
                          </TableCell>
                        ))}
                      </TableRow>
                      <TableRow>
                        <TableCell align="center">Delay Time (second)</TableCell>
                        {[
                          "triggerTimeCH1",
                          "triggerTimeCH2",
                          "triggerTimeCH3",
                          "triggerTimeCH4",
                          "triggerTimeCH5",
                          "triggerTimeCH6",
                          "triggerTimeCH7",
                          "triggerTimeCH8",
                        ].map((item) => (
                          <TableCell align="center" key={item}>
                            <Controller
                              name={item}
                              control={control}
                              rules={{ min: 1 }}
                              defaultValue={data[`triggerTimeCH${item[item.length - 1]}`] || 1}
                              render={({ field, fieldState }) => (
                                <FormControl size="small" fullWidth sx={{ marginTop: 1.5 }}>
                                  <TextField
                                    type="number"
                                    variant="outlined"
                                    size="small"
                                    error={!!fieldState.error}
                                    helperText={fieldState.error && "Minimum value is 1"}
                                    step={1}
                                    {...field}
                                  />
                                </FormControl>
                              )}
                            />
                          </TableCell>
                        ))}
                      </TableRow>

                      <TableRow>
                        <TableCell align="center">Level 3</TableCell>
                        {[
                          "level3CH1",
                          "level3CH2",
                          "level3CH3",
                          "level3CH4",
                          "level3CH5",
                          "level3CH6",
                          "level3CH7",
                          "level3CH8",
                        ].map((item) => (
                          <TableCell align="center" key={item}>
                            <Controller
                              name={item}
                              control={control}
                              defaultValue={data[`level3CH${item[item.length - 1]}`] || false}
                              render={({ field }) => (
                                <Switch type="checkbox" size="medium" {...field} checked={field.value} />
                              )}
                            />
                          </TableCell>
                        ))}
                      </TableRow>

                      <TableRow>
                        <TableCell align="center">Level 2</TableCell>
                        {[
                          "level2CH1",
                          "level2CH2",
                          "level2CH3",
                          "level2CH4",
                          "level2CH5",
                          "level2CH6",
                          "level2CH7",
                          "level2CH8",
                        ].map((item) => (
                          <TableCell align="center" key={item}>
                            <Controller
                              name={item}
                              control={control}
                              defaultValue={data[`level2CH${item[item.length - 1]}`] || false}
                              render={({ field }) => (
                                <Switch type="checkbox" size="medium" {...field} checked={field.value} />
                              )}
                            />
                          </TableCell>
                        ))}
                      </TableRow>

                      <TableRow>
                        <TableCell align="center">Level 1</TableCell>
                        {[
                          "level1CH1",
                          "level1CH2",
                          "level1CH3",
                          "level1CH4",
                          "level1CH5",
                          "level1CH6",
                          "level1CH7",
                          "level1CH8",
                        ].map((item) => (
                          <TableCell align="center" key={item}>
                            <Controller
                              name={item}
                              control={control}
                              defaultValue={data[`level1CH${item[item.length - 1]}`] || false}
                              render={({ field }) => (
                                <Switch type="checkbox" size="medium" {...field} checked={field.value} />
                              )}
                            />
                          </TableCell>
                        ))}
                      </TableRow>

                      <TableRow>
                        <TableCell align="center">Level 0</TableCell>
                        {[
                          "level0CH1",
                          "level0CH2",
                          "level0CH3",
                          "level0CH4",
                          "level0CH5",
                          "level0CH6",
                          "level0CH7",
                          "level0CH8",
                        ].map((item) => (
                          <TableCell align="center" key={item}>
                            <Controller
                              name={item}
                              control={control}
                              defaultValue={data[`level0CH${item[item.length - 1]}`] || false}
                              render={({ field }) => (
                                <Switch type="checkbox" size="medium" {...field} checked={field.value} />
                              )}
                            />
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableBody>
                  </Table>
                </Grid>
              </Grid>

              <Grid container spacing={2} mt={1}>
                <Grid item xs={12}>
                  <Collapse in={error.isError}>
                    <Alert
                      onClose={() =>
                        setError({
                          isError: false,
                          message: "",
                        })
                      }
                      severity="error"
                    >
                      {error.message}
                    </Alert>
                  </Collapse>
                </Grid>
              </Grid>

              <Grid container display="flex" justifyContent="flex-end" mt={3}>
                <Button
                  variant="text"
                  disableRipple
                  onClick={handleClose}
                  color="error"
                  size="small"
                  sx={{ marginRight: 3 }}
                >
                  Cancel
                </Button>
                <Button type="submit" variant="contained" size="small" color="success">
                  Save
                </Button>
              </Grid>
            </form>
          </Grid>
        ) : (
          <Grid item xs={12}>
            <Typography variant="h6" align="center">
              Loading...
            </Typography>
          </Grid>
        )}
      </Grid>
    </>
  );
}

export default EditDevice;
