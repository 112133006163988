import {
  Button,
  Grid,
  TextField,
  Typography,
  MenuItem,
  Select,
  FormControl,
  Stack,
  Checkbox,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  FormControlLabel,
  Switch,
  Box,
  CircularProgress,
} from "@mui/material";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import moment from "moment";

const config = require("./../../../../configs/app");

export default function EditOrganization({ fetchOrg }) {
  const [data, setData] = useState({});
  const [editing, setEditing] = useState(false);
  const [eFieldSensor, setEFieldSensor] = useState([]);
  const [sound, setSound] = useState([]);

  const [user, setUser] = useState([]);
  const [device, setDevice] = useState([]);

  const key = Cookies.get("pathName");

  const fetchData = () => {
    fetch(`${config.urlAuth}/api/v1/organization/${key}`)
      .then((res) => res.json())
      .then((res) => {
        let data = res.message;
        data.EFieldSensor = data.EFieldSensors.map((item) => item.warningPointId);
        return data;
      })
      .then((res) => setData(res))
      .catch((err) => console.error(err));

    fetch(`${config.urlLightningConfig}/api/v1/e-field-sensor/`)
      .then((res) => res.json())
      .then((res) => res.message)
      .then((res) => setEFieldSensor(res))
      .catch((err) => console.error(err));

    fetch(`${config.urlSound}/api/v1/alarm-sound`)
      .then((res) => res.json())
      .then((res) => {
        setSound(res);
      })
      .catch((err) => console.error(err));

    fetch(`${config.urlAuth}/api/v1/user/${key}`)
      .then(async (res) => {
        if (res.ok) {
          const data = await res.json();
          setUser(data.message);
        } else {
          setUser([]);
        }
      })
      .catch((err) => console.error(err));

    fetch(`${config.urlDevice}/api/v1/device/keyName/${key}`)
      .then(async (res) => {
        if (res.ok) {
          const data = await res.json();
          setDevice(data.message);
        } else {
          setDevice([]);
        }
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setData({
      ...data,
      [name]: value,
    });
  };

  const handleCheckboxChange = (event) => {
    const value = event.target.value;
    const checked = event.target.checked;
    let updatedEFieldSensors = [...data.EFieldSensor];

    if (checked) {
      updatedEFieldSensors.push(value);
    } else {
      updatedEFieldSensors = updatedEFieldSensors.filter((id) => id !== value);
    }
    handleInputChange({ target: { name: "EFieldSensor", value: updatedEFieldSensors } });
  };

  const handleEdit = () => {
    setEditing(true);
  };

  const handleCancelEdit = () => {
    fetchData();
    setEditing(false);
  };

  const handleSave = () => {
    // handle before save

    const countUser = user.length;
    const countDevice = device.length;

    if (data.maxUser < countUser) {
      alert(`จำนวนผู้ใช้สูงสุดต้องไม่น้อยกว่าจำนวนผู้ใช้ปัจจุบัน (${countUser})`);
      return;
    }

    if (data.maxDevice < countDevice) {
      alert(`จำนวนอุปกรณ์สูงสุดต้องไม่น้อยกว่าจำนวนอุปกรณ์ปัจจุบัน (${countDevice})`);
      return;
    }

    fetch(`${config.urlAuth}/api/v1/organization/${key}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then(async (response) => {
        if (response.ok) {
          fetchOrg();
          setEditing(false);
          fetchData();
        } else {
          const err = await response.json();
          alert(err.message);
        }
      })
      .then((data) => { })
      .catch((error) => {
        console.error("Error adding data:", error);
      });
  };

  return (
    <>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Typography sx={{ my: 2 }}>
            Location Information{" : "}
            {editing ? (
              <span style={{ color: "red" }}>Editing</span>
            ) : (
              <span style={{ color: "green" }}>Save</span>
            )}
          </Typography>
        </Grid>
        <Grid item display="flex">
          {editing ? (
            <Stack direction="row" spacing={1}>
              <FormControlLabel
                control={
                  <Switch
                    checked={data.activeStatus || false}
                    onChange={(event) => {
                      setData({ ...data, activeStatus: event.target.checked });
                    }}
                  />
                }
                label="Active"
              />
              <Button variant="text" color="error" onClick={handleCancelEdit}>
                Cancel
              </Button>
              <Button variant="contained" color="success" onClick={handleSave}>
                Save
              </Button>
            </Stack>
          ) : (
            <Button variant="outlined" color="warning" onClick={handleEdit}>
              Edit
            </Button>
          )}
        </Grid>
      </Grid>

      <hr />
      {data.keyName ? (
        <Grid container>
          <Typography mt={1}> Name Location</Typography>
          <TextField
            fullWidth
            error={editing}
            autoComplete="new-password"
            hiddenLabel
            value={data.nameLocation || ""}
            size="small"
            type="text"
            name="nameLocation"
            onChange={handleInputChange}
            InputProps={{
              readOnly: !editing,
            }}
          />

          <Grid container spacing={1} display="flex" justifyContent="space-between" mt={1}>
            <Grid item xs={6} alignItems="center" justifyContent="center">
              <Typography>Latitude</Typography>
              <TextField
                fullWidth
                error={editing}
                autoComplete="new-password"
                hiddenLabel
                value={data.lat || ""}
                size="small"
                type="number"
                step="0.000001"
                name="lat"
                onChange={handleInputChange}
                InputProps={{
                  readOnly: !editing,
                }}
              />
            </Grid>
            <Grid item xs={6} alignItems="center" justifyContent="center">
              <Typography>Longitude</Typography>
              <TextField
                fullWidth
                error={editing}
                autoComplete="new-password"
                hiddenLabel
                value={data.lon || ""}
                size="small"
                type="number"
                step="0.000001"
                name="lon"
                onChange={handleInputChange}
                InputProps={{
                  readOnly: !editing,
                }}
              />
            </Grid>
          </Grid>

          <Typography mt={1}>Address</Typography>
          <TextField
            fullWidth
            error={editing}
            autoComplete="new-password"
            hiddenLabel
            value={data.address || ""}
            size="small"
            type="text"
            name="address"
            onChange={handleInputChange}
            InputProps={{
              readOnly: !editing,
            }}
          />

          <Grid container spacing={1} display="flex" justifyContent="space-between" mt={1}>
            <Grid item xs={4} alignItems="center" justifyContent="center">
              <Typography>Email</Typography>
              <TextField
                fullWidth
                error={editing}
                autoComplete="new-password"
                hiddenLabel
                value={data.email || ""}
                size="small"
                type="text"
                name="email"
                onChange={handleInputChange}
                InputProps={{
                  readOnly: !editing,
                }}
              />
            </Grid>
            <Grid item xs={4} alignItems="center" justifyContent="center">
              <Typography>Phone Number</Typography>
              <TextField
                fullWidth
                error={editing}
                autoComplete="new-password"
                hiddenLabel
                value={data.phoneNumber || ""}
                size="small"
                type="text"
                name="phoneNumber"
                onChange={handleInputChange}
                InputProps={{
                  readOnly: !editing,
                }}
              />
            </Grid>
            <Grid item xs={4} alignItems="center" justifyContent="center">
              <Typography>Line Notify Token</Typography>
              <TextField
                fullWidth
                error={editing}
                autoComplete="new-password"
                hiddenLabel
                value={data.lineToken || ""}
                size="small"
                type="text"
                name="lineToken"
                onChange={handleInputChange}
                InputProps={{
                  readOnly: !editing,
                }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={1} display="flex" justifyContent="space-between" mt={1}>
            <Grid item xs={6} alignItems="center" justifyContent="center">
              <Typography>Map Type</Typography>
              <FormControl size="small" fullWidth error={editing}>
                <Select
                  name="mapType"
                  value={data.mapType || 0}
                  onChange={handleInputChange}
                  inputProps={{ readOnly: !editing }}
                >
                  <MenuItem value={0}>-</MenuItem>
                  <MenuItem value={1}>M-Field</MenuItem>
                  <MenuItem value={2}>E-Field</MenuItem>
                  <MenuItem value={3}>M-Field and E-Field</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={6} alignItems="center" justifyContent="center">
              <Typography>Notification Sound</Typography>
              <FormControl size="small" fullWidth error={editing}>
                <Select
                  name="soundWarning"
                  value={data.soundWarning || ""}
                  onChange={handleInputChange}
                  inputProps={{ readOnly: !editing }}
                >
                  <MenuItem value={""}>-</MenuItem>
                  {sound?.map((item) => (
                    <MenuItem key={item.id} value={item.name}>
                      {item.sound_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Grid container spacing={1} display="flex" justifyContent="space-between" mt={1}>
            <Grid item xs={4} alignItems="center" justifyContent="center">
              <Typography>Max User</Typography>
              <TextField
                fullWidth
                error={editing}
                autoComplete="new-password"
                hiddenLabel
                value={data.maxUser || ""}
                size="small"
                name="maxUser"
                type="number"
                onChange={handleInputChange}
                InputProps={{
                  readOnly: !editing,
                }}
              />
            </Grid>
            <Grid item xs={4} alignItems="center" justifyContent="center">
              <Typography>Max Device</Typography>
              <TextField
                fullWidth
                error={editing}
                autoComplete="new-password"
                hiddenLabel
                value={data.maxDevice || ""}
                size="small"
                name="maxDevice"
                type="number"
                onChange={handleInputChange}
                InputProps={{
                  readOnly: !editing,
                }}
              />
            </Grid>
            <Grid item xs={4} alignItems="center" justifyContent="center">
              <Typography>Expire Date</Typography>
              <TextField
                fullWidth
                error={editing}
                autoComplete="new-password"
                hiddenLabel
                value={
                  moment(data.packageEnd).format("YYYY-MM-DD") || moment().utcOffset("+07:00").format("YYYY-MM-DD")
                }
                size="small"
                name="packageEnd"
                type="date"
                onChange={handleInputChange}
                format="dd-MM-yyyy"
                InputProps={{
                  readOnly: !editing,
                }}
              />
            </Grid>
          </Grid>

          <Typography mt={1}>Active Zone R (meter)</Typography>
          <TextField
            fullWidth
            error={editing}
            autoComplete="new-password"
            hiddenLabel
            value={data.activeZoneR || ""}
            size="small"
            name="activeZoneR"
            type="number"
            onChange={handleInputChange}
            InputProps={{
              readOnly: !editing,
            }}
          />

          <Typography mt={1}>Active Zone A (meter)</Typography>
          <TextField
            fullWidth
            error={editing}
            autoComplete="new-password"
            hiddenLabel
            value={data.activeZoneA || ""}
            size="small"
            name="activeZoneA"
            type="number"
            onChange={handleInputChange}
            InputProps={{
              readOnly: !editing,
            }}
          />

          <Typography mt={1}>Active Zone B (meter)</Typography>
          <TextField
            fullWidth
            error={editing}
            autoComplete="new-password"
            hiddenLabel
            value={data.activeZoneB || ""}
            size="small"
            name="activeZoneB"
            type="number"
            onChange={handleInputChange}
            InputProps={{
              readOnly: !editing,
            }}
          />

          <Typography mt={1}>Active Zone E-Field (meter)</Typography>
          <TextField
            fullWidth
            error={editing}
            autoComplete="new-password"
            hiddenLabel
            value={data.activeZoneEField || ""}
            size="small"
            name="activeZoneEField"
            type="number"
            onChange={handleInputChange}
            InputProps={{
              readOnly: !editing,
            }}
          />

          <Typography mt={1}>Display Map (meter)</Typography>
          <TextField
            fullWidth
            error={editing}
            autoComplete="new-password"
            hiddenLabel
            value={data.display || ""}
            size="small"
            name="display"
            type="number"
            onChange={handleInputChange}
            InputProps={{
              readOnly: !editing,
            }}
          />

          <Stack sx={{ width: "100%" }}>
            <Typography mt={1}>E-Field Sensor</Typography>
            <TableContainer component={Paper} sx={{ width: "100%", bgcolor: "transparent", boxShadow: "none" }}>
              <Table size="small" sx={{ border: "none" }}>
                <TableHead>
                  <TableRow>
                    <TableCell>Checkbox</TableCell>
                    <TableCell>Warning Point ID</TableCell>
                    <TableCell>Location</TableCell>
                    <TableCell>Latitude</TableCell>
                    <TableCell>Longitude</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.EFieldSensor &&
                    eFieldSensor.map((item) => (
                      <TableRow key={item.warningPointId}>
                        <TableCell align="center">
                          <Checkbox
                            type="checkbox"
                            name="EFieldSensor"
                            // error={editing}
                            value={item.warningPointId}
                            checked={data.EFieldSensor.includes(item.warningPointId)}
                            onChange={handleCheckboxChange}
                            disabled={!editing}
                          />
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {item.warningPointId}
                        </TableCell>
                        <TableCell>{item.warningPointName}</TableCell>
                        <TableCell>{item.lat}</TableCell>
                        <TableCell>{item.lon}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Stack>
        </Grid>
      ) : (
        <Grid container justifyContent="center" alignItems="center" sx={{ height: "100vh" }}>
          <Box sx={{ display: "flex" }}>
            <CircularProgress color="inherit" />
          </Box>
        </Grid>
      )}
    </>
  );
}
