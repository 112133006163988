import React from 'react';

const DaySelector = ({ selectedDays, onChange }) => {
  const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

  return (
    <div  className="day-selector" align="right">
      {daysOfWeek.map((day, index) => (
        <label key={index}>
          <input
            type="checkbox"
            value={day}
            name="activeDay"
            checked={selectedDays.includes(day)}
            onChange={onChange}
          />
          {day}
        </label>
      ))}
    </div>
  );
};
export default DaySelector;