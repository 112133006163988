const customStyles = {
    headCells: {
        style: {
            fontSize: '14px',
            textDecoration: 'none',
            borderBottom: 'none',
            // background:" rgb(95, 95, 95)",
            // color:'white'
        },
    },
    cells: {
        style: {
            fontSize: '14px',
            textDecoration: 'none',
            borderBottom: 'none',
            borderLeft:'black'
        },
    },
};

export { customStyles }