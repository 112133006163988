import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  Modal,
  OutlinedInput,
  Skeleton,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import moment from "moment";
import AddDevice from "./AddDevice.jsx";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import CopyAllIcon from "@mui/icons-material/CopyAll";
import DeleteDevice from "./DeleteDevice";
import EditDevice from "./EditDevice.jsx";
import apiUrls from "../../../../ApiUrl.jsx";

const config = require("./../../../../configs/app");

function Device({ org }) {
  const [device, setDevice] = useState([]);

  const [loading, setLoading] = useState(true);
  const [allowAdd, setAllowAdd] = useState(true);
  const [isAddOpen, setIsAddOpen] = useState(false);
  const [isRelayOpen, setIsRelayOpen] = useState({
    open: false,
    uid: "",
  });
  const [isDelete, setIsDelete] = useState({
    open: false,
    data: {},
  });
  const [openAlert, setOpenAlert] = useState({
    open: false,
    message: "",
    severity: "",
  });
  const [isEdit, setIsEdit] = useState({
    open: false,
    id: "",
  });
  const key = Cookies.get("pathName");



  const fetchDevice = () => {
    console.log("fetching device");
    setLoading(true);
    fetch(`${config.urlDevice}/api/v1/device/keyName/${key}`)
      .then(async (response) => {
        if (response.ok) {
          const res = await response.json();
          // console.log(res);
          setDevice(res.message);
          setAllowAdd(res.message?.length >= org.maxDevice);
        } else {
          setDevice([]);
          setAllowAdd(false);
        }
      })
      .catch((error) => console.error("Error fetching data:", error))
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchDevice();

    setInterval(() => {
      fetchDevice();
    }, 60000);
  }, []);

  const handleClose = () => {
    fetchDevice();
    setIsAddOpen(false);
    setIsRelayOpen({
      open: false,
      uid: "",
    });
    setIsDelete({
      open: false,
      data: {},
    });
    setIsEdit({
      open: false,
      id: "",
    });
  };

  return (
    <>
      <Grid container>
        <Grid container display="flex" alignItems="center" justifyContent="space-between">
          <Grid item display="flex" alignItems="center">
            <Typography>Setting Device : Max {org.maxDevice} Device </Typography>
            <Tooltip title="Refresh" placement="top">
              <IconButton onClick={fetchDevice}>
                <RefreshIcon />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item>
            <Button
              disableRipple
              size="small"
              variant="contained"
              color="success"
              onClick={() => setIsAddOpen(true)}
              disabled={allowAdd}
            >
              Add Device
            </Button>
          </Grid>
        </Grid>

        {device && (
          <Grid item xs={12}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell align="center">UID</TableCell>
                  <TableCell align="center">Enable</TableCell>
                  <TableCell align="center">Name Device</TableCell>
                  <TableCell align="center">Status</TableCell>
                  <TableCell align="center">Last Check</TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {device.length !== 0 ? (
                  device.map((device) => (
                    <TableRow key={device.id}>
                      <TableCell align="center">{device.uid}</TableCell>
                      <TableCell align="center">
                        {device.enable ? <CheckCircleIcon color="success" /> : <CancelIcon color="error" />}
                      </TableCell>
                      <TableCell align="center">
                        {device.nameDevice}
                        <br />
                        <span style={{ color: "#00000086" }}>{device.description}</span>
                      </TableCell>

                      <TableCell align="center">
                        {device.status ? (
                          <img src="/image/lamp.png" width="24px" alt="Status ON" />
                        ) : (
                          <img src="/image/lampOff.png" width="24px" alt="Status OFF" />
                        )}
                      </TableCell>
                      <TableCell align="center">{device.lastCheck ? device.lastCheck : "No Data"}</TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          whiteSpace: "nowrap",
                          width: 50,
                        }}
                      >
                        <Button
                          disableRipple
                          size="small"
                          variant="contained"
                          color="inherit"
                          sx={{ mr: 1 }}
                          onClick={() =>
                            setIsRelayOpen({
                              open: true,
                              uid: device.uid,
                            })
                          }
                        >
                          Relay
                        </Button>
                        <Button
                          disableRipple
                          size="small"
                          variant="contained"
                          color="warning"
                          sx={{ mr: 1 }}
                          onClick={() =>
                            setIsEdit({
                              open: true,
                              id: device.id,
                            })
                          }
                        >
                          Edit
                        </Button>
                        <Button
                          disableRipple
                          size="small"
                          variant="contained"
                          color="error"
                          onClick={() =>
                            setIsDelete({
                              open: true,
                              data: device,
                            })
                          }
                        >
                          Delete
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={6} align="center">
                      {loading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : (
                        <Typography variant="body2" color="text.secondary">
                          No Data
                        </Typography>
                      )}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </Grid>
        )}

        <Dialog open={isEdit.open} onClose={handleClose} fullWidth maxWidth="md">
          <DialogTitle>
            <Grid container>
              <Grid item xs={6}>
                <span fontWeight="bold">Edit Device</span>
              </Grid>
              <Grid item xs={6} style={{ textAlign: "right" }}>
                <IconButton disableFocusRipple disableRipple size="small" onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </DialogTitle>

          <DialogContent dividers>
            <EditDevice id={isEdit.id} handleClose={handleClose} fetchDevice={fetchDevice} setAlert={setOpenAlert} />
          </DialogContent>
        </Dialog>

        <Dialog open={isDelete.open} onClose={handleClose} fullWidth maxWidth="xs">
          <DialogTitle>
            <Grid container>
              <Grid item xs={6}>
                <span fontWeight="bold">Delete Device</span>
              </Grid>
              <Grid item xs={6} style={{ textAlign: "right" }}>
                <IconButton disableFocusRipple disableRipple size="small" onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </DialogTitle>
          <DeleteDevice
            data={isDelete.data}
            handleClose={handleClose}
            fetchDevice={fetchDevice}
            setAlert={setOpenAlert}
          />
        </Dialog>

        <Dialog open={isRelayOpen.open} onClose={handleClose} fullWidth maxWidth="sm">
          <DialogTitle>
            <Grid container>
              <Grid item xs={6}>
                <span fontWeight="bold">Relay Information</span>
              </Grid>
              <Grid item xs={6} style={{ textAlign: "right" }}>
                <IconButton disableFocusRipple disableRipple size="small" onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </DialogTitle>

          <DialogContent dividers>
            <Relay uid={isRelayOpen.uid} handleClose={handleClose} fetchDevice={fetchDevice} />
          </DialogContent>
          <DialogActions>
            <Button variant="text" disableRipple onClick={handleClose} color="error">
              Close
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={isAddOpen}
          onClose={handleClose}
          fullWidth
          maxWidth="md"
          onClick={(event, reason) => {
            if (reason === "backdropClick") {
              handleClose();
            }
          }}
        >
          <DialogTitle>
            <Grid container>
              <Grid item xs={6}>
                <span fontWeight="bold">Add Device</span>
              </Grid>
              <Grid item xs={6} style={{ textAlign: "right" }}>
                <IconButton disableFocusRipple disableRipple size="small" onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </DialogTitle>

          <DialogContent dividers>
            <AddDevice handleClose={handleClose} fetchDevice={fetchDevice} setAlert={setOpenAlert} />
          </DialogContent>
        </Dialog>
      </Grid>

      <Snackbar
        open={openAlert.open}
        autoHideDuration={6000}
        onClose={() => {
          setOpenAlert({
            open: false,
            message: "",
            severity: "",
          });
        }}
        position="top-right"
      >
        <Alert onClose={handleClose} severity={openAlert.severity || "success"} sx={{ width: "100%" }}>
          {openAlert.message}
        </Alert>
      </Snackbar>
    </>
  );
}

const Relay = ({ uid, handleClose, fetchDevice }) => {
  const [relayData, setRelayData] = useState({});
  const [isCopy, setIsCopy] = useState(false);

  const fetchRelay = () => {
    fetch(`${config.urlDevice}/api/device/send/${uid}`)
      // .then((response) => response.json())
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          return {};
        }
      })
      .then((res) => {
        setRelayData(res);
      })
      .catch((error) => console.error("Error fetching data:", error));
  };

  useEffect(() => {
    fetchRelay();
  }, []);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography>Link Get Relay For Device UID: {uid}</Typography>
          <FormControl fullWidth variant="standard">
            <OutlinedInput
              type="text"
              size="small"
              readOnly
              value={`${config.urlDevice}/api/device/send/${uid}`}
              endAdornment={
                <InputAdornment position="end">
                  <Tooltip title={isCopy ? "Copied" : "Copy"} placement="top">
                    <IconButton
                      disableFocusRipple
                      edge="end"
                      onClick={() => {
                        navigator.clipboard.writeText(`${config.urlDevice}/api/device/send/${uid}`);
                        setIsCopy(true);
                        setTimeout(() => {
                          setIsCopy(false);
                        }, 3000);
                      }}
                    >
                      <CopyAllIcon />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              }
            />
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <Typography>Status Relay At {moment().format("YYYY-MM-D HH:MM:ss")}</Typography>
          <Card sx={{ minWidth: 275 }} mt={2}>
            <CardContent>
              {relayData && (
                <pre>
                  <Typography variant="body2" color="text.secondary" style={{ whiteSpace: "pre-wrap" }}>
                    {JSON.stringify(relayData, undefined, 8)}
                  </Typography>
                </pre>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default Device;
