import React, { useContext, useEffect, useState } from "react";
import {
  Typography,
  Button,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Switch,
  IconButton,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Snackbar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { showSidebar } from "../../kumwell";

const config = require("./../../configs/app");
const SettingsPage = ({ superAdmin, create, update, remove, Admin }) => {
  const [retryCount, setRetryCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [addGroup, setAddGroup] = useState();
  const [permissionADDGroup, setPermissionADDGroup] = useState([]);
  const [selectedRole, setSelectedRole] = useState("userOrganization");
  const [role, setRole] = useState([]);
  const [pageView, setPageView] = useState([]);
  const [isAddRoleModalOpen, setIsAddRoleModalOpen] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [newRoleName, setNewRoleName] = useState("");
  const [checkDelete, setCheckDelete] = useState("");
  // const [newRolePermissions, setNewRolePermissions] = useState([]);
  const { auth } = useContext(showSidebar);
  const [changePermission, setChangePermission] = useState([]);
  const [changePermissionId, setChangePermissionId] = useState([]);
  const [statusMessage, setStatusMessage] = useState("");
  const [selectAll, setSelectAll] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMessage2, setErrorMessage2] = useState("");

  async function DeleteRole(roleId) {
    try {
      const response = await fetch(`${config.urlAuth}/api/v1/role/${roleId}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      if (!response.ok) {
        throw new Error("Failed to delete role.");
      }
      else {
        setStatusMessage(`Role has been deleted.`);
        setShowConfirmation(false);
        setSelectedRole("adminOrganization");
        setRetryCount(retryCount + 1);
      }

    } catch (error) {
      // throw new Error("Error: Something went wrong.");
      setStatusMessage(`Role has been deleted.`);
      setShowConfirmation(false);
      setSelectedRole("adminOrganization");
      setRetryCount(retryCount + 1);
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${config.urlAuth}/api/v1/role/`);
        if (!response.ok) {
          throw new Error("Failed to fetch data.");
        } else {
          const data = await response.json();
          // console.log(data)
          setCheckDelete(data.message);
          const permissionGroup = data.message.map((item) => ({
            roleId: item.roleId,
            name: item.name,
            Permissions: item.Permissions.map((permission) => ({
              id: permission.id,
              type: permission.type,
              name: permission.name,
            })),
          }));
          setRole(permissionGroup);
          setPageView(permissionGroup.find((item) => item.name === selectedRole));
        }
      } catch (error) {
        setStatusMessage("Error fetching data from the API.");
      }
    };
    fetchData();
  }, [retryCount]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${config.urlAuth}/api/v1/permission/`);
        if (!response.ok) {
          throw new Error("Failed to fetch data.");
        } else {
          const data = await response.json();
          setPermissionADDGroup(data.message);
        }
      } catch (error) {
        setStatusMessage("Error fetching data from the API.");
      }
    };
    fetchData();
  }, [retryCount]);

  const uniqueGroupAll = permissionADDGroup ? [...new Set(permissionADDGroup.flatMap(item => item.group))] : [];
  const uniqueRoleAll = checkDelete ? [...new Set(checkDelete.flatMap(item => item.name))] : [];
  const uniqueGroupNames = checkDelete ? [...new Set(checkDelete.flatMap(item => item.Permissions.map(permission => permission.group)))] : [];
  const filteredData = uniqueGroupNames ? permissionADDGroup.filter(item => item.group !== "option" && !uniqueGroupNames.includes(item.group)) : [];

  // console.log(filteredData);
  // const apiUrl = 'http://7315050e2cc3.sn.mynetname.net:8082/api/v1/permission';
  // function deletePermissionById(id) {
  //   fetch(`${apiUrl}/${id}`, {
  //     method: 'DELETE',
  //   })
  //     .then(response => {
  //       if (response.ok) {
  //         console.log(`Successfully deleted permission with id ${id}`);
  //       } else {
  //         console.error(`Failed to delete permission with id ${id}`);
  //       }
  //     })
  //     .catch(error => {
  //       console.error(`Error deleting permission with id ${id}:`, error);
  //     });
  // }

  // useEffect(() => {
  //   filteredData.forEach(item => {
  //     console.log(item.id);
  //     //   deletePermissionById(item.id);

  //   });
  // }, [filteredData]);


  const getUniqueGroups = (data) => {
    if (!Array.isArray(data) || data.length === 0) {
      return [];
    }
    const groupSet = new Set();
    data.forEach((item) => groupSet.add(item.group));
    const uniqueGroups = Array.from(groupSet);
    return uniqueGroups;
  };

  const updatedData = pageView.name
    ? pageView.Permissions.map((item) => {
      switch (item.type) {
        case "read":
          return { ...item, statusRead: "true" };
        case "reads":
          return { ...item, statusRead: "false" };
        case "all":
          return { ...item, statusAll: "true" };
        case "alls":
          return { ...item, statusAll: "false" };
        case "create":
          return { ...item, statusCreate: "true" };
        case "creates":
          return { ...item, statusCreate: "false" };
        case "update":
          return { ...item, statusUpdate: "true" };
        case "updates":
          return { ...item, statusUpdate: "false" };
        case "delete":
          return { ...item, statusDelete: "true" };
        case "deletes":
          return { ...item, statusDelete: "false" };
        default:
          return item;
      }
    })
    : [];

  const handleRoleChange = (event) => {
    setChangePermission([]);
    setSelectedRole(event.target.value);
    setPageView(role.find((item) => item.name === event.target.value));
  };

  function isRoleNameUsed(roleName) {
    const lowerCaseRoleName = roleName.toLowerCase();
    return uniqueRoleAll.some(item => item.toLowerCase() === lowerCaseRoleName);
    // return uniqueRoleAll.includes(roleName);

  }
  function isPermissionNameUsed(permissionUsed) {
    const lowerCaseGroupName = permissionUsed.toLowerCase();
    return uniqueGroupAll.some(item => item.toLowerCase() === lowerCaseGroupName);
    //   return uniqueGroupAll.includes(permissionUsed);
    // }
  }

  const handleRoleNameChange = (e) => {
    const roleName = e.target.value;
    setNewRoleName(roleName);
    if (isRoleNameUsed(roleName)) {
      setErrorMessage("Role name is already in use.");
    } else {
      setErrorMessage("");
    }
  };

  const handleGroupNameChange = (e) => {
    const groupUsed = e.target.value;
    setAddGroup(groupUsed);
    if (isPermissionNameUsed(groupUsed)) {
      setErrorMessage2("Group name is already in use.");

    } else {
      setErrorMessage2("");
    }
  };

  const handleChangeType = (event) => {
    let id = event.id;
    const typeMapping = {
      all: "alls",
      alls: "all",
      read: "reads",
      reads: "read",
      create: "creates",
      creates: "create",
      update: "updates",
      updates: "update",
      delete: "deletes",
      deletes: "delete",
    };

    const checkEdit = permissionADDGroup.find((item) => item.id === id);
    const sameChange = pageView.Permissions.find((item) => item.id == id);
    if (sameChange.type == checkEdit.type) {
      setChangePermission([
        ...changePermission,
        { id: sameChange.id, type: typeMapping[sameChange.type] || sameChange.type },
      ]);
    } else {
      setChangePermission((prevPermissions) => prevPermissions.filter((permission) => permission.id !== id));
    }
    setChangePermissionId(id);

    setPageView((prevData) => {
      const newPermissions = prevData.Permissions.map((permission) => {
        if (permission.id === id) {
          if (sameChange.type === "read" || sameChange.type === "reads") {
            const newType = permission.type === "read" ? "reads" : "read";
            return { ...permission, type: newType };
          } else if (sameChange.type === "all" || sameChange.type === "alls") {
            const newType = permission.type === "all" ? "alls" : "all";
            return { ...permission, type: newType };
          } else if (sameChange.type === "create" || sameChange.type === "creates") {
            const newType = permission.type === "create" ? "creates" : "create";
            return { ...permission, type: newType };
          } else if (sameChange.type === "update" || sameChange.type === "updates") {
            const newType = permission.type === "update" ? "updates" : "update";
            return { ...permission, type: newType };
          } else if (sameChange.type === "delete" || sameChange.type === "deletes") {
            const newType = permission.type === "delete" ? "deletes" : "delete";
            return { ...permission, type: newType };
          }
        }
        return permission;
      });
      return { ...prevData, Permissions: newPermissions };
    });
  };

  const handleCloseStatusMessage = () => {
    setStatusMessage("");
  };

  function mergeStatuses(data) {
    const mergedData = [];
    const nameMap = {};
    data.forEach((item) => {
      const { name, type, id, ...rest } = item;
      if (!nameMap[name]) {
        nameMap[name] = { name };
        nameMap[name].statusRead = item.type === "read" ? item.statusRead : "false";
        if (item.type === "all" || item.type === "alls") {
          nameMap[name].statusAll = item.type === "all" ? item.statusAll : "false";
        }
        nameMap[name].statusCreate = item.type === "create" ? item.statusCreate : "false";
        nameMap[name].statusUpdate = item.type === "update" ? item.statusUpdate : "false";
        nameMap[name].statusDelete = item.type === "delete" ? item.statusDelete : "false";
        nameMap[name].ids = [];
        nameMap[name].ids.push({ name: type, id });
        mergedData.push(nameMap[name]);
      } else {
        if (item.type === "read" && nameMap[name].statusRead === "false") {
          nameMap[name].statusRead = item.statusRead;
        } else if (item.type === "all" && nameMap[name].statusAll === "false") {
          nameMap[name].statusAll = item.statusAll;
        } else if (item.type === "create" && nameMap[name].statusCreate === "false") {
          nameMap[name].statusCreate = item.statusCreate;
        } else if (item.type === "update" && nameMap[name].statusUpdate === "false") {
          nameMap[name].statusUpdate = item.statusUpdate;
        } else if (item.type === "delete" && nameMap[name].statusDelete === "false") {
          nameMap[name].statusDelete = item.statusDelete;
        }
        nameMap[name].ids.push({ name: type, id });
      }
    });
    return mergedData;
  }

  const updatePermissionsToApi = (data) => {
    data.forEach((item) => {
      const { id, type } = item;
      const apiUrl = `${config.urlAuth}/api/v1/permission/${id}`;

      fetch(apiUrl, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ type: type }),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log("Updated permission:", data);
          setIsLoading(false);
          setChangePermission([]);
          setStatusMessage(`Permission has been changed`);
          setRetryCount(retryCount + 1);
        })
        .catch((error) => {
          setStatusMessage("Error: Something went wrong.");
        });
    });
  };

  const handleCancelDelete = () => {
    setShowConfirmation(false);
  };

  const handleDeleteRoleClick = () => {
    setShowConfirmation(true);
  };

  const handleButtonClick = () => {
    setIsLoading(true);
    updatePermissionsToApi(changePermission);
  };

  const handleSelectChange = (itemId) => {
    setSelectAll((prevSelectedIds) => {
      if (prevSelectedIds.includes(itemId)) {
        return prevSelectedIds.filter((id) => id !== itemId);
      } else {
        return [...prevSelectedIds, itemId];
      }
    });
  };
  const handleDeleteRole = () => {
    DeleteRole(pageView.roleId);

  };

  const mergedData = mergeStatuses(updatedData);

  const findPermissionsByGroup = (group) => {
    const permissionsWithGroup = permissionADDGroup.filter((item) => item.group === group);
    return permissionsWithGroup;
  };
  const getPermissionGroup = findPermissionsByGroup("option");
  const getPermissionName = selectAll.map((itemName) => ({ name: itemName }));

  const matchedData = getPermissionName.map((item) => ({
    matches: getPermissionGroup.filter((dataItem) => dataItem.name === item.name),
  }));

  const extractedIds = [].concat(
    ...matchedData.map((item) => item.matches.map((match) => ({ name: match.name, type: match.type })))
  );

  async function postPermission(data) {
    try {
      console.log(data)
      const postedIds = [];
      const requestBody = { group: "", name: "", type: "" };
      for (const item of data) {
        requestBody.group = item.group;
        requestBody.name = item.name;
        requestBody.type = item.type;
        const response = await fetch(`${config.urlAuth}/api/v1/permission/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        });
        const responseData = await response.json();
        postedIds.push(responseData.message.id);
      }
      const newData = {
        name: newRoleName,
        permissions: postedIds,
      };
      const newResponse = await fetch(`${config.urlAuth}/api/v1/role/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newData),
      });
      const newResponseData = await newResponse.json();

      if (newResponseData.status === 201) {
        setStatusMessage(`Role has been added successfully.`);
        setNewRoleName("");
        setSelectAll([]);
        setIsAddRoleModalOpen(false);
        setSelectedRole(newRoleName);
        setRetryCount(retryCount + 1);
      } else {
        setStatusMessage("Role or Group has already exists");
      }
    } catch (error) {
      console.error("Error posting data to API:", error);
    }
  }

  const pushRole = () => {
    const newData = extractedIds.map((item) => ({
      ...item,
      group: addGroup,
    }));
    postPermission(newData);
  };

  return (
    <React.Fragment>
      {selectedRole && (
        <FormControl fullWidth>
          <InputLabel id="role-label" sx={{ marginTop: "-5px" }}>
            Select Role
          </InputLabel>
          <Select
            labelId="role-label"
            id="role"
            value={selectedRole || ""}
            onChange={handleRoleChange}
            sx={{ height: "35px" }}
            label="Select Role"
          >
            {role &&
              role
                .filter(
                  (item) =>
                    (superAdmin ? item.name : item.name !== "adminKumwell") &&
                    item.roleId !== 29 &&
                    item.roleId !== auth.Role.roleId &&
                    (Admin ? item.name : item.name !== "userKumwell")
                )
                .map((item, index) => (
                  <MenuItem value={item.name} key={index}>
                    {item.name}
                  </MenuItem>
                ))}
          </Select>
        </FormControl>
      )}
      {create && (
        <Button
          onClick={() => setIsAddRoleModalOpen(true)}
          variant="contained"
          color="primary"
          style={{ marginTop: "15px", height: "30px", marginRight: "20px" }}
        >
          Add Role
        </Button>
      )}
      {/* 
            <Button
                variant="contained"
                color="primary"
                style={{ marginTop: "15px", height: "30px", marginRight: "20px" }}
            >
                Add Permission To Role
            </Button> */}
      {remove &&
        pageView.roleId &&
        pageView.roleId !== 25 &&
        pageView.roleId !== 24 &&
        pageView.roleId !== 26 &&
        pageView.roleId !== 27 &&
        pageView.roleId !== 29 && (
          <Button
            variant="contained"
            color="error"
            style={{ marginTop: "15px", height: "30px", marginRight: "20px" }}
            onClick={handleDeleteRoleClick}
          >
            Delete Role
          </Button>
        )}

      <Typography variant="h6">Role: {selectedRole}</Typography>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Page</TableCell>
            <TableCell align="center">View</TableCell>
            <TableCell align="center">Add</TableCell>
            <TableCell align="center">Edit</TableCell>
            <TableCell align="center">Delete</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {mergedData &&
            mergedData.map((item, index) => (
              <TableRow key={index}>
                <TableCell>{item.name}</TableCell>
                <TableCell align="center">
                  <Switch
                    checked={item.statusRead === "true" ? true : false || item.statusAll === "true" ? true : false}
                    color="primary"
                    onChange={() =>
                      handleChangeType(
                        item.ids.find(
                          (create) =>
                            create.name === "read" ||
                            create.name === "reads" ||
                            create.name === "all" ||
                            create.name === "alls"
                        )
                      )
                    }
                  />
                </TableCell>
                {(item.statusCreate &&
                  item.name !== "map" &&
                  item.name !== "Home" &&
                  item.name !== "Test send" &&
                  item.name !== "LineSend" &&
                  item.name !== "Report" &&
                  item.name !== "report" && (
                    <TableCell align="center">
                      <Switch
                        checked={item.statusCreate === "true" ? true : false}
                        color="primary"
                        onChange={() =>
                          handleChangeType(
                            item.ids.find((create) => create.name === "create" || create.name === "creates")
                          )
                        }
                      />
                    </TableCell>
                  )) || <TableCell></TableCell>}
                {(item.statusUpdate &&
                  item.name !== "map" &&
                  item.name !== "Test send" &&
                  item.name !== "LineSend" &&
                  item.name !== "Report" &&
                  item.name !== "report" &&
                  !item.statusAll && (
                    <TableCell align="center">
                      <Switch
                        checked={item.statusUpdate === "true" ? true : false}
                        color="primary"
                        onChange={() =>
                          handleChangeType(
                            item.ids.find((create) => create.name === "update" || create.name === "updates")
                          )
                        }
                      />
                    </TableCell>
                  )) || <TableCell></TableCell>}
                {(item.statusDelete &&
                  item.name !== "map" &&
                  item.name !== "Home" &&
                  item.name !== "Test send" &&
                  item.name !== "LineSend" &&
                  item.name !== "Report" &&
                  item.name !== "report" &&
                  !item.statusAll && (
                    <TableCell align="center">
                      <Switch
                        checked={item.statusDelete === "true" ? true : false}
                        color="primary"
                        onChange={() =>
                          handleChangeType(
                            item.ids.find((create) => create.name === "delete" || create.name === "deletes")
                          )
                        }
                      />
                    </TableCell>
                  )) || <TableCell></TableCell>}
                {/* <TableCell align="center">
                                <IconButton
                                    aria-label="delete"
                                    color="error"
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </TableCell> */}
              </TableRow>
            ))}
        </TableBody>
      </Table>
      {update && (
        <Button
          variant="contained"
          color={"secondary"}
          disabled={(changePermission[0] ? false : true) || (isLoading && true)}
          style={{ marginTop: "25px", height: "30px" }}
          onClick={handleButtonClick}
        >
          {isLoading ? <CircularProgress size={20} color="warning" /> : "Save Changes"}
        </Button>
      )}
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={statusMessage !== ""}
        onClose={handleCloseStatusMessage}
        autoHideDuration={3000}
        message={statusMessage}
      />
      <Dialog
        open={isAddRoleModalOpen}
        onClose={() => setIsAddRoleModalOpen(false)}
        PaperProps={{
          style: {
            width: "500px",
            height: "500px",
            minHeight: "300px",
            // margin: "50px",
          },
        }}
      >
        <DialogTitle>Add New Role</DialogTitle>
        <DialogContent>
          <TextField
            label="Role Name"
            value={newRoleName || ""}
            onChange={handleRoleNameChange}
            fullWidth
            margin="normal"
          />
          <div style={{ color: "red", paddingBottom: "10px" }}>
            {errorMessage && errorMessage}
          </div>
          <TextField
            label="Group Name"
            fullWidth
            value={addGroup || ""}

            onChange={handleGroupNameChange}
          />

          <div style={{ color: "red" }}>
            {errorMessage2 && errorMessage2}
          </div>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Permission</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  <FormControl style={{ margin: 0, padding: 0 }}>
                    {getPermissionGroup &&
                      getPermissionGroup
                        .filter(
                          (item) =>
                            item.group === "option" &&
                            (item.type === "read" ||
                              item.type === "reads" ||
                              item.type === "alls" ||
                              item.type === "all")
                        )
                        .map((item) => (
                          <React.Fragment key={item.id}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={selectAll.includes(item.name)}
                                  onChange={() => handleSelectChange(item.name)}
                                />
                              }
                              label={item.name}
                            />
                          </React.Fragment>
                        ))}
                  </FormControl>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsAddRoleModalOpen(false)} color="error">
            Cancel
          </Button>
          <Button onClick={pushRole} color="primary" disabled={!newRoleName || !selectAll[0] || !addGroup || errorMessage || errorMessage2}>
            {" "}
            Add{" "}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={showConfirmation} onClose={handleCancelDelete}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <p>Are you sure you want to delete the selected role?</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteRole} color="error">
            Delete
          </Button>
          <Button onClick={handleCancelDelete} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default SettingsPage;
