import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditNoteIcon from "@mui/icons-material/EditNote";
import { Box, Stack } from "@mui/system";

import Cookies from "js-cookie";
import TableLevel from "./TableLevel";

const config = require("./../../../../configs/app");

export default function SettingLevel() {
  const [data, setData] = useState([]);
  const [warningConfig, setWarningConfig] = useState([]);

  const key = Cookies.get("pathName");

  const fetchDataOrganization = async () => {
    const res = await fetch(`${config.urlAuth}/api/v1/organization/${key}`);
    const response = await res.json();
    const data = response.message;
    setData(data);
  };

  const fetchDataWarningConfig = async () => {
    const res = await fetch(`${config.urlLightningConfig}/api/v1/warning-config/keyName/${key}`);
    const response = await res.json();
    const data = response.message;
    setWarningConfig(data);
  };

  useEffect(() => {
    fetchDataOrganization();
    fetchDataWarningConfig();
  }, []);

  return (
    <>
      {data?.keyName ? (
        <Stack spacing={2} sx={{ width: "100%" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} lg={4} mb={2}>
              <Card variant="outlined">
                <CardContent>
                  <Typography fontWeight="bold">Active Zone Information</Typography>
                  <Table size="small">
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          <Typography>Active Zone R </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography>{data.activeZoneR} m</Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Typography>Active Zone A </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography>{data.activeZoneA} m</Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Typography>Active Zone B </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography>{data.activeZoneB} m</Typography>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} md={12} lg={8} mb={3}>
              <Card variant="outlined">
                <CardContent>
                  <Typography fontWeight="bold">E-Field Sensor</Typography>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Warning Point ID</TableCell>
                        <TableCell>Location</TableCell>
                        <TableCell>Latitude</TableCell>
                        <TableCell>Longitude</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data.EFieldSensors &&
                        data.EFieldSensors.map((item) => (
                          <TableRow key={item.warningPointId}>
                            <TableCell>
                              <Typography>{item.warningPointId}</Typography>
                            </TableCell>
                            <TableCell>
                              <Typography>{item.warningPointName}</Typography>
                            </TableCell>
                            <TableCell>
                              <Typography>{item.lat}</Typography>
                            </TableCell>
                            <TableCell>
                              <Typography>{item.lon}</Typography>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </CardContent>
              </Card>
            </Grid>
          </Grid>

          <Card variant="outlined">
            <CardContent>
              <TableLevel level={3} organization={data} />
            </CardContent>
          </Card>
          <Card variant="outlined">
            <CardContent>
              <TableLevel level={2} organization={data} />
            </CardContent>
          </Card>
          <Card variant="outlined">
            <CardContent>
              <TableLevel level={1} organization={data} />
            </CardContent>
          </Card>
        </Stack>
      ) : (
        <>
          {/* loading spiner */}
          <Stack
            sx={{
              height: "50vh",
              zIndex: 9999,
            }}
            justifyContent="center"
            alignItems="center"
          >
            <Box sx={{ display: "flex" }}>
              <CircularProgress color="inherit" />
            </Box>
          </Stack>
        </>
      )}
    </>
  );
}
