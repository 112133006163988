import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { Controller, useForm } from "react-hook-form";

var numeral = require("numeral");
const config = require("./../../../../configs/app");

function converterZoneNameToNumber(zone, data) {
  if (zone === "0") {
    return 0;
  } else if (zone === "R") {
    return numeral(data.activeZoneR).format("0,0");
  } else if (zone === "A") {
    return numeral(data.activeZoneA).format("0,0");
  } else if (zone === "B") {
    return numeral(data.activeZoneB).format("0,0");
  } else {
    return zone;
  }
}

function converterZoneNameToRealNumber(zone, data) {
  if (zone === "0") {
    return 0;
  } else if (zone === "R") {
    return data.activeZoneR;
  } else if (zone === "A") {
    return data.activeZoneA;
  } else if (zone === "B") {
    return data.activeZoneB;
  } else {
    return zone;
  }
}

export default function EditSettingLevel({ fetchDataWarningConfig, onCloseModal, level, organization }) {
  const [data, setData] = React.useState([]);
  const { control, handleSubmit } = useForm();

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name.includes(".")) {
      const [outer, inner] = name.split(".");
      setData({
        ...data,
        [outer]: {
          ...data[outer],
          [inner]: value,
        },
      });
    } else {
      setData({
        ...data,
        [name]: value,
      });
    }
  };

  const handleSwitchChange = (event) => {
    const { name, checked } = event.target;
    if (name.includes(".")) {
      const [outer, inner] = name.split(".");
      setData({
        ...data,
        [outer]: {
          ...data[outer],
          [inner]: checked,
        },
      });
    } else {
      setData({
        ...data,
        [name]: checked,
      });
    }
  };

  const onSubmit = (formData) => {
    addDataToSystem(formData);
  };

  const addDataToSystem = (data) => {
    const start = converterZoneNameToRealNumber(data.activeZoneStart, organization);
    const end = converterZoneNameToRealNumber(data.activeZoneEnd, organization);

    if (data.enableActiveZone) {
      if (start === "" || end === "") {
        alert("Please select an active zone");
        return;
      }
      if (start === 0 && end === 0) {
        alert("Please select an active zone");
        return;
      }
      if (start >= end) {
        alert("Active Zone is invalid");
        return;
      }
    }

    if (data.enableMFieldType) {
      if (data.mFieldType === "0") {
        alert("Please select a lightning type");
        return;
      }
    }

    if (data.enableMFieldCounter) {
      if (data.mFieldCounterSymbol === "") {
        alert("Please select a symbol");
        return;
      }
      if (data.mFieldCounter === "") {
        alert("Please enter the number of times lightning");
        return;
      }
      if (data.mFieldCounterInTime === "") {
        alert("Please fill in in time");
        return;
      }
    }

    if (data.enableMFieldAmplitude) {
      if (data.mFieldAmplitudeSymbol === "") {
        alert("Please select a symbol");
        return;
      }
      if (data.mFieldAmplitude === "") {
        alert("Please enter lightning size");
        return;
      }
    }

    if (data.enableEFieldLevel || data.enableEFieldChange || data.enableEFieldValue) {
      if (data.warningPointId === "") {
        alert("Please select Warning Point ID");
        return;
      }
    }

    if (data.enableEFieldLevel) {
      if (data.eFieldLevel === 0) {
        alert("Please select notification level");
        return;
      }
    }

    if (data.enableEFieldChange) {
      if (data.eFieldChangeAbsolute === "") {
        alert("Please enter the electric field change");
        return;
      }
    }

    if (data.enableEFieldValue) {
      if (data.eFieldValueSymbol === "") {
        alert("Please select a symbol.");
        return;
      }
      if (data.eFieldValue === "") {
        alert("Please enter the electric field value");
        return;
      }
    }

    const reqData = {
      ...data,
      keyName: organization.keyName,
      levelWarning: level,
    };

    // console.log("Before Send Data: ", reqData);

    fetch(`${config.urlLightningConfig}/api/v1/warning-config/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(reqData),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        // console.log("Data added successfully:", data);
        fetchDataWarningConfig();
        onCloseModal();
      })
      .catch((error) => {
        console.error("Error adding data:", error);
      });
  };

  return (
    <>
      <Grid container direction="column" spacing={1}>
        <Grid item>
          <Typography fontWeight="bold">Add Warning Level {level}</Typography>
        </Grid>

        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid item mt={2}>
            <TableContainer component={Paper}>
              <Table size="small">
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <Controller
                        name="enableActiveZone"
                        control={control}
                        defaultValue={true}
                        render={({ field }) => (
                          <FormControlLabel
                            control={<Checkbox type="checkbox" size="small" {...field} defaultChecked={true} />}
                            label="Active Zone"
                            size="small"
                          />
                        )}
                      />
                    </TableCell>
                    <TableCell sx={{ width: 250 }}>
                      <Controller
                        name="activeZoneStart"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <FormControl fullWidth size="small">
                            <InputLabel>Active Zone Start</InputLabel>
                            <Select label="Active Zone Start" size="small" {...field}>
                              <MenuItem value={"0"}>Active Zone Start : 0 meter</MenuItem>
                              <MenuItem value={"R"}>
                                Active Zone R : {converterZoneNameToNumber("R", organization)} meter
                              </MenuItem>
                              <MenuItem value={"A"}>
                                Active Zone A : {converterZoneNameToNumber("A", organization)} meter
                              </MenuItem>
                              <MenuItem value={"B"}>
                                Active Zone B : {converterZoneNameToNumber("B", organization)} meter
                              </MenuItem>
                            </Select>
                          </FormControl>
                        )}
                      />
                    </TableCell>
                    <TableCell sx={{ width: 250 }}>
                      <Controller
                        name="activeZoneEnd"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <FormControl fullWidth size="small">
                            <InputLabel>Active Zone End</InputLabel>
                            <Select label="Active Zone End" size="small" {...field}>
                              <MenuItem value={"0"}>Active Zone End : 0 meter</MenuItem>
                              <MenuItem value={"R"}>
                                Active Zone R : {converterZoneNameToNumber("R", organization)} meter
                              </MenuItem>
                              <MenuItem value={"A"}>
                                Active Zone A : {converterZoneNameToNumber("A", organization)} meter
                              </MenuItem>
                              <MenuItem value={"B"}>
                                Active Zone B : {converterZoneNameToNumber("B", organization)} meter
                              </MenuItem>
                            </Select>
                          </FormControl>
                        )}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Controller
                        name="enableMFieldType"
                        control={control}
                        defaultValue={false}
                        render={({ field }) => (
                          <FormControlLabel
                            control={<Checkbox type="checkbox" size="small" {...field} />}
                            label="M-Field : Types of Lightning"
                            size="small"
                          />
                        )}
                      />
                    </TableCell>
                    <TableCell colSpan={2}>
                      <Controller
                        name="mFieldType"
                        control={control}
                        defaultValue={0}
                        render={({ field }) => (
                          <FormControl fullWidth size="small">
                            <InputLabel>Types of Lightning</InputLabel>
                            <Select label="Types of Lightning" size="small" {...field}>
                              <MenuItem value={0}>-</MenuItem>
                              <MenuItem value={1}>Cloud to Ground</MenuItem>
                              <MenuItem value={2}>Cloud to Cloud</MenuItem>
                            </Select>
                          </FormControl>
                        )}
                      />
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>
                      <Controller
                        name="enableMFieldCounter"
                        control={control}
                        defaultValue={false}
                        render={({ field }) => (
                          <FormControlLabel
                            control={<Checkbox type="checkbox" size="small" {...field} />}
                            label="M-Field : Counter of times lightning"
                            size="small"
                          />
                        )}
                      />
                    </TableCell>
                    <TableCell sx={{ width: 250 }}>
                      <Controller
                        name="mFieldCounterSymbol"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <FormControl fullWidth size="small">
                            <InputLabel>symbol</InputLabel>
                            <Select label="symbol" size="small" {...field}>
                              <MenuItem value={""}>-</MenuItem>
                              <MenuItem value={"<="}>{"<="} : less than or equal to</MenuItem>
                              <MenuItem value={">="}>{">="} : greater than or equal to</MenuItem>
                            </Select>
                          </FormControl>
                        )}
                      />
                    </TableCell>
                    <TableCell sx={{ width: 250 }}>
                      <Controller
                        name="mFieldCounter"
                        control={control}
                        defaultValue={0}
                        render={({ field }) => (
                          <FormControl fullWidth size="small">
                            <TextField
                              type="number"
                              label="Counter of times lightning"
                              variant="outlined"
                              size="small"
                              {...field}
                            />
                          </FormControl>
                        )}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell />
                    <TableCell />
                    <TableCell>
                      <Controller
                        name="mFieldCounterInTime"
                        control={control}
                        defaultValue={0}
                        render={({ field }) => (
                          <FormControl fullWidth size="small">
                            <TextField
                              type="number"
                              label="in time (minutes)"
                              variant="outlined"
                              size="small"
                              {...field}
                            />
                          </FormControl>
                        )}
                      />
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>
                      <Controller
                        name="enableMFieldAmplitude"
                        control={control}
                        defaultValue={false}
                        render={({ field }) => (
                          <FormControlLabel
                            control={<Checkbox type="checkbox" size="small" {...field} />}
                            label="M-Field : Amplitude"
                            size="small"
                          />
                        )}
                      />
                    </TableCell>
                    <TableCell sx={{ width: 250 }}>
                      <Controller
                        name="mFieldAmplitudeSymbol"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <FormControl fullWidth size="small">
                            <InputLabel>symbol</InputLabel>
                            <Select label="symbol" size="small" {...field}>
                              <MenuItem value={""}>-</MenuItem>
                              <MenuItem value={"<="}>{"<="} : less than or equal to</MenuItem>
                              <MenuItem value={">="}>{">="} : greater than or equal to</MenuItem>
                            </Select>
                          </FormControl>
                        )}
                      />
                    </TableCell>
                    <TableCell sx={{ width: 250 }}>
                      <Controller
                        name="mFieldAmplitude"
                        control={control}
                        defaultValue={0}
                        render={({ field }) => (
                          <FormControl fullWidth size="small">
                            <TextField
                              label="Amplitude (kA)"
                              variant="outlined"
                              size="small"
                              type="number"
                              step="0.1"
                              {...field}
                            />
                          </FormControl>
                        )}
                      />
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>
                      <Typography>E-Field : E-Field Sensor</Typography>
                    </TableCell>
                    <TableCell colSpan={4}>
                      <Controller
                        name="warningPointId"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <FormControl fullWidth size="small">
                            <InputLabel>Warning Point ID</InputLabel>
                            <Select label="Warning Point ID" size="small" {...field}>
                              <MenuItem value={""}>-</MenuItem>
                              {organization.EFieldSensors.map((sensor) => (
                                <MenuItem value={sensor.warningPointId} key={sensor.warningPointId}>
                                  {sensor.warningPointId} Latitude: {sensor.lat} Longitude: {sensor.lon}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        )}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Controller
                        name="enableEFieldLevel"
                        control={control}
                        defaultValue={false}
                        render={({ field }) => (
                          <FormControlLabel
                            control={<Checkbox type="checkbox" size="small" {...field} />}
                            label="E-Field : Level"
                            size="small"
                          />
                        )}
                      />
                    </TableCell>
                    <TableCell colSpan={4}>
                      <Controller
                        name="eFieldLevel"
                        control={control}
                        defaultValue={0}
                        render={({ field }) => (
                          <FormControl fullWidth size="small">
                            <InputLabel>Level</InputLabel>
                            <Select label="Level" size="small" {...field}>
                              <MenuItem value={0}>-</MenuItem>
                              <MenuItem value={1}>1</MenuItem>
                              <MenuItem value={2}>2</MenuItem>
                              <MenuItem value={3}>3</MenuItem>
                            </Select>
                          </FormControl>
                        )}
                      />
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>
                      <Controller
                        name="enableEFieldChange"
                        control={control}
                        defaultValue={false}
                        render={({ field }) => (
                          <FormControlLabel
                            control={<Checkbox type="checkbox" size="small" {...field} />}
                            label="E-Field : Change in the electric field"
                            size="small"
                          />
                        )}
                      />
                    </TableCell>
                    <TableCell colSpan={4}>
                      <Controller
                        name="eFieldChangeAbsolute"
                        control={control}
                        defaultValue={0}
                        render={({ field }) => (
                          <FormControl fullWidth size="small">
                            <TextField
                              label="Change in the electric field value (V/m)"
                              variant="outlined"
                              size="small"
                              type="number"
                              step="0.1"
                              {...field}
                            />
                          </FormControl>
                        )}
                      />
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>
                      <Controller
                        name="enableEFieldValue"
                        control={control}
                        defaultValue={false}
                        render={({ field }) => (
                          <FormControlLabel
                            control={<Checkbox type="checkbox" size="small" {...field} />}
                            label="E-Field : greater than the electric field (absolute)"
                            size="small"
                          />
                        )}
                      />
                    </TableCell>
                    <TableCell colSpan={4}>
                      <Controller
                        name="eFieldValue"
                        control={control}
                        defaultValue={0}
                        render={({ field }) => (
                          <FormControl fullWidth size="small">
                            <TextField
                              label="greater than the electric field (absolute) (V/m)"
                              variant="outlined"
                              size="small"
                              type="number"
                              step="0.1"
                              {...field}
                            />
                          </FormControl>
                        )}
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>

            <Grid container justifyContent="flex-end" mt={2}>
              <Grid item>
                <Button variant="outlined" color="error" onClick={onCloseModal}>
                  Cancel
                </Button>

                <Button variant="contained" color="success" sx={{ marginLeft: 2 }} type="submit">
                  Save
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </>
  );
}
