import "../navigation/navigation-css/LoginPage.css";
import React, { useEffect, useState } from "react";
import Cookies from 'js-cookie';
import apiUrls from "../ApiUrl";
import config from "../configs/app"
// Cookies.remove('accessToken');
export default function LoginPage() {
  const [username, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [onInput, setOnInput] = useState('');
  const [error, setError] = useState(false);

  // const LoginUrl = apiUrls.loginUrl;
  // const config = require(".././configs/app");

  async function loginUser(credentials) {
    return fetch(`${config.urlAuth}/api/v1/auth/login`, {
      method: "POST",
      headers: { "Content-Type": "application/json", },
      // credentials: 'include',
      // sameSite: "none",
      body: JSON.stringify(credentials)
    })
      .then((response) => response.json())
      .catch(error => { setError(true) });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await loginUser({
      "emailUsername": username,
      "password": password,
    });

    if (response !== undefined) {
      if (response.userData && response.accessToken && response.userData.activeStatus === true) {
        setError(false);
        Cookies.set('accessToken', response.accessToken, { expires: 1 });
        window.location.href = "/HomePage";
      }
      else if (response.userData && response.accessToken && response.userData.activeStatus === false) {
        setError(true);
        setOnInput('ไม่สามารถเข้าใช้งานได้ โปรดติดต่อเจ้าหน้าที่');
      }
      else if (username.trim().length === 0) {
        setOnInput('Please enter username and password');
        setError(true);

      }
      else if (password.trim().length === 0) {
        setError(true);
        setOnInput('Please enter username and password');
      }
      else {
        setError(true);
        setOnInput('username or password incorrect');
      }
    }
    else {
      setError(true);
      setOnInput('Connection failure.');
    }
  };

  useEffect(() => {
    if (!Cookies.get('accessToken')) {
      if (window.location.pathname !== "/") {
        window.location.href = "/";
      }
    }
  }, []);

  return (
    <form className="body" onSubmit={handleSubmit}>
      <div className="Login"  >
        <div className="Logo" align="center">
          <img src="/image/Logo-Kumwell.png" width="250px" height="auto" alt="logo" />
          <p className="SubName">Lightning Warning System</p>
        </div>
        <section className="flex">
          <div className="Login-form" >
            <p>USERNAME OR EMAIL</p>
            <input
              type="text"
              value={username || ''}
              id="username"
              onChange={(e) => setUserName(e.target.value)}
            // required
            />

            <p>PASSWORD</p>
            <input
              type="password"
              value={password || ''}
              id="password"
              onChange={(e) => setPassword(e.target.value)}
            // required
            />
            {error ? <><div className="wrong-message">{onInput}</div></> : <></>}

            <div align="center">
              <button type="Submit" className="btn-login" >
                Sign In
              </button>
            </div>
          </div>
        </section>
      </div>

    </form>
  );
};
