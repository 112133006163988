import React, { createContext, useContext, useEffect, useState } from "react";
import {
    Typography,
    Button,
    Table,

    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Switch,
    IconButton,
    MenuItem,
    Select,
    InputLabel,
    FormControl,
    Snackbar,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    FormControlLabel,
    Checkbox,

} from "@mui/material";
import '../Routers.css';
import Cookies from "js-cookie";
import Swal from "sweetalert2";
import apiUrls from "../../ApiUrl";
import { DeviceContext } from "./DeviceStatus";
import DaySelector from "./DaySelect";

const UpdateDeviceContext = createContext();
export default function UpdateDevice({ edit }) {
    const [onHole, setOnHole] = useState(true);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const [onFetch, setOnFetch] = useState(false);
    const [activeDay, setActiveDay] = useState("");
    const [activeItem, setActiveItem] = useState(1);
    const { setDeviceOption, setFormData, formData, setRetryCount, retryCount, formDataCheck, items } = useContext(DeviceContext);
    const token = Cookies.get("accessToken");

    const handleModalClick = (e) => {
        e.stopPropagation();
        setOnHole(true);
    };

    const closeModal = () => {
        if (onHole !== false) {
            setDeviceOption(false);
        }
        setOnHole(true);
    };

    const closeModals = () => {
        setDeviceOption(false);
    };


    const Next = () => {
        const areAllInputsFilled = Object.values(formData).every((input) => input !== "");
        if (areAllInputsFilled) {
            setActiveItem(activeItem + 1);
        }
        else {
            setError(true);
        }
    }

    const CheckSubmit = () => {
        let keysExist = false;
        const areAllInputsFilled = Object.values(formData).every((input) => input !== "");
        if (areAllInputsFilled && errorMessage === false) {

            Object.keys(formData).forEach((key) => {
                if (formData[key] === formDataCheck[key]) {
                }
                else if (error != "Password-Fail") {
                    keysExist = true;
                }
            })
            if (keysExist) { handleSubmit(); }
            else {
                console.log(keysExist);
                setError("No Edit");
            }
        }
        else {
            setError(true);
        }
    }

    const handleSubmit = async () => {
        setOnFetch(true);
        if (edit) { apiUrls.setEfieldRequest(formDataCheck.warningPointId); }
        else { apiUrls.setEfieldRequest(''); }
        const options = {
            method: `${edit ? "PUT" : "POST"}`,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(formData),
        };
        try {
            const response = await fetch(apiUrls.Efield, options);
            console.log(apiUrls.Efield)
            console.log(options)
            if (response.ok) {
                Swal.fire({
                    icon: "success",
                    title: `${edit ? "Edited" : "Created"} Successful!`,
                    text: `Data has been ${edit ? "Edited" : "Created"}`,
                });
                setRetryCount(retryCount + 1);
                setOnFetch(false);
                closeModals();
            } else {
                Swal.fire({
                    icon: "error",
                    title: `${edit ? "Edited" : "Created"} Failed`,
                    text: "Something went wrong!",
                });
                setOnFetch(false);

            }
        } catch (error) {
            console.error("Error inserting data:", error);
            Swal.fire({
                icon: "error",
                title: "Insert Failed",
                text: "Something went wrong!",
            });
            setOnFetch(false);
        }
    };

    const componentMapping = {
        1: DeviceData,
        2: EnableTimeActive,

    };

    const ActiveComponent = componentMapping[activeItem] || null;

    return (
        <UpdateDeviceContext.Provider value={{ activeDay, setActiveDay, setError }}>
            <div className="overlay" onClick={closeModal}>
                <div className="bodyModal" >
                    <div
                        className="modals-organization"
                        onClick={handleModalClick}
                        onMouseDown={() => setOnHole(false)}
                        onMouseUp={() => setOnHole(false)}
                    >
                        <div className="menu-bar-addOrganization">{edit ? "แก้ไข" : "เพิ่ม"} IOT Device</div>
                        <nav className="status-sidebar-addOrganization" align="center">
                            <div className={activeItem === 1 ? "active" : ""}>ข้อมูลอุปกรณ์ IOT</div>
                            <div className={activeItem === 2 ? "active" : ""}>การทำงานอุปกรณ์ IOT</div>
                        </nav>

                        <div className="Router-addOrganization" autoComplete="off" style={{ maxHeight: "500px", overflow: "hidden", overflowY: "auto" }}>
                            {ActiveComponent && <ActiveComponent />}

                            {error === true && (
                                <div className="alert-input" align="center">กรุณาป้อนข้อมูลให้ครบ </div>)
                                || error === "No Edit" && (
                                    <div className="alert-input" align="center">ข้อมูลยังไม่มีการเปลี่ยนแปลง </div>
                                )}
                        </div>

                        <div className="menu-bar-addOrganization">
                            {activeItem > 1 ? <button className="btn-addOrg close" onClick={() => setActiveItem(1)}> Back </button>
                                : <button className="btn-addOrg close" onClick={closeModals}> Close </button>}
                            {activeItem > 1 || formData.activeDay === false ?
                                <button className="btn-addOrg next" align="center"     >
                                    {onFetch ? <span className="loader2"></span> : "SUBMIT"}
                                </button> :
                                <button className="btn-addOrg next" onClick={Next}>Next</button>
                            }

                            {/* // onClick={CheckSubmit}
                                // disabled={onFetch} */}
                        </div>
                    </div>
                </div>
            </div>
        </UpdateDeviceContext.Provider>
    )
}
export { UpdateDeviceContext }

function DeviceData() {


    const [daysOfWeek, setDaysOfWeek] = useState({
        Monday: false,
        Tuesday: false,
        Wednesday: false,
        Thursday: false,
        Friday: false,
        Saturday: false,
        Sunday: false,
    });

    const handleCheckboxChange = (event) => {
        setDaysOfWeek({ ...daysOfWeek, [event.target.name]: event.target.checked });
    };

    const { setError } = useContext(UpdateDeviceContext);
    const { setFormData, formData } = useContext(DeviceContext);
    const [selectedDays, setSelectedDays] = useState([]);
    const [item, setItem] = useState([]);
    const [activeDayUse, setActiveDayUse] = useState("");
    const token = Cookies.get("accessToken");

    const fetchKeyName = async () => {
        try {
            const response = await fetch(apiUrls.OrganizationUrl_GET, {
                method: "GET",
                headers: { Authorization: `Bearer ${token}` },
            });
            if (!response.ok) { throw new Error("Failed to fetch data"); }
            const data = await response.json();
            setItem(data);
        } catch (error) { console.error("Error fetching data:", error); }
    };

    const handleDayChange = (event) => {
        const { value } = event.target;
        if (selectedDays.includes(value)) {
            setSelectedDays(selectedDays.filter((day) => day !== value));
        } else {
            setSelectedDays([...selectedDays, value]);
        }
    };

    const calculateActiveDay = (selectedDays) => {
        const activeDayArr = new Array(7).fill(0);
        selectedDays.forEach((day) => {
            const index = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].indexOf(day);
            if (index !== -1) {
                activeDayArr[index] = 1;
            }
        });
        return activeDayArr.join('');
    };

    console.log(formData)

    const handleChange = (event) => {
        const { name, value } = event.target;
        setError(false);
        let parsedValue;
        if (name === "manualOff" || name === "statusSimulation") {
            if (value === "true" || value === true) {
                parsedValue = true;
            } else if (value === "false" || value === false) {
                parsedValue = false;
            } else {
                parsedValue = value;
            }
        } else if (name === "keyName" && value != "") {
            const Found = item.message.find(item => item.keyName === value);
            if (Found) {
                setFormData((prevFormData) => ({
                    ...prevFormData,
                    ["lat"]: Found.lat,
                }));
                setFormData((prevFormData) => ({
                    ...prevFormData,
                    ["lon"]: Found.lon,
                }));
            }
            parsedValue = Found.keyName;
        }
        else { parsedValue = value; }

        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: parsedValue,
        }));
    }

    useEffect(() => {
        fetchKeyName();
    }, []);

    useEffect(() => {
        const activeDayValue = calculateActiveDay(selectedDays);
        setFormData((prevFormData) => ({
            ...prevFormData,
            activeDay: activeDayUse === 'false' ? '0000000' : activeDayValue,
        }));
    }, [selectedDays, activeDayUse]);

    return (
        <>
            <p>ข้อมูล อุปกรณ์ IOT</p>
            <div className="input-oneline " align="right">
                <span>Organization : </span>
                <select
                    name="keyName"
                    onChange={handleChange}
                    value={formData.keyName || ""}
                >
                    <option value={""}></option>
                    {item.message && (
                        <>
                            {item.message.map((items, index) => (
                                <option value={items.keyName} key={index}>
                                    {items.keyName}
                                </option>
                            ))}
                        </>
                    )}
                </select>
            </div>

            <div className="input-oneline" align="right" >
                <span>Device Name : </span>
                <input
                    onChange={handleChange}
                    value={formData.nameDevice || ""}
                    type="text"
                    name="nameDevice"
                    placeholder=""
                />
            </div>

            <div className="input-oneline" align="right" >
                <span>Line Token : </span>
                <input
                    onChange={handleChange}
                    value={formData.lineToken || ""}
                    type="text"
                    name="lineToken"
                    placeholder=""
                />
            </div>

            <span>{(activeDayUse !== "false") && (activeDayUse != "") && (
                <DaySelector selectedDays={selectedDays} name="activeDay" onChange={handleDayChange
                } />
            )}
            </span>

            <div className="input-oneline" align="right" >
                <span>Manual Off : </span>
                <select name="manualOff" value={formData.manualOff} onChange={handleChange}
                >
                    <option value={""}></option>
                    <option value={true}>Enable</option>
                    <option value={false}>Disable</option>
                </select>
            </div>

            <div className="input-oneline" align="right" >
                <span>Mode : </span>
                <select name="statusSimulation" value={formData.statusSimulation} onChange={handleChange}
                >
                    <option value={""}></option>
                    <option value={true}>Simulate mode</option>
                    <option value={false}>Normal mode</option>
                </select>
            </div>
        </>
    )
}

function EnableTimeActive() {
    const [switchValues, setSwitchValues] = useState("0000000");
    const { setFormData, formData } = useContext(DeviceContext);
    const [open, setOpen] = useState(false);
    const channelData = [
        { value: 1, label: 'CH1' },
        { value: 2, label: 'CH2' },
        { value: 3, label: 'CH3' },
        { value: 4, label: 'CH4' },
        { value: 5, label: 'CH5' },
        { value: 6, label: 'CH6' },
        { value: 7, label: 'CH7' },
        { value: 8, label: 'CH8' },
    ];

    const [delayOn, setDelayOn] = useState({});
    const [delayOff, setDelayOff] = useState({});

    const handleDelayOn = (channel) => (event) => {
        const delayOnValue = event.target.checked;
        setDelayOn((prevDelayOn) => ({
            ...prevDelayOn,
            [channel]: delayOnValue,
        }));

        setFormData((prevFormData) => ({
            ...prevFormData,
            relay: prevFormData.relay.map((item) =>
                item.channel === channel ? { ...item, delayOn: delayOnValue ? 1 : 0 } : item
            ),
        }));
    };

    const handleDelayOff = (channel) => (event) => {
        const delayOffValue = event.target.checked;
        setDelayOff((prevDelayOff) => ({
            ...prevDelayOff,
            [channel]: delayOffValue,
        }));

        setFormData((prevFormData) => ({
            ...prevFormData,
            relay: prevFormData.relay.map((item) =>
                item.channel === channel ? { ...item, delayOff: delayOffValue ? 1 : 0 } : item
            ),
        }));
    };

    console.log(formData)
    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleActiveTimeStartChange = (event) => {
        const newActiveTimeStart = event.target.value;
        setFormData((prevFormData) => ({
            ...prevFormData,
            activeTimeStart: newActiveTimeStart,
        }));
    };

    const handleActiveTimeEndChange = (event) => {
        const newActiveTimeEnd = event.target.value;
        setFormData((prevFormData) => ({
            ...prevFormData,
            activeTimeEnd: newActiveTimeEnd,
        }));
    };

    const handleSwitchChange = (index) => (event) => {
        const updatedSwitchValues = switchValues.split("");
        updatedSwitchValues[index] = event.target.checked ? "1" : "0";
        setSwitchValues(updatedSwitchValues.join(""));
    };


    const textFieldInputProps = {
        style: {
            height: "10px",
        },
    };

    const options = [
        1,
        2,
        3,
    ];

    const [selectedLevels, setSelectedLevels] = useState({});
    const handleChangeSingle = (index) => (event) => {
        const checked = event.target.checked;
        const channel = channelData[index].value;
        if (checked) {
            setFormData((prevFormData) => ({
                ...prevFormData,
                relay: [
                    ...prevFormData.relay,
                    {
                        channel: channel,

                        enableActiveTime: false,
                        level: selectedLevels[channel] || options[0],
                    },
                ],
            }));
        } else {
            setFormData((prevFormData) => ({
                ...prevFormData,
                relay: prevFormData.relay.filter((item) => item.channel !== channel),
            }));
            setSelectedLevels((prevSelectedLevels) => {
                const updatedSelectedLevels = { ...prevSelectedLevels };
                delete updatedSelectedLevels[channel];
                return updatedSelectedLevels;
            });
        }
    };

    const handleSelectorChange = (channel) => (event) => {
        const level = event.target.value;
        setSelectedLevels((prevSelectedLevels) => ({
            ...prevSelectedLevels,
            [channel]: level,
        }));

        setFormData((prevFormData) => ({
            ...prevFormData,
            relay: prevFormData.relay.map((item) =>
                item.channel === channel ? { ...item, level: level } : item
            ),
        }));
    };

    return (
        <>
            <Typography variant="h6" gutterBottom>
                ActiveDay
            </Typography>
            <Table size="small">
                <TableHead>
                    <TableRow style={{ fontSize: "11px" }}>
                        <TableCell align="center">Monday</TableCell>
                        <TableCell align="center">Tuesday</TableCell>
                        <TableCell align="center">Wednesday</TableCell>
                        <TableCell align="center">Thursday</TableCell>
                        <TableCell align="center">Friday</TableCell>
                        <TableCell align="center">Saturday</TableCell>
                        <TableCell align="center">Sunday</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {switchValues.split("").map((value, index) => (
                        <TableCell key={index}>
                            <Switch checked={value === "1"} onChange={handleSwitchChange(index)} />
                        </TableCell>
                    ))}
                </TableBody>
            </Table>
            <div style={{ marginTop: "30px" }}>
                <TextField
                    label="Active Time Start"
                    type="time"
                    value={formData.activeTimeStart}
                    onChange={handleActiveTimeStartChange}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    inputProps={textFieldInputProps}
                    sx={{ marginRight: "20px" }}
                />
                <TextField
                    label="Active Time End"
                    type="time"
                    value={formData.activeTimeEnd}
                    onChange={handleActiveTimeEndChange}
                    inputProps={textFieldInputProps}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                <Button variant="contained" color="primary" onClick={handleOpen}
                    style={{ marginTop: "20px" }}>
                    คำสั่งการทำงาน
                </Button>

                <Dialog open={open} onClose={handleClose} PaperProps={{
                    style: {
                        minWidth: '1000px',
                        margin: '50px',
                    },
                }}>
                    <DialogTitle> คำสั่งการทำงาน</DialogTitle>
                    <DialogContent>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell>CH1</TableCell>
                                    <TableCell>CH2</TableCell>
                                    <TableCell>CH3</TableCell>
                                    <TableCell>CH4</TableCell>
                                    <TableCell>CH5</TableCell>
                                    <TableCell>CH6</TableCell>
                                    <TableCell>CH7</TableCell>
                                    <TableCell>CH8</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell>
                                        Enable Active Time
                                    </TableCell>
                                    {channelData.map((channel, index) => (
                                        <TableCell key={index}>
                                            <Checkbox
                                                checked={
                                                    formData.relay.some((item) => item.channel === channel.value)
                                                }
                                                onChange={handleChangeSingle(index)}
                                            />
                                        </TableCell>
                                    ))}
                                </TableRow>
                                <TableRow >
                                    <TableCell sx={{ border: "none" }}>Level</TableCell>
                                    {channelData.map((channel) => (
                                        <TableCell key={channel.value} sx={{ border: "none" }}>
                                            <Select
                                                value={selectedLevels[channel.value]}
                                                onChange={handleSelectorChange(channel.value)}
                                                sx={{
                                                    width: '55x',
                                                    height: "30px",
                                                    borderRadius: '4px',
                                                    fontSize: '14px',
                                                }}
                                            >
                                                {options.map((option, index) => (
                                                    <MenuItem key={index} value={option}>
                                                        {option}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </TableCell>
                                    ))}
                                </TableRow >
                                <TableCell sx={{ border: "none" }}>
                                    Relay ON
                                </TableCell >

                                {channelData.map((channel) => (<TableCell sx={{ border: "none" }}>
                                    <Switch
                                        checked={delayOn[channel.value] || false}
                                        onChange={handleDelayOn(channel.value)}
                                    />
                                </TableCell>
                                ))}
                                <TableRow>
                                </TableRow >
                                <TableRow>
                                    <TableCell>
                                        Relay OFF
                                    </TableCell>
                                    {channelData.map((channel) => (<TableCell >
                                        <Switch
                                            checked={delayOff[channel.value] || false}
                                            onChange={handleDelayOff(channel.value)}
                                        />
                                    </TableCell>))}
                                </TableRow>
                            </TableBody>
                        </Table>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Close</Button>
                    </DialogActions>
                </Dialog>
            </div>
        </>
    )
}