import React, { createContext, useContext, useEffect, useState } from "react";
import "../Routers.css";
import DataTable from "react-data-table-component";
import Cookies from "js-cookie";
import Container from "../Container";
import ConfirmDelete from "../ConfirmDelete";
import AddUser from "./AddUser";
import apiUrls from "../../ApiUrl";
import { showSidebar } from "../../kumwell";
import { customStyles } from "../DataTableStyle";
import Swal from "sweetalert2";
import EditUser from "./EditUser";
const userContext = createContext();

export default function UserSetting({ create, update, remove, role, superAdmin }) {
    const [pending, setPending] = useState(true);
    const [showComponent, setShowComponent] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState([]);
    const [retryCount, setRetryCount] = useState(0);
    const [errorFetch, setErrorFetch] = useState("");
    const [searchQuery, setSearchQuery] = useState("");
    const [showDelete, setShowDelete] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [key, setKey] = useState("");
    const [key2, setKey2] = useState("");
    const token = Cookies.get("accessToken");
    const [onFetch, setOnFetch] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState("");
    const [confirmpassword, setconfirmPassword] = useState(false);
    const [errorEmail, setErrorEmail] = useState(false);
    const [errorUsername, setErrorUsername] = useState(false);
    const { auth } = useContext(showSidebar);
    const [error, setError] = useState(false);

    const [userData, setUserData] = useState({
        username: "",
        email: "",
        password: "",
        firstName: "",
        lastName: "",
        phoneNumber: "",
        viewEField: "",
        activeStatus: "",
        RoleId: "",
    });

    const [EditUserData, setEditUserData] = useState({
        keyName: auth.keyName,
        username: auth.username,
        email: auth.email,
        firstName: auth.firstName,
        lastName: auth.lastName,
        phoneNumber: auth.phoneNumber,
        viewEField: auth.viewEField,
        activeStatus: auth.activeStatus,
        RoleId: auth.Role.roleId,
        userId: auth.Role.userId,
    });

    const [EditUserDataCheck, setEditUserDataCheck] = useState({
        keyName: auth.keyName,
        username: auth.username,
        email: auth.email,
        firstName: auth.firstName,
        lastName: auth.lastName,
        phoneNumber: auth.phoneNumber,
        viewEField: auth.viewEField,
        activeStatus: auth.activeStatus,
        RoleId: auth.Role.roleId,
        userId: auth.Role.userId,
        // "lineToken": auth.lineToken
    });

    const openDelete = (request, request2) => {
        setKey(request);
        setKey2(request2);
        setShowDelete(true);
    };

    const closeModals = () => {
        setShowDelete(false);
    };

    const showAddUser = () => {
        setError(false)
        setShowComponent(!showComponent);
    };

    const fetchData = () => {
        fetch(apiUrls.user_GET, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.json())
            .then((data) => {
                if (data.status === 200) {
                    setIsLoaded(true);
                    setItems(data);
                } else {
                    setIsLoaded(false);
                    console.log(data);
                }
            })
            .catch((error) => {
                setIsLoaded(false);
                setErrorFetch(error);
                const retryDelay = 3000;
                setTimeout(() => setRetryCount(retryCount + 1), retryDelay);
            });
    };

    async function handleDelete(request, request2) {
        try {
            apiUrls.setUserRequest3(request);
            apiUrls.setUserRequest4(request2);
            setDeleteLoading(true);
            const response = await fetch(apiUrls.user_Request, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            });
            if (response.ok) {
                Swal.fire({
                    icon: "success",
                    title: "Delete Successful!",
                    text: "user has been Deleted",
                });
                fetchData();
            } else {
                Swal.fire({
                    icon: "error",
                    title: "Delete Failed",
                    text: "something went wrong!",
                });
                console.error("Failed to delete item:", response.status);
            }
        } catch (error) {
            console.error("Failed to delete item:", error);
        } finally {
            setShowDelete(false);
            setDeleteLoading(false);
        }
    }

    useEffect(() => {
        if (role) {
            apiUrls.setUserRequest1(auth.keyName);
            fetchData();
        } else {
            fetchData();
        }
    }, [retryCount]);

    const columns = [
        {
            name: "#",
            selector: (row) => row.id,
            sortable: true,
            width: "57px",
        },
        {
            name: "Name",
            selector: (row) => (
                <>
                    {row.firstName} {row.lastName}
                </>
            ),
            sortable: true,
            width: "150px",
        },
        {
            name: "Username",
            selector: (row) => row.username,
            width: "150px",
        },
        {
            name: "Email",
            selector: (row) => row.email,
            width: "200px",
        },

        {
            name: "Role",
            cell: (row) => <>{row.Role ? <>{row.Role.name}</> : <></>}</>,
            width: "170px",
        },

        {
            name: "Status",
            cell: (row) => (<> {row.activeStatus === true ? <span style={{ color: "green" }}>Active</span> : <span style={{ color: "red" }}>Inactive</span>}</>),
            width: "auto",
        },
    ];
    if (update) {
        columns.push(
            // {
            //     name: 'แก้ไขรหัสผ่าน',
            //     cell: () => (<><button className="setting-btn">แก้ไขรหัสผ่าน</button></>),
            //     ignoreRowClick: true,
            //     allowOverflow: true,
            //     button: true,
            //     width: '120px',
            // },
            {
                name: "",
                cell: (row) => (
                    <>
                        <button
                            className="edit-btn"
                            onClick={() => {
                                setErrorEmail(false);
                                setErrorUsername(false);
                                setError(false);
                                setShowEdit(true);
                                setEditUserData({
                                    keyName: row.keyName,
                                    username: row.username,
                                    email: row.email,
                                    firstName: row.firstName,
                                    lastName: row.lastName,
                                    phoneNumber: row.phoneNumber,
                                    viewEField: row.viewEField,
                                    activeStatus: row.activeStatus,
                                    RoleId: row.Role ? row.Role.roleId : "",
                                    userId: row.userId,
                                });

                                setEditUserDataCheck({
                                    keyName: row.keyName,
                                    username: row.username,
                                    email: row.email,
                                    firstName: row.firstName,
                                    lastName: row.lastName,
                                    phoneNumber: row.phoneNumber,
                                    viewEField: row.viewEField,
                                    activeStatus: row.activeStatus,
                                    RoleId: row.Role ? row.Role.roleId : "",
                                    userId: row.userId,
                                });
                            }}
                        >
                            Edit
                        </button>
                    </>
                ),
                ignoreRowClick: true,
                allowOverflow: true,
                button: true,
                width: "90px",
            }
        );
    }

    if (remove) {
        columns.push({
            name: "",
            cell: (row) => (
                <>
                    <button
                        className="delete-btn"
                        onClick={() => openDelete(row.keyName, row.userId)}
                    >
                        Delete
                    </button>
                </>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            width: "90px",
        });
    }

    const CustomLoader = () => (
        <>
            <div style={{ padding: "40px" }}>
                <span className="loader"></span>
                <p>{errorFetch.message}</p>
            </div>
        </>
    );

    if (token != null && isLoaded === true) {
        const data = items.message;
        const dataWithIds = data.map((item, index) => ({ id: index + 1, ...item }));
        const filteredData = dataWithIds.filter((item) => {
            const { username, firstName, lastName } = item;
            const searchValue = searchQuery.toLowerCase();
            return (
                username.toLowerCase().includes(searchValue) ||
                firstName.toLowerCase().includes(searchValue) ||
                lastName.toLowerCase().includes(searchValue)
            );
        });
        return (
            <userContext.Provider
                value={{
                    retryCount,
                    setRetryCount,
                    EditUserDataCheck,
                    EditUserData,
                    setEditUserData,
                    showEdit,
                    setShowEdit,
                    showComponent,
                    setShowComponent,
                    userData,
                    setUserData,
                    setError,
                    error,
                    confirmPassword,
                    setConfirmPassword,
                    confirmpassword,
                    setconfirmPassword,
                    onFetch,
                    setOnFetch, errorUsername, setErrorUsername, errorEmail, setErrorEmail
                }}
            >
                {showComponent && <AddUser superAdmin={superAdmin} />}
                <Container
                    title={<>Users</>}
                    btn={create ? <>Add User</> : null}
                    action={showAddUser}
                    components={
                        <>
                            {showEdit ? <EditUser superAdmin={superAdmin} /> : <></>}
                            {showDelete ? (
                                <ConfirmDelete
                                    topic="User"
                                    closeModals={closeModals}
                                    confirm={() => handleDelete(key, key2)}
                                    loadding={deleteLoading}
                                />
                            ) : (
                                <></>
                            )}
                            <input
                                className="Search"
                                type="text"
                                onChange={(e) => setSearchQuery(e.target.value)}
                                placeholder="Search"
                            />
                            <DataTable
                                columns={columns}
                                data={filteredData}
                                pagination
                                fixedHeader
                                customStyles={customStyles}
                                highlightOnHover
                                noHeader
                                striped
                            />
                        </>
                    }
                />
            </userContext.Provider>
        );
    }
    else {
        return (
            <Container
                title={<>Users</>}
                components={
                    <DataTable
                        progressPending={pending}
                        progressComponent={<CustomLoader />}
                    />
                }
            ></Container>
        );
    }
}

export { userContext };
