import React from "react";
import { Button, Grid, Typography } from "@mui/material";

const config = require("./../../../../configs/app");

const Delete = ({ settingId, fetchDataWarningConfig, onCloseModal, setting }) => {
  const handleDelete = async () => {
    const response = await fetch(`${config.urlLightningConfig}/api/v1/warning-config/${settingId}`, {
      method: "DELETE",
    })
      .then((res) => res)
      .catch((error) => {
        console.error("There has been a problem with your fetch operation:", error.message);
      });

    if (response.status === 204) {
      // console.log("ลบข้อมูลสำเร็จ");
      fetchDataWarningConfig();
      onCloseModal(settingId);
    } else {
      console.error("Error:", response?.status);
    }
  };

  const handleClose = () => {
    onCloseModal();
  };

  return (
    <>
      <Typography fontWeight="bold">Confirm Delete Warning Level {setting.levelWarning}</Typography>
      <Grid item xs={6} display="flex" justifyContent="center" mb={2}>
        <Typography>
          You Want to delete notification level data? {setting.levelWarning} No. {setting.groupWarning} or not?
        </Typography>
        <Typography></Typography>
      </Grid>
      <Grid item xs={6} display="flex" justifyContent="space-between" sx={{ gap: 2 }}>
        <Button variant="contained" color="inherit" onClick={handleClose} fullWidth>
          CANCEL
        </Button>
        <Button variant="contained" color="error" onClick={handleDelete} fullWidth>
          DELETE
        </Button>
      </Grid>
    </>
  );
};

export default Delete;
