import React, { useState, useEffect, useRef } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Pagination,
  Typography,
  Button,
  Grid,
  Paper,
  InputBase,
  IconButton,
  Modal,
  Box,
  CircularProgress,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { Search } from "@mui/icons-material";
import Cookies from "js-cookie";
import * as XLSX from "xlsx";
import { PDFDownloadLink } from "@react-pdf/renderer";
import PDFDocument from "./PDFDocument";

const config = require("../../../configs/app");

const changeStatus = (status) => {
  if (status === "1" || status === 1) {
    return "สำเร็จ";
  } else if (status === "2" || status === 2) {
    return "ไม่เข้าเงื่อนไข";
  } else {
    return "ผิดพลาด";
  }
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 3,
  p: 4,
};

export default function Report({ user }) {
  const currentDate = dayjs();
  const [startDate, setStartDate] = useState(currentDate);
  const [endDate, setEndDate] = useState(currentDate);
  const [dataToPdf, setDataToPdf] = useState([]);
  const [dataShow, setDataShow] = useState([]);
  const pdfLinkRef = useRef();
  const keyName = Cookies.get("pathName");

  const start = startDate.format("YYYY-MM-DD");
  const end = endDate.format("YYYY-MM-DD");
  const now = currentDate.format("YYYY-MM-DD HH:mm:ss");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [dataLength, setDataLength] = useState();

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  useEffect(() => {
    setLoading(true);
    const apiUrl = `${config.urlLightningConfig}/api/v1/lightningWarning/${keyName}?dateStart=${start}&dateEnd=${end}`;
    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 200) {
          setData(data);

          const sortedData = data.message.slice().sort((a, b) => a.id - b.id);

          const mappedArray = [];
          const mappedArray2 = [];
          sortedData.forEach((item, index) => {
            item.logEField.forEach((logItem) => {
              mappedArray.push({
                "No.": index + 1,
                Date: item.date,
                Level: item.levelWarning,
                LAT: "",
                LON: "",
                Type: "",
                Height: "",
                Current: "",
                Error: "",
                "Lightning Date": "",
              });
              mappedArray2.push({
                id: item.id,
                Order: item.groupWarning,
                SentLine: item.sentLine,
                SentLineMsg: item.sentLineError,
                SentEmail: item.sentEmail,
                SentEmailMsg: item.sentEmailError,
                SentIoT: item.sentIoT,
                SentIoTMsg: item.sentIoTError,
                "No.": index + 1,
                Date: item.date,
                Level: item.levelWarning,
                LAT: "",
                LON: "",
                Type: "",
                Height: "",
                Current: "",
                Error: "",
                "Lightning Date": "",
              });
            });
            item.logMField.forEach((logItem) => {
              mappedArray.push({
                "No.": index + 1,
                Date: item.date,
                Level: item.levelWarning,
                LAT: logItem.LAT,
                LON: logItem.LON,
                Type: logItem.TYP,
                Height: logItem.HEI,
                Current: logItem.AMP,
                Error: logItem.ERR,
                "Lightning Date": logItem.DAT,
              });
              mappedArray2.push({
                id: item.id,
                Order: item.groupWarning,
                SentLine: item.sentLine,
                SentLineMsg: item.sentLineError,
                SentEmail: item.sentEmail,
                SentEmailMsg: item.sentEmailError,
                SentIoT: item.sentIoT,
                SentIoTMsg: item.sentIoTError,
                "No.": index + 1,
                Date: item.date,
                Level: item.levelWarning,
                LAT: logItem.LAT,
                LON: logItem.LON,
                Type: logItem.TYP,
                Height: logItem.HEI,
                Current: logItem.AMP,
                Error: logItem.ERR,
                "Lightning Date": logItem.DAT,
              });
            });

            setDataToPdf(mappedArray);
            setDataShow(mappedArray2);
            setDataLength(mappedArray2.length);
          });
        } else {
          console.log("error");
        }
      })
      .catch((error) => console.error("Error fetching data:", error))
      .finally(() => {
        setLoading(false);
      });
  }, [keyName, start, end]);

  const handleExport = () => {
    const worksheet = XLSX.utils.json_to_sheet(dataToPdf);

    Object.keys(worksheet).forEach((cellRef) => {
      const cell = worksheet[cellRef];
      if (cell && cell.t === "s" && cell.w) {
        const contentLength = cell.w.length;
        const currentWidth = worksheet["!cols"] && worksheet["!cols"][cell.c] ? worksheet["!cols"][cell.c].wch : 0;
        if (!worksheet["!cols"]) worksheet["!cols"] = [];
        if (contentLength > currentWidth) {
          worksheet["!cols"][cell.c] = { wch: contentLength };
        }
      }
    });

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Data");
    XLSX.writeFile(workbook, "notification_log_data.xlsx");
  };

  return (
    <>
      <Grid container my={2} display="flex" alignItems="center" justifyContent="center" p={2}>
        <Grid
          item
          xs={12}
          sm={12}
          lg={4}
          display="flex"
          alignItems="center"
          gap={4}
          sx={{ justifyContent: { xs: "left", sm: "center", lg: "center" }, mb: { xs: 2, sm: 2, lg: 0 } }}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              defaultValue={currentDate}
              openTo="year"
              label="Start Date"
              onChange={handleStartDateChange}
              disableFuture={true}
              className="custom-date-picker"
              format="DD/MM/YYYY"
              sx={{ width: "90%" }}
            />
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              defaultValue={currentDate}
              onChange={handleEndDateChange}
              openTo="year"
              label="End Date"
              disableFuture={true}
              format="DD/MM/YYYY"
              className="custom-date-picker"
              sx={{ width: "90%" }}
            />
          </LocalizationProvider>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          lg={4}
          display="flex"
          alignItems="center"
          gap={5}
          sx={{ justifyContent: { xs: "left", sm: "left", lg: "center" }, mb: { xs: 2, sm: 2, lg: 0 } }}
        >
          <Button variant="outlined" onClick={handleExport} color="success">
            Export Excel
          </Button>
          <PDFDownloadLink
            document={
              <PDFDocument
                user={user}
                data={dataToPdf}
                start={start}
                end={end}
                now={now}
                keyName={keyName}
                dataLength={dataLength}
              />
            }
            fileName="notification_log_data_pdf.pdf"
          >
            {({ blob, url, loading, error }) => (
              <Button variant="outlined" ref={pdfLinkRef} color="error">
                Export PDF
              </Button>
            )}
          </PDFDownloadLink>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          lg={4}
          display="flex"
          alignItems="center"
          sx={{ justifyContent: { xs: "left", sm: "left", lg: "right" }, mb: { xs: 2, sm: 2, lg: 0 } }}
        >
          <Paper
            component="form"
            sx={{
              p: 1,
              display: "flex",
              alignItems: "center",
              borderRadius: "32px",
              height: "20px",
              bgcolor: "white",
              width: { xs: "100%", sm: "50%", lg: "70%" },
            }}
          >
            <InputBase sx={{ ml: 1, flex: 1, color: "black" }} placeholder="Search" />
            <IconButton type="button" sx={{ P: 1 }} aria-label="search">
              <Search sx={{ color: "black" }} />
            </IconButton>
          </Paper>
        </Grid>
      </Grid>
      <Grid container>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center">No.</TableCell>
              <TableCell align="center">Date</TableCell>
              <TableCell align="center">Level</TableCell>
              <TableCell align="center">Order Config</TableCell>
              <TableCell align="center">LAT</TableCell>
              <TableCell align="center">LON</TableCell>
              <TableCell align="center">Type</TableCell>
              <TableCell align="center">Height</TableCell>
              <TableCell align="center">Current</TableCell>
              <TableCell align="center">Error</TableCell>
              <TableCell align="center">Lightning Date</TableCell>
              <TableCell align="center">Sent Line</TableCell>
              <TableCell align="center">Sent Email</TableCell>
              <TableCell align="center">Sent IoT</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataShow && dataShow.length > 0 ? (
              dataShow.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage).map((item, index) => (
                <TableRow key={index}>
                  <TableCell align="center">{item["No."]}</TableCell>
                  <TableCell align="center">{item.Date}</TableCell>
                  <TableCell align="center">{item.Level}</TableCell>
                  <TableCell align="center">{item.Order}</TableCell>
                  <TableCell align="center">{item.LAT}</TableCell>
                  <TableCell align="center">{item.LON}</TableCell>
                  <TableCell align="center">{item.Type}</TableCell>
                  <TableCell align="center">{item.Height}</TableCell>
                  <TableCell align="center">{item.Current}</TableCell>
                  <TableCell align="center">{item.Error}</TableCell>
                  <TableCell align="center">{item["Lightning Date"]}</TableCell>
                  <TableCell align="center">
                    {item.SentLine === 1 ? (
                      <Typography color="green">OK</Typography>
                    ) : (
                      <Typography color="error">{item.SentLineMsg}</Typography>
                    )}
                  </TableCell>
                  <TableCell align="center">
                    {item.SentEmail === 1 ? (
                      <Typography color="green">OK</Typography>
                    ) : (
                      <Typography color="error">{item.SentEmailMsg}</Typography>
                    )}
                  </TableCell>
                  <TableCell align="center">
                    {item.SentIoT === 1 ? (
                      <Typography color="green">OK</Typography>
                    ) : (
                      <Typography color="error">{item.SentIoTMsg}</Typography>
                    )}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={14} align="center">
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : (
                    <Typography>
                      No information as of the current date. <br /> Please select a start date. and the end date of the
                      requested information
                    </Typography>
                  )}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Grid>
      <Grid container justifyContent="center" mt={3} alignItems="center">
        <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
          {dataShow && dataShow.length > 0 ? (
            <Pagination
              count={Math.ceil(dataLength / itemsPerPage)}
              page={currentPage}
              onChange={handlePageChange}
              showFirstButton
              showLastButton
            />
          ) : null}
        </Grid>
        <Grid item xs={12} display="flex" justifyContent="center" alignItems="center" mt={2}>
          <Typography variant="body2" color="text.secondary">
            {dataShow && dataShow.length > 0
              ? `Page ${currentPage} From ${Math.ceil(dataLength / itemsPerPage)}`
              : null}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
}
