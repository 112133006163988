function convertSecondsToHoursMinutes(data) {
    var convertedData = {};
    for (var i = 0; i < data.length; i++) {
        var seconds = data[i][0];
        var value = data[i][1];

        var hours = Math.floor(seconds / 3600); // 3600 seconds in an hour
        var minutes = Math.floor((seconds % 3600) / 60); // Remaining seconds divided by 60 gives minutes

        // Format the hours and minutes
        var formattedHours = ("0" + hours).slice(-2); // Adding leading zero if necessary
        var formattedMinutes = ("0" + minutes).slice(-2); // Adding leading zero if necessary

        var currentTime = formattedHours + ":" + formattedMinutes;

        if (currentTime in convertedData) {
            // If the current time already exists as a key in convertedData,
            // accumulate the values to calculate the average later
            convertedData[currentTime].push(value);
        } else {
            // If the current time is a new minute, initialize it with an array containing the value
            convertedData[currentTime] = [value];
        }
    }

    // Calculate the average value for each minute
    var result = [];
    for (var key in convertedData) {
        var values = convertedData[key];
        var averageValue =
            values.reduce((sum, value) => sum + value, 0) / values.length;
        var timeParts = key.split(":");
        var second = +timeParts[0] * 3600 + +timeParts[1] * 60; // Convert time back to seconds
        result.push({
            x: second,
            y: parseFloat(averageValue.toFixed(2)),
            time: key,
        });
    }

    return result;
}

function convertSecondsToHoursMinutesOne(seconds) {
    var hours = Math.floor(seconds / 3600); // 3600 seconds in an hour
    var minutes = Math.floor((seconds % 3600) / 60); // Remaining seconds divided by 60 gives minutes

    // Format the hours and minutes
    var formattedHours = ("0" + hours).slice(-2); // Adding leading zero if necessary
    var formattedMinutes = ("0" + minutes).slice(-2); // Adding leading zero if necessary

    return formattedHours + ":" + formattedMinutes;
}

module.exports = {
    convertSecondsToHoursMinutes,
    convertSecondsToHoursMinutesOne,
};
