import React, { useContext } from 'react';
import "../Routers.css"
import Container from '../Container';
import { showSidebar } from '../../kumwell';
import Report from '../organization/all-setting/ReportMField';

export default function WarningReport({ user }) {
    const { reportWarn } = useContext(showSidebar);

    return (
        <Container title={`Warning Report : ${reportWarn.keyName}`} pathBefore="Report"
            components={
                <React.Fragment>
                    <Report />
                </React.Fragment>
            }>
        </Container>
    );
}