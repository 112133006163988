import React, { useEffect, useState } from "react";
import "../Routers.css";
import DataTable from "react-data-table-component";
import Cookies from "js-cookie";
import Container from "../Container";
import ConfirmDelete from "../ConfirmDelete";
import apiUrls from "../../ApiUrl";
import { customStyles } from "../DataTableStyle";
import { createContext } from "react";
import AddEfield from "./AddEField";
import Swal from "sweetalert2";
import { Alert, Button, Divider, FormControl, Grid, Snackbar, TextField, Typography } from "@mui/material";
import UrlMField from "./UrlMField.jsx";
const EfieldContext = createContext();
export default function Efield({ create, update, remove }) {
  const [pending, setPending] = useState(true);
  const [isLoaded, setIsLoaded] = useState(false);
  const [EfieldOption, setEfielOption] = useState(false);
  const [items, setItems] = useState([]);
  const [retryCount, setRetryCount] = useState(0);
  const [errorFetch, setErrorFetch] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [showDelete, setShowDelete] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const [openAlert, setOpenAlert] = useState({
    open: false,
    message: "",
    severity: "",
  });

  const [key, setKey] = useState("");
  const token = Cookies.get("accessToken");

  const [formData, setFormData] = useState({
    warningPointId: "",
    warningPointName: "",
    lat: "",
    lon: "",
    link: "",
  });
  const [formDataCheck, setFormDataCheck] = useState({
    warningPointId: "",
    warningPointName: "",
    lat: "",
    lon: "",
    link: "",
  });

  async function handleDelete(request) {
    try {
      apiUrls.setEfieldRequest(request);
      setDeleteLoading(true);
      const response = await fetch(apiUrls.Efield, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.ok) {
        Swal.fire({
          icon: "success",
          title: "Delete Successful!",
          text: "E-Field has been Deleted",
        });
        fetchData();
        apiUrls.setEfieldRequest("");
      } else {
        Swal.fire({
          icon: "error",
          title: "Delete Failed",
          text: "something went wrong!",
        });
        apiUrls.setEfieldRequest("");
      }
    } catch (error) {
      console.error("Failed to delete item:", error);
    } finally {
      apiUrls.setEfieldRequest("");
      setShowDelete(false);
      setDeleteLoading(false);
    }
  }

  const openDelete = (request) => {
    setKey(request);
    setShowDelete(true);
  };

  const closeModals = () => {
    setShowDelete(false);
  };

  const openCreate = () => {
    setFormData({
      warningPointId: "",
      warningPointName: "",
      lat: "",
      lon: "",
    });
    setEfielOption("create");
  };

  const fetchData = () => {
    const url = apiUrls.Efield_Get;
    fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === 200) {
          setIsLoaded(true);
          setItems(data);
        } else {
          setIsLoaded(false);
          console.log(data);
        }
      })
      .catch((error) => {
        setIsLoaded(false);
        setErrorFetch(error);
        const retryDelay = 3000;
        setTimeout(() => setRetryCount(retryCount + 1), retryDelay);
      });
  };

  useEffect(() => {
    fetchData();
  }, [retryCount]);

  const columns = [
    {
      name: "#",
      selector: (row) => row.id,
      sortable: true,
      width: "60px",
    },
    {
      name: "Warning Point",
      selector: (row) => row.warningPointId,
      sortable: true,
      width: "165px",
    },
    {
      name: "E-Field Name",
      selector: (row) => row.warningPointName,
      sortable: true,
      width: "150px",
    },
    {
      name: "Lat,long ",
      cell: (row) => (
        <li>
          {row.lat}, {row.lon}
        </li>
      ),
      width: "auto",
    },
  ];

  if (update) {
    columns.push({
      name: "",
      cell: (row) => (
        <>
          <button
            className="edit-btn"
            onClick={() => {
              setFormData({
                warningPointId: row.warningPointId,
                warningPointName: row.warningPointName,
                lat: row.lat,
                lon: row.lon,
                link: row.link
              });
              setFormDataCheck({
                warningPointId: row.warningPointId,
                warningPointName: row.warningPointName,
                lat: row.lat,
                lon: row.lon,
                link: row.link
              });
              setEfielOption("edit");
            }}
          >
            Edit
          </button>
        </>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      width: "90px",
    });
  }

  if (remove) {
    columns.push({
      name: "",
      cell: (row) => (
        <>
          <button className="delete-btn" onClick={() => openDelete(row.warningPointId)}>
            Delete
          </button>
        </>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      width: "90px",
    });
  }

  const CustomLoader = () => (
    <>
      <div style={{ padding: "40px" }}>
        <span className="loader"></span>
        <p>{errorFetch.message}</p>
      </div>
    </>
  );
  if (token != null && isLoaded === true) {
    const data = items.message;
    const dataWithIds = data.map((item, index) => ({ id: index + 1, ...item }));
    const filteredData = dataWithIds.filter((item) => {
      const { warningPointId, warningPointName } = item;
      const searchValue = searchQuery.toLowerCase();
      return warningPointId.toLowerCase().includes(searchValue) || warningPointName.toLowerCase().includes(searchValue);
    });
    return (
      <>
        <EfieldContext.Provider
          value={{
            EfieldOption,
            setEfielOption,
            formData,
            setFormData,
            setRetryCount,
            retryCount,
            formDataCheck,
            items,
          }}
        >
          {EfieldOption === "create" && <AddEfield edit={false} />}
          {EfieldOption === "edit" && <AddEfield edit={true} />}
          <Container
            title={<>E-Field sensor</>}
            btn={create ? <>Add E-Field Sensors </> : null}
            action={openCreate}
            components={
              <>
                {showDelete ? (
                  <ConfirmDelete
                    topic="E-field"
                    closeModals={closeModals}
                    confirm={() => handleDelete(key)}
                    loadding={deleteLoading}
                  />
                ) : (
                  <></>
                )}
                <input
                  className="Search"
                  type="text"
                  onChange={(e) => setSearchQuery(e.target.value)}
                  placeholder="Search"
                />
                <DataTable
                  columns={columns}
                  data={filteredData}
                  pagination
                  fixedHeader
                  customStyles={customStyles}
                  highlightOnHover
                  noHeader
                  striped
                />

                <UrlMField openAlert={setOpenAlert} />

                <Snackbar
                  open={openAlert.open}
                  autoHideDuration={1000}
                  onClose={() => {
                    setOpenAlert({
                      ...openAlert,
                      open: false,
                    });
                  }}
                  position="top-right"
                  disableWindowBlurListener
                >
                  {openAlert.open ? (
                    <Alert
                      onClose={() => {
                        setOpenAlert({
                          ...openAlert,
                          open: false,
                        });
                      }}
                      severity={openAlert.severity || "success"}
                      sx={{ width: "100%" }}
                    >
                      {openAlert.message}
                    </Alert>
                  ) : null}
                </Snackbar>
              </>
            }
          />
        </EfieldContext.Provider>
      </>
    );
  } else {
    return (
      <>
        <Container
          title={<>E-Field sensor</>}
          components={
            <>
              <DataTable progressPending={pending} progressComponent={<CustomLoader />} />
            </>
          }
        />
      </>
    );
  }
}
export { EfieldContext };
