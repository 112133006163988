// require("dotenv").config();

module.exports = {
  urlLightningConfig: process.env.REACT_APP_URL_LIGHTNING_CONFIG || "http://7315050e2cc3.sn.mynetname.net:8081",
  urlAuth: process.env.REACT_APP_URL_AUTH || "http://7315050e2cc3.sn.mynetname.net:8082",
  urlSettingNotification: process.env.REACT_APP_URL_SETTING_NOTIFICATION || "http://7315050e2cc3.sn.mynetname.net:8083",
  urlNotification: process.env.REACT_APP_URL_NOTIFICATION || "http://7315050e2cc3.sn.mynetname.net:8084",
  urlWebsocket: process.env.REACT_APP_URL_WEBSOCKET || "ws://7315050e2cc3.sn.mynetname.net:8085",
  urlSound: process.env.REACT_APP_URL_SOUND || "http://7315050e2cc3.sn.mynetname.net:8086",
  urlDevice: process.env.REACT_APP_URL_DEVICE || "http://7315050e2cc3.sn.mynetname.net:8087",
  urlTestWarning: process.env.REACT_APP_URL_TEST_WARNING || "http://7315050e2cc3.sn.mynetname.net:9001",
};
