import { useContext, useState } from "react";
import { EfieldContext } from "./E-Field";
import '../Routers.css';
import Cookies from "js-cookie";
import Swal from "sweetalert2";
import apiUrls from "../../ApiUrl";
import { TextField } from "@mui/material";
export default function AddEfield({ edit }) {
    const [onHole, setOnHole] = useState(true);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const [onFetch, setOnFetch] = useState(false);
    const { setEfielOption, setFormData, formData, setRetryCount, retryCount, formDataCheck, items } = useContext(EfieldContext);
    const token = Cookies.get("accessToken");

    const handleModalClick = (e) => {
        e.stopPropagation();
        setOnHole(true);
    };

    const closeModal = () => {
        if (onHole !== false) {
            setEfielOption(false);
        }
        setOnHole(true);
    };

    const closeModals = () => {
        setEfielOption(false);
    };

    const handleChange = (event) => {
        const { name, type, value } = event.target;
        setError(false);
        let parsedValue;
        if (type === "number") {
            parsedValue = parseFloat(value);
        }
        else {
            parsedValue = value;
        }
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: parsedValue,
        }));
    }

    const Unique = () => {
        const Found = items.message.find(item => item.warningPointId === formData.warningPointId);
        if (Found && edit === false) {
            setErrorMessage(true);
        } else {
            setErrorMessage(false);
        }
    }

    const CheckSubmit = () => {
        let keysExist = false;
        const areAllInputsFilled = Object.values(formData).every((input) => input !== "");
        if (areAllInputsFilled && errorMessage === false) {
            Object.keys(formData).forEach((key) => {
                if (formData[key] === formDataCheck[key]) {
                }
                else if (error != "Password-Fail") {
                    keysExist = true;
                }
            })
            if (keysExist) { handleSubmit(); }
            else {
                setError("No Edit");
            }
        }
        else {
            setError(true);
        }
    }

    const handleSubmit = async () => {
        setOnFetch(true);
        if (edit) { apiUrls.setEfieldRequest(formDataCheck.warningPointId); }
        else { apiUrls.setEfieldRequest(''); }
        const options = {
            method: `${edit ? "PUT" : "POST"}`,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(formData),
        };
        try {
            const response = await fetch(apiUrls.Efield, options);
            if (response.ok) {
                Swal.fire({
                    icon: "success",
                    title: `${edit ? "Edited" : "Created"} Successful!`,
                    text: `Data has been ${edit ? "Edited" : "Created"}`,
                });
                setRetryCount(retryCount + 1);
                setOnFetch(false);
                closeModals();
            } else {
                Swal.fire({
                    icon: "error",
                    title: `${edit ? "Edited" : "Created"} Failed`,
                    text: "Something went wrong!",
                });
                setOnFetch(false);

            }
        } catch (error) {
            console.error("Error inserting data:", error);
            Swal.fire({
                icon: "error",
                title: "Insert Failed",
                text: "Something went wrong!",
            });
            setOnFetch(false);
        }
    };

    return (
        <div className="overlay" onClick={closeModal}>
            <div className="bodyModal" >
                <div
                    className="modals-organization"
                    onClick={handleModalClick}
                    onMouseDown={() => setOnHole(false)}
                    onMouseUp={() => setOnHole(false)}
                >
                    <div className="menu-bar-addOrganization">{edit ? "Edit" : "Add"} E-Field Sensor</div>
                    <nav className="status-sidebar-addOrganization" align="center">
                        <div className="active">E-Field sensor</div>
                    </nav>

                    <div className="Router-addOrganization">
                        <form autoComplete="off">
                            <p>E-Field Sensor</p>
                            <div className="input-onelines" align="right">
                                <span>warningPointId : </span>
                                <input
                                    type="text"
                                    name="warningPointId"
                                    value={formData.warningPointId}
                                    placeholder=""
                                    onChange={handleChange}
                                    onBlur={Unique}
                                />

                                {/* <TextField
                                    size="small"
                                    label="warningPointId"
                                    name="warningPointId"
                                    type="text"
                                    value={formData.warningPointId}
                                    onChange={handleChange}
                                    onBlur={Unique}
                                    sx={{ width: '50%' }}
                                ></TextField> */}


                            </div>
                            {errorMessage && <div className="erorr-password input-onelines">warningPointId มีผู้ใช้งานแล้ว</div>}
                            <div className="input-onelines" align="right">
                                <span>warningPointName : </span>
                                <input
                                    type="text"
                                    name="warningPointName"
                                    value={formData.warningPointName}
                                    placeholder=""
                                    onChange={handleChange}
                                />
                            </div>

                            <div className="input-onelines" align="right">
                                <span>latitude : </span>
                                <input
                                    type="number"
                                    name="lat"
                                    value={formData.lat}
                                    placeholder=""
                                    onChange={handleChange}
                                />
                            </div>

                            <div className="input-onelines" align="right">
                                <span>longitude : </span>
                                <input
                                    type="number"
                                    name="lon"
                                    value={formData.lon}
                                    placeholder=""
                                    onChange={handleChange}
                                />
                            </div>

                            <div className="input-onelines" align="left" style={{ width: "100%" }}>
                                <span>Link : </span>
                                <input
                                    style={{ width: "90%" }}
                                    type="text"
                                    name="link"
                                    value={formData.link}
                                    placeholder=""
                                    onChange={handleChange}
                                />
                            </div>


                            {error === true && (
                                <div className="alert-input" align="center">
                                    Please enter all information.
                                </div>
                            )
                                || error === "No Edit" && (
                                    <div className="alert-input" align="center">
                                        The information has not changed.
                                    </div>
                                )}
                        </form>
                    </div>

                    <div className="menu-bar-addOrganization">
                        <button className="btn-addOrg close" onClick={closeModals}>
                            Close
                        </button>
                        <button
                            className="btn-addOrg next"
                            onClick={CheckSubmit}
                            disabled={onFetch}
                            align="center"
                        >
                            {onFetch ? <span className="loader2"></span> : "SUBMIT"}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

