import { useContext, useEffect, useState } from "react";
import { OrganizationInput } from "./AddOrganization";
import DataTable from "react-data-table-component";
import Cookies from "js-cookie";
import apiUrls from "../../../ApiUrl";

const config = require("./../../../configs/app");

export default function SummaryOrg({ superAdmin }) {
  const { formData, userData, addMaptype, sensorData, selectedDate, setFormData } = useContext(OrganizationInput);
  const [showPassword, setShowPassword] = useState(false);
  const [items, setItems] = useState([]);
  const [allRole, setAllRole] = useState([]);
  const token = Cookies.get("accessToken");

  const fetchData = () => {
    const url = apiUrls.Efield;
    fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === 200) {
          setItems(data);
        } else {
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchRole = async () => {
    try {
      const response = await fetch(`${config.urlAuth}/api/v1/role/`, {
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
      });
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }
      const data = await response.json();
      const Rolename = Object.values(data.message).map((item) => ({
        name: item.name,
        roleId: item.roleId,
      }));
      setAllRole(Rolename);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
    fetchRole();
  }, []);

  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      email: userData.email,
      phoneNumber: userData.phoneNumber,
    }));
  }, []);

  const filteredDatas = items.message ? items.message.filter((item) => sensorData.includes(item.warningPointId)) : [];

  const columns = [
    {
      name: "E-Field Name",
      selector: (row) => row.warningPointName,
      sortable: true,
      width: "180px",
    },
    {
      name: "Warning Point",
      selector: (row) => row.warningPointId,
      sortable: true,
      width: "auto",
    },
    {
      name: "Latitude ",
      selector: (row) => row.lat,
      width: "150px",
    },

    {
      name: "Longitude ",
      selector: (row) => row.lon,
      width: "150px",
    },
  ];

  return (
    <div className="summary-organization">
      <h3>Summary Organization</h3>
      <p>ข้อมูลสถานที่</p>
      <div className="input-inline " align="right">
        <span>ชื่อสถานที่ : </span>
        <input
          type="text"
          className="input-half summary-input-background "
          value={formData.nameLocation || ""}
          readOnly
          style={{ pointerEvents: "none" }}
        />
      </div>

      <div className="input-inline" align="right">
        <span>รหัสสถานที่ : </span>
        <input
          className="input-half summary-input-background "
          type="text"
          name="keyName"
          value={formData.keyName || ""}
          readOnly
          onChange={(event) => event.preventDefault()}
          style={{ pointerEvents: "none" }}
        />
      </div>

      <div className="input-inline" align="right">
        <span>Latitude : </span>
        <input
          className="input-half summary-input-background"
          type="number"
          name="lat"
          value={formData.lat || ""}
          readOnly
          onChange={(event) => event.preventDefault()}
          style={{ pointerEvents: "none" }}
        />
      </div>

      <div className="input-inline" align="right">
        <span>Longitude : </span>
        <input
          className="input-half summary-input-background"
          type="number"
          name="lon"
          value={formData.lon || ""}
          readOnly
          onChange={(event) => event.preventDefault()}
          style={{ pointerEvents: "none" }}
        />
      </div>

      <div className="input-Full " align="right">
        <span>ที่อยู่ : </span>
        <textarea
          className="summary-input-background"
          rows="3"
          cols="30"
          name="address"
          value={formData.address || ""}
          readOnly
          onChange={(event) => event.preventDefault()}
          style={{ pointerEvents: "none" }}
        />
      </div>

      <div className="input-inline" align="right">
        <span>ประเภทแผนที่ : </span>
        <select
          className="summary-input-background"
          name="mapType"
          value={addMaptype || ""}
          readOnly
          onChange={(event) => event.preventDefault()}
          style={{ pointerEvents: "none" }}
        >
          <option value={1}> M-Field </option>
          <option value={2}> E-Field </option>
          <option value={3}> M-Field,E-Field </option>
        </select>
      </div>

      <div className="input-center" align="right">
        <span>Display (เมตร) : </span>
        <input
          className="input-full summary-input-background"
          type="number"
          required
          name="display"
          value={formData.display || ""}
          readOnly
          onChange={(event) => event.preventDefault()}
          style={{ pointerEvents: "none" }}
        />
      </div>

      <div className="input-center" align="right">
        <span>ActiveZone R (เมตร) : </span>
        <input
          className="input-full summary-input-background"
          type="number"
          name="activeZoneR"
          value={formData.activeZoneR || ""}
          readOnly
          onChange={(event) => event.preventDefault()}
          style={{ pointerEvents: "none" }}
        />
      </div>

      <div className="input-center" align="right">
        <span>ActiveZone A (เมตร) : </span>
        <input
          className="input-full summary-input-background"
          type="number"
          name="activeZoneA"
          value={formData.activeZoneA || ""}
          readOnly
          onChange={(event) => event.preventDefault()}
          style={{ pointerEvents: "none" }}
        />
      </div>

      <div className="input-center" align="right">
        <span>ActiveZone B (เมตร) : </span>
        <input
          className="input-full summary-input-background"
          type="number"
          name="activeZoneB"
          value={formData.activeZoneB || ""}
          readOnly
          onChange={(event) => event.preventDefault()}
          style={{ pointerEvents: "none" }}
        />
      </div>

      <div className="input-center" align="right">
        <span>Line Token : </span>
        <input
          className="input-full summary-input-background"
          type="text"
          name="lineToken"
          value={formData.lineToken || ""}
          readOnly
          onChange={(event) => event.preventDefault()}
          style={{ pointerEvents: "none" }}
        />
      </div>

      <div className="input-center" align="right">
        <span>อายุการใช้งาน จนถึง: </span>
        <input
          className="input-full summary-input-background"
          type="date"
          readOnly
          onChange={(event) => event.preventDefault()}
          style={{ pointerEvents: "none" }}
          value={selectedDate}
        />
      </div>
      {/* <div className="input-center" align="right">
        อายุการใช้งาน {formData.packagePlan} วัน
      </div> */}

      {addMaptype !== 1 && (
        <>
          <p>E field sensor</p>
          <div>
            <DataTable columns={columns} data={filteredDatas} />
          </div>
        </>
      )}

      <p>ข้อมูลผู้ใช้งาน</p>

      <div className="input-oneline" align="right">
        <span>ชื่อ : </span>
        <input
          type="text"
          name="firstName "
          value={userData.firstName || ""}
          placeholder="First Name"
          readOnly
          onChange={(event) => event.preventDefault()}
          style={{ pointerEvents: "none" }}
          className="summary-input-background"
        />
      </div>

      <div className="input-oneline" align="right">
        <span>นามสกุล : </span>
        <input
          type="text"
          name="lastName"
          value={userData.lastName || ""}
          placeholder="Last Name"
          readOnly
          onChange={(event) => event.preventDefault()}
          style={{ pointerEvents: "none" }}
          className="summary-input-background"
        />
      </div>
      <div className="input-oneline" align="right">
        <span>Email : </span>
        <input
          type="text"
          name="email"
          value={userData.email || ""}
          placeholder="example@email.com"
          readOnly
          onChange={(event) => event.preventDefault()}
          style={{ pointerEvents: "none" }}
          className="summary-input-background"
        />
      </div>

      <div className="input-oneline" align="right">
        <span>Username : </span>
        <input
          type="text"
          name="username"
          value={userData.username || ""}
          placeholder="username"
          readOnly
          onChange={(event) => event.preventDefault()}
          style={{ pointerEvents: "none" }}
          className="summary-input-background"
        />
      </div>

      <div className="input-oneline" align="right">
        <span>Password : </span>
        <input
          autoComplete="off"
          className="input-half summary-input-background"
          type={showPassword ? "text" : "password"}
          value={userData.password || ""}
          readOnly
          onChange={(event) => event.preventDefault()}
          style={{ pointerEvents: "none" }}
        />
      </div>

      <div className="input-oneline" align="right">
        <span>เบอร์โทรศัพท์ : </span>
        <input
          type="text"
          name="phoneNumber"
          value={userData.phoneNumber || ""}
          readOnly
          onChange={(event) => event.preventDefault()}
          style={{ pointerEvents: "none" }}
          className="summary-input-background"
        />
      </div>

      <div className="input-oneline" align="right">
        <span>Role : </span>
        <select
          name="RoleId"
          value={userData.RoleId}
          readOnly
          onChange={(event) => event.preventDefault()}
          className="summary-input-background"
          style={{ pointerEvents: "none" }}
        >
          {/* <option value={1}>adminKumwell</option>
          <option value={2}>userOrganization</option>
          <option value={3}>adminOrganization</option> */}
          {allRole &&
            allRole
              .filter((item) => (superAdmin ? item.roleId : item.roleId !== 29 && item.roleId !== 24))
              .map((items, index) => (
                <option key={index} value={items.roleId}>
                  {items.name}
                </option>
              ))}
        </select>
      </div>
    </div>
  );
}
