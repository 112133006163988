import React, { createContext, useContext, useEffect, useState } from "react";
import "../Routers.css";
import Cookies from "js-cookie";
import { showSidebar } from "../../kumwell";
import Container from "../Container";
import Swal from "sweetalert2";
import { Grid, TextField } from "@mui/material";

const config = require("./../../configs/app");

const TestSendContext = createContext();
export default function Line() {
  const { auth, showSidebars } = useContext(showSidebar);
  const token = Cookies.get("accessToken");
  const marker = document.querySelector("#marker2");
  const item = document.querySelectorAll(".organization-menu");
  const [activeItem, setActiveItem] = useState(0);

  const [formData, setFormData] = useState({
    token: "",
    message: "",
  });
  const [formDataEmail, setFormDataEmail] = useState({
    email: "",
    message: "",
  });

  const indicator = (e) => {
    marker.style.left = e.offsetLeft + "px";
    marker.style.width = e.offsetWidth + "px";
  };

  item.forEach((link) => {
    link.addEventListener("click", (e) => {
      indicator(e.target);
    });
  });

  const hasPermission = (itemId) => {
    const allowedIds = [null]; //if you want to hide menu , in [null] you can add id you want such as [1,2,3]
    return allowedIds.includes(itemId);
  };

  const handleClick = (itemId) => {
    setActiveItem(itemId);
  };

  const items = [
    { id: 1, text: "Line" },
    { id: 2, text: "Email" },
  ].filter((item) => !hasPermission(item.id));

  const componentMapping = {
    1: LineSend,
    2: EmailSend,
  };

  const ActiveComponent = componentMapping[activeItem] || null;

  useEffect(() => {
    setActiveItem(1);
  }, []);

  if (token != null && auth) {
    return (
      <TestSendContext.Provider value={{ formData, setFormData, formDataEmail, setFormDataEmail }}>
        <div className={`organization route-container ${showSidebars}`}>
          <div className="route-backgorund">
            <span className="topic-title">
              <span className="backOrganizion" style={{ pointerEvents: "none" }}>
                Testing send
              </span>
            </span>
            <div className="organization-setting">
              <nav>
                <div id="marker2"></div>
                {items.map((item) => (
                  <span
                    key={item.id}
                    className={activeItem === item.id ? "organization-menu active" : "organization-menu"}
                    onClick={() => handleClick(item.id)}
                  >
                    {item.text}
                  </span>
                ))}
              </nav>
            </div>
            <div className="route-item ">{ActiveComponent && <ActiveComponent />}</div>
          </div>
        </div>
      </TestSendContext.Provider>
    );
  } else {
    return <Container title={<>Testing send</>} components={<></>} />;
  }
}

function LineSend() {
  const { formData, setFormData } = useContext(TestSendContext);
  const [error, setError] = useState(false);
  const [onFetch, setOnFetch] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const token = Cookies.get("accessToken");

  const characterCountStyle = {
    position: 'absolute',
    top: 1,
    right: 5,
    color: 'gray',
    fontSize: '11px',
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setError(false);
    if (name === "token") {
      setErrorMessage(false);
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
    else if (name === "message") {
      if (value.length > 1000) {

        setShowError(true);
      } else {
        setShowError(false);
        setFormData((prevFormData) => ({
          ...prevFormData,
          [name]: value,
        }));
      }
    }

  };

  const checkToken = () => {
    const lineTokenPattern = /^[A-Za-z0-9_-]{30,84}$/;
    if (!lineTokenPattern.test(formData.token) && formData.token !== "") {
      setErrorMessage(true);
    } else {
      setErrorMessage(false);
    }
  };

  const CheckSubmit = () => {
    const areAllInputsFilled = Object.values(formData).every((input) => input !== "");
    if (areAllInputsFilled && errorMessage === false && error === false) {
      handleSubmit();
    } else {
      setError(true);
    }
  };

  const handleSubmit = async () => {
    setOnFetch(true);
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(formData),
    };
    try {
      const response = await fetch(`${config.urlNotification}/api/v1/test-sending-line`, options);
      console.log(response);
      if (response.ok) {
        Swal.fire({
          icon: "success",
          title: `Line Send Successful!`,
          text: `message has been sent`,
        });
        setOnFetch(false);
      } else {
        Swal.fire({
          icon: "error",
          title: `Line Send Failed`,
          text: "Something went wrong!",
        });
        setOnFetch(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div>
        <TextField
          size="small"
          label="Token Line ID"
          name="token"
          onBlur={checkToken}
          type="text"
          value={formData.token || ""}
          onChange={handleChange}
          sx={{ width: "100%" }}
        ></TextField>
      </div>
      {errorMessage && <div className="erorr-message ">LineToken is invalid</div>}
      <br />
      <div style={{ position: 'relative' }}>
        <TextField
          size="small"
          label="Message"
          name="message"
          multiline
          type="text"
          value={formData.message || ""}
          onChange={handleChange}
          sx={{ width: "100%" }}
        />
        <div style={characterCountStyle}>
          {formData.message && <>{formData.message.length}/1000</>}
        </div>
        {showError && (
          <div style={{ color: "red" }}>
            message length should not exceed 1000 characters.
          </div>
        )}
        {error && (
          <div className="alert-input-sendinfo" align="center">
            please provide complete information.
          </div>
        )}
      </div>
      <button className="Line-Btn setting-btn" onClick={CheckSubmit} disabled={onFetch || showError}>
        {" "}
        {onFetch ? <span className="loader2"></span> : "SUBMIT"}
      </button>
    </>
  );
}

function EmailSend() {
  const { formDataEmail, setFormDataEmail } = useContext(TestSendContext);
  const [onFetch, setOnFetch] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const token = Cookies.get("accessToken");
  const [showError, setShowError] = useState(false);

  const characterCountStyle = {
    position: 'absolute',
    top: 1,
    right: 5,
    color: 'gray',
    fontSize: '11px',
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "email") {
      setErrorMessage(false);
      setFormDataEmail((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));

    } else {
      if (value.length > 1000) {
        setShowError(true);
      } else {
        setShowError(false);
        setFormDataEmail((prevFormData) => ({
          ...prevFormData,
          [name]: value,
        }));
      }
    }

  };

  const checkMail = () => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(formDataEmail.email) && formDataEmail.email !== "") {
      setErrorMessage(true);
    } else {
      setErrorMessage(false);
    }
  };

  const handleSubmit = async () => {
    setOnFetch(true);
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(formDataEmail),
    };
    try {
      const response = await fetch(`${config.urlNotification}/api/v1/test-sending-email`, options);

      if (response.ok) {
        Swal.fire({
          icon: "success",
          title: `Email Send Successful!`,
          text: `message has been sent`,
        });
        setOnFetch(false);
      } else {
        Swal.fire({
          icon: "error",
          title: `Email Send Failed`,
          text: "Something went wrong!",
        });
        setOnFetch(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const CheckSubmit = () => {
    const areAllInputsFilled = Object.values(formDataEmail).every((input) => input !== "");
    if (areAllInputsFilled && errorMessage === false && error === false) {
      handleSubmit();
    } else {
      setError(true);
    }
  };
  const inputStyle = {
    height: "18px",
  };
  return (
    <>

      <div>
        <TextField
          size="small"
          label="Email"
          name="email"
          onBlur={checkMail}
          type="text"
          onChange={handleChange}
          sx={{ width: "100%" }}
        ></TextField>
      </div>
      {errorMessage && <div className="erorr-message ">Email is invalid</div>}
      <br />
      <div style={{ position: 'relative' }}>
        <TextField
          size="small"
          label="Message"
          multiline
          name="message"
          value={formDataEmail.message || ""}
          type="text"
          onChange={handleChange}
          sx={{ width: "100%" }}
        />
        <div style={characterCountStyle}>
          {formDataEmail.message && <>{formDataEmail.message.length}/1000</>}
        </div>
      </div>
      {showError && (
        <div style={{ color: "red" }}>
          message length should not exceed 1000 characters.
        </div>
      )}
      {error && (
        <div className="alert-input-sendinfo" align="center">
          please provide complete information.
        </div>
      )}
      <button className="Line-Btn setting-btn" onClick={CheckSubmit} disabled={onFetch || showError}>
        {" "}
        {onFetch ? <span className="loader2"></span> : "SUBMIT"}
      </button>

    </>
  );
}
