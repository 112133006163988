import { useContext, useState } from "react";
import { PasswordContext } from "../navigation/Menubar";
import "./Routers.css";
import { showSidebar } from "../kumwell";
import apiUrls from "../ApiUrl";
import Cookies from "js-cookie";
import Swal from "sweetalert2";
export default function PasswordSet() {
    const { showComponent, setShowComponent } = useContext(PasswordContext);
    const [password, setPassword] = useState("");
    const [passwordNow, setPasswordNow] = useState("");
    const [confirmPassword, setconfirmPassword] = useState("");
    const [dataStatus, setDataStatus] = useState(false);
    const [dataPassword, setDataPassword] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [onHole, setOnHole] = useState(true);
    const [erorrPassword, setErorrPassword] = useState("");
    const { auth } = useContext(showSidebar);
    const LoginUrl = apiUrls.loginUrl;
    const regex = /^[a-zA-Z0-9@\s]*$/;
    const token = Cookies.get("accessToken");

    const handlePasswordChange = (e) => {
        const value = e.target.value;
        if (regex.test(value)) {
            setPassword(value);
        }
    };

    const handlePasswordNowChange = (e) => {
        const value = e.target.value;
        setPasswordNow(value);
    };

    const handlePassword2Change = (e) => {
        const value = e.target.value;

        if (regex.test(value)) {
            setconfirmPassword(value);
        }
    };

    const PushData = () => {
        fetch(apiUrls.user_Request, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({ password: password }),
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.status === 200) {
                    Swal.fire({
                        icon: "success",
                        title: "Password Change Successful!",
                        text: "Password has been Changed",
                    });
                    closeModals();
                } else {
                    Swal.fire({
                        icon: "error",
                        title: "Password Change Failed",
                        text: "something went wrong",
                    });
                }
                console.log(data);
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    const CheckPassword = () => {
        if (password.length < 8 && password !== "") {
            setErorrPassword("Password should be at least 8 characters long");
            setDataStatus(false);
        } else if (password !== confirmPassword && confirmPassword !== "") {
            setErorrPassword("Passwords dont match");
            setDataStatus(false);
        } else if (dataPassword === true && password === passwordNow && passwordNow !== "") {
            setErorrPassword("New password can not be same as your Current password");
            setDataStatus(false);
        } else if (dataStatus === true && confirmPassword === "") {
            setErorrPassword("Passwords dont match");
            setDataStatus(false);
        } else if (password === confirmPassword && confirmPassword !== "") {
            setDataStatus(true);
            setErorrPassword("");
        } else {
            setErorrPassword("");
        }
    };

    async function loginUser(credentials) {
        return fetch(LoginUrl, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            credentials: "include",
            sameSite: "none",
            body: JSON.stringify(credentials),
        })
            .then((response) => response.json())
            .catch((error) => {
                console.log(`error${error.message}`);
            });
    }

    const handleSubmit = async (e) => {
        if (dataStatus === true) {
            e.preventDefault();
            const response = await loginUser({
                emailUsername: auth.username,
                password: passwordNow,
            });
            if (response !== undefined) {
                if (response.userData && response.accessToken) {
                    setDataPassword(true);
                    apiUrls.setUserRequest3(auth.keyName);
                    apiUrls.setUserRequest4(auth.userId);
                    apiUrls.setUserRequest4(1);
                    PushData();
                } else {
                    setErorrPassword("Current Password is Wrong!");
                }
            } else {
                setErorrPassword("Current Password is Wrong!");
            }
        } else {
            if (confirmPassword === "") {
                setErorrPassword("Confirm password dont match");
            }
        }
    };

    const handleCheckboxChange = () => {
        setShowPassword(!showPassword);
    };

    const handleModalClick = (e) => {
        e.stopPropagation();
        setOnHole(true);
    };

    const closeModal = () => {
        if (onHole !== false) {
            setShowComponent(false);
        }
        setOnHole(true);
    };

    const closeModals = () => {
        setShowComponent(false);
    };

    return (
        <>
            {showComponent && (
                <div className="overlay" onClick={closeModal}>
                    <div className="bodyModal " aria-autocomplete="off">
                        <div
                            className="modals-password"
                            onClick={handleModalClick}
                            onMouseDown={() => setOnHole(false)}
                            onMouseUp={() => setOnHole(false)}
                        >
                            <p className="topic-modal">
                                <img src="/image/padlock.png" alt="lock" width="18px" /> Change
                                Password
                                <img
                                    className="close-modal"
                                    src="/image/close-button.png"
                                    alt="close"
                                    width="18px"
                                    onClick={closeModals}
                                />
                            </p>
                            <hr />
                            <label>Current Password</label>
                            <input
                                type={showPassword ? "text" : "password"}
                                onChange={handlePasswordNowChange}
                                value={passwordNow}
                            />
                            <label>New Password</label>
                            <input
                                type={showPassword ? "text" : "password"}
                                id="password"
                                value={password}
                                onBlur={CheckPassword}
                                onChange={handlePasswordChange}
                            />

                            <label>Confirm Password</label>
                            <input
                                type={showPassword ? "text" : "password"}
                                onChange={handlePassword2Change}
                                value={confirmPassword}
                                onBlur={CheckPassword}
                            />
                            <div align="left" className="erorr-password2 ">
                                {" "}
                                {erorrPassword}
                            </div>
                            <input
                                type="checkbox"
                                id="showPassword"
                                checked={showPassword}
                                onChange={handleCheckboxChange}
                            />
                            <label>แสดงรหัสผ่าน</label>
                            <button className="submit-btn" onClick={handleSubmit}>
                                SUBMIT
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}
