import { useContext, useState, createContext, useEffect } from "react";
import { OrganizationContext } from "../Organization";
import InformationOrg from "./InformationOrg";
import TypeMap from "./Typemap";
import User from "./user";
import SummaryOrg from "./SummaryOrg";
import Cookies from "js-cookie";
import apiUrls from "../../../ApiUrl";
import Swal from "sweetalert2";
const OrganizationInput = createContext();

export default function UpDateOrgan() {
  const [selectedDate, setSelectedDate] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmpassword, setconfirmPassword] = useState(false);
  const { showComponent, setShowComponent, setRetryCount, retryCount } = useContext(OrganizationContext);
  const [onHole, setOnHole] = useState(true);
  const [activeItem, setActiveItem] = useState(1);
  const [addMaptype, setMaptype] = useState("");
  const [badResquest, setBadRequest] = useState(false);
  const [error, setError] = useState(false);
  const [errorEmail, setErrorEmail] = useState(false);
  const [errorUsername, setErrorUsername] = useState(false);
  const [sensorData, setsensorData] = useState([]);
  const [onFetch, setOnFetch] = useState(false);
  const token = Cookies.get("accessToken");

  const [formData, setFormData] = useState({
    nameLocation: "",
    keyName: "",
    lat: "",
    lon: "",
    address: "",
    mapType: addMaptype,
    activeZoneR: "",
    activeZoneA: "",
    activeZoneB: "",
    display: "",
    lineToken: "",
    packageEnd: "",
    activeStatus: true,
  });

  const [userData, setUserData] = useState({
    username: "",
    email: "",
    password: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    viewEField: false,
    RoleId: 1,
  });

  const urlOrganization = apiUrls.OrganizationUrl_POST;
  const urlUser = apiUrls.user_GET;
  apiUrls.setUserRequest1(formData.keyName);

  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  function SubmitData() {
    setOnFetch(true);
    const bodyOrganization = JSON.stringify(formData);
    const bodyUser = JSON.stringify(userData);
    fetch(urlOrganization, { ...options, body: bodyOrganization })
      .then((response) => response.json())
      .then((result) => {
        console.log("organization submit:", result);
        return fetch(urlUser, { ...options, body: bodyUser });
      })
      .then((response) => response.json())
      .then((result) => {
        if (result.status === 201) {
          Swal.fire({
            icon: "success",
            title: "Submission Successful!",
          });
          setRetryCount(retryCount + 1);
          setShowComponent(false);
          apiUrls.setUserRequest1("");
        } else {
          console.log("user error submit:", result);
          Swal.fire({
            icon: "error",
            title: "Submission Failed",
          });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      })
      .finally(() => {
        setOnFetch(false);
        apiUrls.setUserRequest1("");
      });
  }

  useEffect(() => {
    if (addMaptype !== 1) {
      const newFormData = {
        ...formData,
        EFieldSensor: sensorData,
        // activeZoneEField: "",
      };
      setFormData(newFormData);
    } else {
      const { EFieldSensor, ...updatedFormData } = formData;

      setFormData(updatedFormData);
    }
  }, [addMaptype, sensorData]);

  const handleModalClick = (e) => {
    e.stopPropagation();
    setOnHole(true);
  };

  const closeModal = () => {
    setError(false);
    if (onHole !== false) {
      setShowComponent(false);
    }
    setOnHole(true);
  };

  const closeModals = () => {
    setError(false);
    setShowComponent(false);
  };

  const componentMapping = {
    1: Component1,
    2: Component2,
    3: Component4,
    4: Component5,
  };
  //const requiredInputs = ['nameLocation', 'keyName']; //* ใช้กำหนด Input
  const handleNext = () => {
    const areAllInputsFilled = Object.values(formData).every((input) => input !== ""); //* จำเป็นต้องกรอกข้อมูลทั้งหมด
    // const areAllRequiredInputsFilled = requiredInputs.every(input => formData[input] !== ''); //*กำหนดได้ว่า บังคับป้อนข้อมูลอะไรบ้าง
    if (areAllInputsFilled && addMaptype === 1 && badResquest === false) {
      if (activeItem < 3) {
        setActiveItem(activeItem + 2);
      } else {
        setActiveItem(activeItem + 1);
      }
    } else if (areAllInputsFilled && addMaptype !== 1 && badResquest === false) {
      if (activeItem === 1) {
        setActiveItem(activeItem + 1);
      } else {
        if (formData.EFieldSensor[0]) {
          setActiveItem(activeItem + 1);
          setError(false);
        } else {
          setError(true);
        }
      }
    } else {
      setError(true);
    }
  };

  const handleNext2 = () => {
    const areAllInputsFilled = Object.values(userData).every((input) => input !== "");
    //* จำเป็นต้องกรอกข้อมูลทั้งหมด

    if (areAllInputsFilled && confirmpassword === true && errorEmail === false && errorUsername === false) {
      setActiveItem(activeItem + 1);
    } else {
      setError(true);
    }
  };

  const handleBack = () => {
    if (addMaptype === 1) {
      if (activeItem === 3) {
        setActiveItem(activeItem - 2);
      } else {
        setActiveItem(activeItem - 1);
      }
    } else if (addMaptype !== 1) {
      setActiveItem(activeItem - 1);
    }
    setError(false);
  };

  const ActiveComponent = componentMapping[activeItem] || null;

  return (
    <>
      <OrganizationInput.Provider
        value={{
          selectedDate,
          setSelectedDate,
          badResquest,
          setBadRequest,
          confirmPassword,
          setConfirmPassword,
          setconfirmPassword,
          formData,
          setFormData,
          sensorData,
          setsensorData,
          userData,
          setUserData,
          addMaptype,
          setMaptype,
          error,
          setError,
          errorEmail,
          setErrorEmail,
          errorUsername,
          setErrorUsername,
        }}
      >
        {showComponent && (
          <div className="overlay" onClick={closeModal}>
            <div className="bodyModal ">
              <div
                className="modals-organization"
                onClick={handleModalClick}
                onMouseDown={() => setOnHole(false)}
                onMouseUp={() => setOnHole(false)}
              >
                <div className="menu-bar-addOrganization">Add new location (ADMIN)</div>

                <nav className="status-sidebar-addOrganization" align="center">
                  <div onClick={() => setActiveItem(1)} className={activeItem === 1 ? "active" : ""}>
                    Location information
                  </div>
                  {addMaptype !== 1 ? (
                    <div onClick={() => setActiveItem(2)} className={activeItem === 2 ? "active" : ""}>
                      E-Field sensor
                    </div>
                  ) : (
                    <></>
                  )}
                  <div onClick={() => setActiveItem(3)} className={activeItem === 3 ? "active" : ""}>
                    Admin Account
                  </div>
                  <div onClick={() => setActiveItem(3)} className={activeItem === 4 ? "active" : ""}>
                    Summary
                  </div>
                </nav>

                <div className="Router-addOrganization">{ActiveComponent && <ActiveComponent />}</div>

                <div className="menu-bar-addOrganization">
                  <button className="btn-addOrg close" onClick={closeModals}>
                    Close
                  </button>

                  {addMaptype !== 1 && (
                    <>
                      {activeItem <= 2 && (
                        <>
                          <button className="btn-addOrg next" onClick={handleNext}>
                            Next
                          </button>
                        </>
                      )}
                    </>
                  )}
                  {addMaptype === 1 && (
                    <>
                      {activeItem <= 2 && (
                        <>
                          {" "}
                          <button className="btn-addOrg next" onClick={handleNext}>
                            Next
                          </button>
                        </>
                      )}
                    </>
                  )}

                  {activeItem > 2 && (
                    <>
                      {" "}
                      <button className="btn-addOrg next" onClick={handleNext2}>
                        Next
                      </button>
                    </>
                  )}

                  {addMaptype !== 1 && (
                    <>
                      {activeItem !== 1 && (
                        <button className="btn-addOrg back" onClick={handleBack}>
                          Back
                        </button>
                      )}
                    </>
                  )}
                  {addMaptype === 1 && (
                    <>
                      {activeItem !== 1 && (
                        <button className="btn-addOrg back" onClick={handleBack}>
                          Back
                        </button>
                      )}
                    </>
                  )}
                  {activeItem >= 4 ? (
                    <button className="btn-addOrg next" onClick={SubmitData} disabled={onFetch} align="center">
                      {onFetch ? <span className="loader2"></span> : "SUBMIT"}
                    </button>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </OrganizationInput.Provider>
    </>
  );
}

function Component1() {
  return (
    <>
      <InformationOrg />
    </>
  );
}

function Component2() {
  const { addMaptype } = useContext(OrganizationInput);
  return (
    <>
      {addMaptype !== 1 && (
        <>
          <TypeMap />
        </>
      )}
    </>
  );
}

function Component4() {
  return (
    <>
      <User context={OrganizationInput} />
    </>
  );
}

function Component5() {
  return (
    <>
      <SummaryOrg />
    </>
  );
}

export { OrganizationInput };
