import * as React from "react";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Box, Button, CircularProgress, Grid, Icon, Modal, Tooltip } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditNoteIcon from "@mui/icons-material/EditNote";
import { useEffect } from "react";
import { useState } from "react";
import Cookies from "js-cookie";
import AddSettingLevel from "./AddSettingLevel.jsx";
import EditSettingLevel from "./EditSettingLevel.jsx";
import DeleteSettingLevel from "./DeleteSettingLevel.jsx";
import SortSettingLevel from "./SortSettingLevel.jsx";

const config = require("./../../../../configs/app");

const modalStyled = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: "80vw",
  maxWidth: 1000,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 3,
  borderRadius: 1,
};

const modalStyled2 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: "30vw",
  // maxWidth: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 3,
  borderRadius: 1,
};

var numeral = require("numeral");

function converterThanLess(x) {
  if (x === ">") {
    return "more than";
  } else if (x === "<") {
    return "less";
  } else if (x === ">=") {
    return "greater than equal to";
  } else if (x === "<=") {
    return "less than equal to";
  }
}

function converterZoneNameToNumber(zone, data) {
  if (zone === "0") {
    return 0;
  } else if (zone === "R") {
    return numeral(data.activeZoneR).format("0,0");
  } else if (zone === "A") {
    return numeral(data.activeZoneA).format("0,0");
  } else if (zone === "B") {
    return numeral(data.activeZoneB).format("0,0");
  } else {
    return zone;
  }
}

function Row(props) {
  const { row, organization, setEditingSettingId, setDeletingSetting, setDeletingSettingId, setIsDeleteModalOpen } =
    props;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell component="th" scope="row" align="center">
          {row.groupWarning}
        </TableCell>
        <TableCell align="center">
          {(row.activeZoneStart === "0" || row.activeZoneStart === "") &&
          (row.activeZoneEnd === "0" || row.activeZoneEnd === "")
            ? "No Active Zone"
            : converterZoneNameToNumber(row.activeZoneStart, organization) +
              " - " +
              converterZoneNameToNumber(row.activeZoneEnd, organization) +
              " Meters"}
        </TableCell>
        <TableCell align="center" sx={{ width: 50, whiteSpace: "nowrap" }}>
          {row.enableActiveZone ? (
            <Tooltip title="Active">
              <Icon>
                <CheckCircleIcon color="success" />
              </Icon>
            </Tooltip>
          ) : (
            <Tooltip title="Inactive">
              <Icon>
                <CancelIcon color="error" />
              </Icon>
            </Tooltip>
          )}
        </TableCell>
        <TableCell align="center" sx={{ width: 50, whiteSpace: "nowrap" }}>
          {row.eFieldConfig.eFieldSenSor && row.eFieldConfig.eFieldSenSor.warningPointId ? (
            <Tooltip title="Active">
              <Icon>
                <CheckCircleIcon color="success" />
              </Icon>
            </Tooltip>
          ) : (
            <Tooltip title="Inactive">
              <Icon>
                <CancelIcon color="error" />
              </Icon>
            </Tooltip>
          )}
        </TableCell>
        <TableCell align="center" sx={{ width: 50, whiteSpace: "nowrap" }}>
          {row.protein}
        </TableCell>
        <TableCell align="center" sx={{ width: 50, whiteSpace: "nowrap" }}>
          <Tooltip title="แก้ไข">
            <IconButton size="small" onClick={() => setEditingSettingId(row.warningId)}>
              <EditNoteIcon color="warning" />
            </IconButton>
          </Tooltip>
          <Tooltip title="ลบ">
            <IconButton
              size="small"
              onClick={() => {
                setDeletingSetting(row);
                setDeletingSettingId(row.warningId);
                setIsDeleteModalOpen(true);
              }}
            >
              <DeleteOutlineIcon color="error" />
            </IconButton>
          </Tooltip>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Grid container sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Detail
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  {row.enableActiveZone && (
                    <>
                      <Typography gutterBottom component="div">
                        M-Field
                      </Typography>
                      <Table size="small" aria-label="purchases">
                        <TableBody>
                          <TableRow>
                            <TableCell component="th" scope="row">
                              Active Zone
                            </TableCell>
                            <TableCell align="right">
                              {row.activeZoneStart} - {row.activeZoneEnd} :{" "}
                              {converterZoneNameToNumber(row.activeZoneStart, organization)}-
                              {converterZoneNameToNumber(row.activeZoneEnd, organization)} เมตร
                            </TableCell>
                          </TableRow>
                          {row.mFieldConfig.enableMFieldType && (
                            <TableRow>
                              <TableCell component="th" scope="row">
                                Type of Lightning
                              </TableCell>
                              <TableCell align="right">
                                {/* {row.mFieldConfig.mFieldType === 2 ? "Cloud to Cloud" : "Cloud to Ground"} */}
                                {row.mFieldConfig.mFieldType === 1
                                  ? "Cloud to Ground"
                                  : row.mFieldConfig.mFieldType === 2
                                  ? "Cloud to Cloud"
                                  : "-"}
                              </TableCell>
                            </TableRow>
                          )}
                          {row.mFieldConfig.enableMFieldCounter && (
                            <TableRow>
                              <TableCell component="th" scope="row">
                                Counter of Lightning
                              </TableCell>
                              <TableCell align="right">
                                greater than equal to {row.mFieldConfig.mFieldCounter} time within{" "}
                                {row.mFieldConfig.mFieldCounterInTime} minutes
                              </TableCell>
                            </TableRow>
                          )}
                          {row.mFieldConfig.enableMFieldAmplitude && (
                            <TableRow>
                              <TableCell component="th" scope="row">
                                Amplitude of Lightning
                              </TableCell>
                              <TableCell align="right">
                                {converterThanLess(row.mFieldConfig.mFieldAmplitudeSymbol)}{" "}
                                {row.mFieldConfig.mFieldAmplitude} kA
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </>
                  )}
                </Grid>
                <Grid item xs={12} md={6}>
                  {row.eFieldConfig.eFieldSenSor && row.eFieldConfig.eFieldSenSor.warningPointId && (
                    <>
                      <Typography gutterBottom component="div">
                        E-Field
                      </Typography>
                      <Table size="small" aria-label="purchases">
                        <TableBody>
                          <TableRow>
                            <TableCell component="th" scope="row">
                              Warning Point ID
                            </TableCell>
                            <TableCell align="right">{row.eFieldConfig.eFieldSenSor.warningPointId}</TableCell>
                          </TableRow>
                          {row.eFieldConfig.enableEFieldChange && (
                            <TableRow>
                              <TableCell component="th" scope="row">
                                Change in the electric field value
                              </TableCell>
                              <TableCell align="right">
                                more than {row.eFieldConfig.eFieldChangeAbsolute} V/m per second
                              </TableCell>
                            </TableRow>
                          )}
                          {row.eFieldConfig.enableEFieldValue && (
                            <TableRow>
                              <TableCell component="th" scope="row">
                                Electric field charge (absolute)
                              </TableCell>
                              <TableCell align="right">
                                {"more than "}
                                {row.eFieldConfig.eFieldValue}
                                {" V/m"}
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function CollapsibleTable({ level, organization }) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editingSettingId, setEditingSettingId] = useState(null);
  const [deletingSettingId, setDeletingSettingId] = useState(null);
  const [deletingSetting, setDeletingSetting] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isAddOpen, setIsAddOpen] = useState(false);
  const [isSortOpen, setIsSortOpen] = useState(false);

  const key = Cookies.get("pathName");

  const fetchDataWarningConfig = async () => {
    setLoading(true);
    const res = await fetch(`${config.urlLightningConfig}/api/v1/warning-config/keyName/${key}`);
    const response = await res.json();
    const data =
      response.status === 200
        ? response.message.map((item) => (item.levelWarning === level ? item : null)).filter((item) => item !== null)
        : [];
    setData(data);
    setLoading(false);
  };

  useEffect(() => {
    fetchDataWarningConfig();
  }, []);

  const handleCloseModal = () => {
    setEditingSettingId(null);
  };

  const handleCloseDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };

  const handleClose = () => {
    fetchDataWarningConfig();
    setIsAddOpen(false);
    setIsSortOpen(false);
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12} md={6} lg={6} display="flex" alignItems="center">
          <Typography fontWeight="bold">Warning Level {level}</Typography>
        </Grid>
        <Grid item xs={12} md={6} lg={6} display="flex" alignItems="center" justifyContent="end">
          <Grid mx={2}>
            <Button variant="outlined" color="inherit" size="small" onClick={() => setIsSortOpen(true)}>
              Sort
            </Button>
          </Grid>
          <Grid>
            <Button variant="contained" color="primary" size="small" onClick={() => setIsAddOpen(true)}>
              Add
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid mt={1}>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell align="center">Order</TableCell>
                <TableCell align="center">Active Zone</TableCell>
                <TableCell align="center">M-Field</TableCell>
                <TableCell align="center">E-Field</TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.length > 0 ? (
                data.map((row) => (
                  <Row
                    key={row.warningId}
                    row={row}
                    organization={organization}
                    setEditingSettingId={setEditingSettingId}
                    setDeletingSetting={setDeletingSetting}
                    setDeletingSettingId={setDeletingSettingId}
                    setIsDeleteModalOpen={setIsDeleteModalOpen}
                  />
                ))
              ) : (
                <TableRow>
                  <TableCell align="center" colSpan={7}>
                    {loading ? <CircularProgress color="inherit" size={20} /> : "No data"}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <Modal
          open={editingSettingId !== null}
          onClose={handleCloseModal}
          aria-labelledby="edit-setting-modal-title"
          aria-describedby="edit-setting-modal-description"
        >
          <Box sx={modalStyled}>
            <EditSettingLevel
              warningId={editingSettingId}
              fetchDataWarningConfig={fetchDataWarningConfig}
              onCloseModal={handleCloseModal}
              level={level}
              organization={organization}
            />
          </Box>
        </Modal>

        <Modal
          open={isDeleteModalOpen}
          onClose={handleCloseDeleteModal}
          aria-labelledby="delete-setting-modal-title"
          aria-describedby="delete-setting-modal-description"
        >
          <Box sx={modalStyled2}>
            <DeleteSettingLevel
              settingId={deletingSettingId}
              fetchDataWarningConfig={fetchDataWarningConfig}
              onCloseModal={handleCloseDeleteModal}
              setting={deletingSetting}
            />
          </Box>
        </Modal>

        <Modal
          open={isAddOpen}
          onClose={handleClose}
          aria-labelledby="delete-setting-modal-title"
          aria-describedby="delete-setting-modal-description"
        >
          <Box sx={modalStyled}>
            <AddSettingLevel
              onCloseModal={handleClose}
              level={level}
              fetchDataWarningConfig={fetchDataWarningConfig}
              organization={organization}
            />
          </Box>
        </Modal>

        <Modal
          open={isSortOpen}
          onClose={handleClose}
          aria-labelledby="delete-setting-modal-title"
          aria-describedby="delete-setting-modal-description"
        >
          <Box sx={modalStyled2}>
            <SortSettingLevel
              onCloseModal={handleClose}
              level={level}
              fetchDataWarningConfig={fetchDataWarningConfig}
              organization={organization}
            />
          </Box>
        </Modal>
      </Grid>
    </>
  );
}
