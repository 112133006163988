import React from "react";
import { Button, Grid } from "@mui/material";
import Swal from "sweetalert2";

const config = require("./../../../../configs/app");

const Delete = ({ settingId, onDelete, onCloseModal, keyNames }) => {
  const handleDelete = () => {
    fetch(`${config.urlSettingNotification}/api/v1/setting-notifications/${settingId}`, {
      method: "DELETE",
    })
      .then((response) => {
        if (response.ok) {
          onDelete(settingId);
          Swal.fire({
            icon: "success",
            title: "Delete Successful!",
            text: "data has been deleted",
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Delete Failed!",
            text: response.status,
          });
        }
      })
      .catch((error) => {
        console.error("เกิดข้อผิดพลาดในการลบข้อมูล:", error);
      });
  };

  const handleClose = () => {
    onCloseModal();
  };

  return (
    <div>
      <h2>ยืนยันการลบข้อมูล</h2>
      <Grid item xs={12} display="flex" justifyContent="center" mb={2}>
        <h3>ท่านต้องการลบข้อมูลการตั้งค่าการแจ้งเตือนของ {keyNames} ใช่หรือไม่?</h3>
      </Grid>
      <Grid item xs={12} display="flex" justifyContent="space-between" sx={{ gap: 2 }}>
        <Button variant="contained" sx={{ bgcolor: "#F37026" }} onClick={handleDelete} fullWidth>
          ยืนยันการลบ
        </Button>
        <Button variant="contained" sx={{ bgcolor: "#2E86C1" }} onClick={handleClose} fullWidth>
          ยกเลิก
        </Button>
      </Grid>
    </div>
  );
};

export default Delete;
