import { useContext, useState, useEffect } from "react";
import { OrganizationInput } from "./AddOrganization";
import DataTable from 'react-data-table-component';
import Cookies from 'js-cookie';
import apiUrls from "../../../ApiUrl";
import '../../Routers.css'
// const rowSelectCritera = row => row.fat > 6;
// const rowSelectCritera = row => row.warningPointId==="F56A45740044B0";
export default function TypeMap() {
    const [pending, setPending] = useState(true);
    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState([]);
    const [retryCount, setRetryCount] = useState(0);
    const [errorFetch, setErrorFetch] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const token = (Cookies.get('accessToken'));
    const { setsensorData, addMaptype, sensorData, formData, error, setError } = useContext(OrganizationInput);

    const fetchData = () => {
        const url = apiUrls.Efield;
        fetch(url,
            {
                method: 'GET', headers: {
                    Authorization: `Bearer ${token}`,
                }
            })
            .then(res => res.json())
            .then(data => {
                if (data.status === 200) {
                    setIsLoaded(true);
                    setItems(data);
                } else {
                    setIsLoaded(false);
                    console.log(data);
                }
            })
            .catch(error => {
                setIsLoaded(false);
                setErrorFetch(error);
                const retryDelay = 3000;
                setTimeout(() => setRetryCount(retryCount + 1), retryDelay);
            });
    };

    useEffect(() => {
        fetchData();
    }, [retryCount]);

    const columns = [
        {
            name: 'E-Field Name',
            selector: row => row.warningPointName,
            sortable: true,
            width: '130px'
        },
        {
            name: 'Warning Point',
            selector: row => row.warningPointId,
            sortable: true,
            width: '140px'
        },
        {
            name: 'Latitude ',
            selector: row => row.lat,
            width: '80px'
        },

        {
            name: 'Longitude ',
            selector: row => row.lon,
            width: '90px'
        },
    ];

    //   console.log(items.message.find((item)=>item.warningPointId === "D17E0170E8B4E4"))
    //   const selectCheck = items.message.filter((item)=> item.warningPointId === "sensorData")
    // const selectableRowSelected = (row) => sensorData[row.warningPointId];

    const CustomLoader = () => (
        <>
            <div style={{ margin: '180px' }}>
                <span className="loader"></span>
                <p>{errorFetch.message}</p>
            </div>
        </>
    );

    const handleRowSelected = (rows) => {
        setError(false);
        const dataGet = rows.selectedRows.map((item) => item.warningPointId)
        setsensorData(dataGet);
    };
    // console.log(formData.EFieldSensor)
    if (token != null && isLoaded === true) {
        const data = items.message;
        // const filteredDatas = data.filter(item => sensorData.includes(item.warningPointId));
        // console.log(filteredDatas)
        const filteredData = data.filter((item) => {
            const { warningPointId, warningPointName, lat, lon } = item;
            const searchValue = searchQuery.toLowerCase();
            return (warningPointId.toLowerCase().includes(searchValue) || warningPointName.toLowerCase().includes(searchValue)
                || lat.toString().toLowerCase().includes(searchValue) || lon.toString().toLowerCase().includes(searchValue));
        });

        // const selectedDataMap = filteredDatas.reduce((map, item) => {
        //     map[item.warningPointId] = true;
        //     return map;
        // }, {});

        return (
            <form>
                {addMaptype === 2 && <p style={{ fontWeight: "bold" }}>E-Field sensor</p>}
                {addMaptype === 3 && <p style={{ fontWeight: "bold" }}>E-Field sensor</p>}
                <input className="Search" type="text" placeholder="Search" onChange={e => setSearchQuery(`${e.target.value}`)} />
                <div className='dataTableContainer'>
                    <DataTable
                        columns={columns}
                        data={filteredData}
                        selectableRows
                        // selectableRowSelected={selectableRowSelected}
                        dense
                        onSelectedRowsChange={handleRowSelected}
                    />
                </div>
                {error === true && (
                    <div className="alert-input" align="center">
                        Please select some item.
                    </div>
                )}
                {/* <br /> */}
                {/* <label >activeZone EField (เมตร) : </label>
                <input style={{ height: "20px", width: "35%" }} type="text" /> */}
            </form>
        );
    }

    else {
        return (
            <DataTable
                progressPending={pending}
                progressComponent={<CustomLoader />}
            />
        );
    }
}