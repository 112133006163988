import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { useParams } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import {
  TextField,
  Button,
  Switch,
  FormControlLabel,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Alert,
  Collapse,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const config = require("./../../../../configs/app");

const textFiledStyle = {
  my: 1,
};

function Add({ onCloseModal, fetchUser }) {
  const [roles, setRoles] = useState([]);
  const [error, setError] = useState({
    status: false,
    message: "",
  });
  const key = Cookies.get("pathName");
  const { control, handleSubmit } = useForm();

  const onSubmit = (formData) => {
    addDataToSystem(formData);
  };

  useEffect(() => {
    fetch(`${config.urlAuth}/api/v1/role`)
      .then((res) => res.json())
      .then((res) => setRoles(res))
      .catch((err) => console.error(`Error Fetch Role: ${err}`));
  }, []);

  const addDataToSystem = (data) => {
    // console.log(data);
    if (data.password !== data.confirmPassword) {
      setError({
        status: true,
        message: "Password Not Match",
      });
      return;
    }

    fetch(`${config.urlAuth}/api/v1/user/${key}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then(async (res) => {
        if (res.ok) {
          fetchUser();
          onCloseModal();
        } else {
          const err = await res.json();
          setError({
            status: true,
            message: err.message,
          });
          console.error("Error adding data:", err);
        }
      })
      .catch((error) => {
        console.error("Error adding data:", error);
      });
  };

  const handleCloseModal = () => {
    onCloseModal();
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container>
          <h2>Add User : {key}</h2>
          <Controller
            name="username"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                label="Username"
                type="text"
                autoComplete="new-password"
                fullWidth
                sx={textFiledStyle}
                {...field}
              />
            )}
          />
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <TextField
                label="Email"
                type="email"
                autoComplete="new-password"
                fullWidth
                sx={textFiledStyle}
                {...field}
              />
            )}
          />
          <Controller
            name="password"
            control={control}
            render={({ field }) => (
              <TextField
                label="Password"
                type="password"
                autoComplete="new-password"
                fullWidth
                sx={textFiledStyle}
                {...field}
              />
            )}
          />
          <Controller
            name="confirmPassword"
            control={control}
            render={({ field }) => (
              <TextField
                label="Confirm Password"
                type="password"
                autoComplete="new-password"
                fullWidth
                sx={textFiledStyle}
                {...field}
              />
            )}
          />
          <Grid container spacing={2} display="flex" justifyContent="space-between">
            <Grid item xs={6} display="flex" alignItems="center" justifyContent="center">
              <Controller
                name="firstName"
                control={control}
                render={({ field }) => (
                  <TextField
                    label="First Name"
                    type="text"
                    autoComplete="new-password"
                    fullWidth
                    sx={textFiledStyle}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6} display="flex" alignItems="center" justifyContent="center">
              <Controller
                name="lastName"
                control={control}
                render={({ field }) => (
                  <TextField
                    label="Last Name"
                    type="text"
                    autoComplete="new-password"
                    fullWidth
                    sx={textFiledStyle}
                    {...field}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Controller
            name="phoneNumber"
            control={control}
            render={({ field }) => (
              <TextField
                label="Phone Number"
                type="text"
                autoComplete="new-password"
                fullWidth
                sx={textFiledStyle}
                {...field}
              />
            )}
          />
          <Controller
            name="RoleId"
            control={control}
            render={({ field }) => (
              <Select {...field} fullWidth>
                {roles.status === 200 &&
                  roles.message.map((item, index) => (
                    <MenuItem key={index} value={item.roleId}>
                      {item.name} {item.description}
                    </MenuItem>
                  ))}
              </Select>
            )}
            defaultValue={0}
          />
          <Controller
            name="viewEField"
            control={control}
            render={({ field }) => <FormControlLabel control={<Switch {...field} />} label="View E-Field" />}
          />
          <Controller
            name="activeStatus"
            control={control}
            render={({ field }) => <FormControlLabel control={<Switch {...field} />} label="Active" />}
          />
        </Grid>
        <Collapse in={error.status}>
          <Alert
            variant="outlined"
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setError({
                    status: false,
                    message: "",
                  });
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {error.message}
          </Alert>
        </Collapse>
        <Grid container spacing={2} display="flex" justifyContent="space-between" mt={1}>
          <Grid item xs={6} display="flex" alignItems="center" justifyContent="center">
            <Button variant="outlined" color="error" fullWidth onClick={handleCloseModal}>
              CLOSE
            </Button>
          </Grid>
          <Grid item xs={6} display="flex" alignItems="center" justifyContent="center">
            <Button type="submit" variant="contained" color="success" fullWidth>
              SUBMIT
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
}

export default Add;
