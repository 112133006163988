import React, { useEffect, useState, createContext, useContext } from "react";
import "../Routers.css";
import DataTable from "react-data-table-component";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import Container from "../Container";
import UpDateOrgan from "./add-organization/AddOrganization";
import ConfirmDelete from "../ConfirmDelete";
import apiUrls from "../../ApiUrl";
import Swal from "sweetalert2";
import { showSidebar } from "../../kumwell";
import { customStyles } from "../DataTableStyle";

const OrganizationContext = createContext();

export default function Organization({ update, create, remove, role }) {
  const [showDelete, setShowDelete] = useState(false);
  const [showComponent, setShowComponent] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [pending, setPending] = useState(true);
  const [items, setItems] = useState([]);
  const [retryCount, setRetryCount] = useState(0);
  const [errorFetch, setErrorFetch] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const token = Cookies.get("accessToken");
  const pathName = Cookies.get("pathName");
  const [key, setKey] = useState("");
  const { auth, setOrganization_Setting, setRouteKeyName } = useContext(showSidebar);
  const navigate = useNavigate();

  const handleClick = () => {
    setShowComponent(!showComponent);
  };

  const openDelete = (keyName) => {
    setKey(keyName);
    setShowDelete(true);
  };

  const closeModals = () => {
    setShowDelete(false);
  };

  const fetchData = () => {
    // console.log(apiUrls.OrganizationUrl_byKeyName)
    fetch(apiUrls.OrganizationUrl_byKeyName, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === 200) {
          setIsLoaded(true);
          setItems(data);
        } else {
          setIsLoaded(false);
        }
      })
      .catch((error) => {
        setIsLoaded(false);
        setErrorFetch(error);
        const retryDelay = 3000;
        setTimeout(() => setRetryCount(retryCount + 1), retryDelay);
      });
  };

  async function handleDelete(keyName) {
    try {
      apiUrls.setRequestOrganization(keyName);
      setDeleteLoading(true);
      const response = await fetch(apiUrls.OrganizationUrl_DELETE, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.ok) {
        Swal.fire({
          icon: "success",
          title: "Delete Successful!",
          // text: 'data has been deleted'
        });
        fetchData();
      } else {
        Swal.fire({
          icon: "success",
          title: "Delete Fail",
          // text: response.status
        });
      }
    } catch (error) {
      console.error("Failed to delete item:", error);
    } finally {
      setShowDelete(false);
      setDeleteLoading(false);
      apiUrls.setRequestOrganization("");
    }
  }
  const settingWithData = (data) => {
    Cookies.set("pathName", data.keyName);
    setOrganization_Setting(data);
    navigate(`/organization/setting-organization/${data.keyName}`);
  };
  // useEffect(() => {
  //     if (role) {
  //         apiUrls.setUserRequest1(auth.keyName);
  //         fetchData();
  //     } else { fetchData(); }
  // }, [retryCount]);
  useEffect(() => {
    if (role) {
      apiUrls.setKeyNameReport(auth.keyName);
      fetchData();
    } else {
      fetchData();
    }
  }, [retryCount]);

  const columns = [
    {
      name: "#",
      selector: (row) => row.id,
      sortable: true,
      width: "60px",
    },
    {
      name: "Location",
      selector: (row) => row.nameLocation,
      sortable: true,
      width: "150px",
    },
    {
      name: "Latitude , Longitude ",
      cell: (row) => (
        <li>
          {row.lat}, {row.lon}
        </li>
      ),
      width: "200px",
    },
    {
      name: "key name",
      selector: (row) => row.keyName,
      sortable: true,
      width: "130px",
    },

    // {
    //   name: "address",
    //   selector: (row) => row.address,
    //   sortable: true,
    //   width: "500px",
    // },
    {
      name: "mapType",
      cell: (row) => {
        if (row.mapType === 1) {
          return <span>M-Field</span>;
        } else if (row.mapType === 2) {
          return <span>E-Field</span>;
        } else if (row.mapType === 3) {
          return <span>M-Field , E-Field</span>;
        } else {
          return <span>ไม่ระบุ</span>;
        }
      },
      width: "160px",
    },

    {
      name: "Expiration date",
      cell: (row) => {
        const dateObject = new Date(row.packageEnd);
        const day = dateObject.getUTCDate();
        const month = dateObject.getUTCMonth() + 1;
        const year = dateObject.getUTCFullYear();
        const formattedDate = `${day}/${month}/${year}`;
        return (
          <>
            {row.activeStatus ? (
              <span style={{ color: "green" }}>{formattedDate}</span>
            ) : (
              <span style={{ color: "red" }}>{formattedDate}</span>
            )}
          </>
        );
      },
      width: "150px",
    },

    {
      name: "Status",
      cell: (row) => (
        <>
          {row.activeStatus ? (
            <span style={{ color: "green" }}>Active</span>
          ) : (
            <span style={{ color: "red" }}>Inactive</span>
          )}
        </>
      ),
      width: "auto",
    },
  ];

  if (update) {
    columns.push({
      name: "",
      cell: (row) => (
        <>
          <button
            className="setting-btn"
            onClick={() => {
              settingWithData(row);
            }}
          >
            Setting
          </button>
        </>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      width: "90px",
    });
  }

  if (remove) {
    columns.push({
      name: "",
      cell: (row) => (
        <>
          <button className="delete-btn" onClick={() => openDelete(row.keyName)}>
            Delete
          </button>
        </>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      width: "90px",
    });
  }

  const CustomLoader = () => (
    <>
      <div style={{ padding: "40px" }}>
        <span className="loader"></span>
        <p>{errorFetch.message}</p>
      </div>
    </>
  );

  if (token != null && isLoaded === true) {
    const data = role ? [items.message] : items.message;
    const dataWithIds = data.map((item, index) => ({ id: index + 1, ...item }));
    const filteredData = dataWithIds.filter((item) => {
      const { nameLocation, keyName } = item;
      const searchValue = searchQuery.toLowerCase();
      return keyName.toLowerCase().includes(searchValue) || nameLocation.toLowerCase().includes(searchValue);
    });
    return (
      <OrganizationContext.Provider
        value={{ showComponent, setShowComponent, items, showDelete, setShowDelete, setRetryCount, retryCount }}
      >
        <Container
          title={<>Locations </>}
          btn={create ? <>Add Location</> : null}
          action={handleClick}
          components={
            <>
              {" "}
              {showDelete ? (
                <ConfirmDelete
                  topic="Organization"
                  closeModals={closeModals}
                  confirm={() => handleDelete(key)}
                  loadding={deleteLoading}
                />
              ) : (
                <></>
              )}
              <UpDateOrgan />
              <input
                className="Search"
                type="text"
                onChange={(e) => setSearchQuery(e.target.value)}
                placeholder="Search"
              />
              <DataTable
                columns={columns}
                data={filteredData}
                pagination
                fixedHeader
                customStyles={customStyles}
                highlightOnHover
                noHeader
                striped
              />
            </>
          }
        />
      </OrganizationContext.Provider>
    );
  } else {
    return (
      <>
        <Container
          title={<>Locations</>}
          components={
            <>
              <DataTable progressPending={pending} progressComponent={<CustomLoader />} />
            </>
          }
        />
      </>
    );
  }
}

export { OrganizationContext };
