import './Routers.css';
export default function ConfirmDelete({ topic, confirm, closeModals, loadding }) {
    return (
        <>
            <div className="overlay">
                <div className="bodyModal">
                    <div className="modals-password" align="center">
                        <img src="/image/close.png" alt="cross" />
                        <h3>Delete {topic}?</h3>
                        <p>Are you sure you want to delete</p>
                        <button className="Cancel-btn" onClick={closeModals}>Cancel</button>
                        <button className="Delete-btn" onClick={confirm} >{loadding ? <span className='loader3'></span> : <>Delete</>}</button>
                    </div>
                </div>
            </div>
        </>
    );
};


