import React, { useEffect, useState } from "react";
import { Button, Divider, FormControl, Grid, Snackbar, TextField, Typography } from "@mui/material";

const config = require("./../../configs/app");

function UrlMField({ openAlert }) {
  const [urlMField, setUrlMField] = useState("");
  const [isEdit, setIsEdit] = useState(false);

  const fetchUrlMField = () => {
    fetch(`${config.urlLightningConfig}/api/v1/config-url/`, {
      method: "GET",
    })
      .then(async (res) => {
        if (res.ok) {
          const data = await res.json();
          //   console.log(data);
          setUrlMField(data.message.find((item) => item.name === "urlMField").value);
        } else {
          const err = await res.json();
          console.error(err);
          openAlert({
            open: true,
            message: `Error Fetching Data: ${err.message}`,
            severity: "error",
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchUrlMField();
    setIsEdit(false);
  }, []);

  const openEdit = () => {
    console.log("openEdit");
    openAlert({
      open: true,
      message: "Please edit URL M-Field",
      severity: "warning",
    });
    setIsEdit(true);
  };

  const handleSave = () => {
    fetch(`${config.urlLightningConfig}/api/v1/config-url/urlMField`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        value: urlMField,
      }),
    })
      .then(async (res) => {
        if (res.ok) {
          //   const data = await res.json();
          //   console.log(data);
          fetchUrlMField();
          setIsEdit(false);
          openAlert({
            open: true,
            message: "URL M-Field has been updated",
            severity: "success",
          });
        } else {
          const err = await res.json();
          console.error(err);
          openAlert({
            open: true,
            message: `Error Fetching Data: ${err.message}`,
            severity: "error",
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <Divider
        sx={{
          marginTop: 2,
        }}
      ></Divider>

      <Typography fontSize={"20px"} mt={2}>
        Setting URL M-Field
      </Typography>

      {urlMField ? (
        <Grid container spacing={2}>
          <Grid item xs={11} mt={2}>
            <FormControl fullWidth>
              <TextField
                fullWidth
                type="text"
                size="small"
                label="URL M-Field"
                variant="outlined"
                required
                error={isEdit}
                InputProps={{
                  readOnly: !isEdit,
                }}
                value={urlMField}
                onChange={(e) => {
                  setUrlMField(e.target.value);
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={1} mt={2}>
            <Button
              variant={isEdit ? "contained" : "outlined"}
              fullWidth
              color={isEdit ? "success" : "warning"}
              disableRipple
              onClick={() => {
                if (isEdit) {
                  handleSave();
                } else {
                  openEdit();
                }
              }}
            >
              {isEdit ? "Save" : "Edit"}
            </Button>
          </Grid>
        </Grid>
      ) : null}
    </>
  );
}

export default UrlMField;
