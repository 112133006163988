import { useContext, useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { showSidebar } from '../../kumwell';
import Cookies from 'js-cookie';
function SidebarItem({ title, route }) {
    const pathName = Cookies.get("pathName");
    const [status, setStatus] = useState('');
    const sidebarItems = document.querySelectorAll('.onSelect');
    const {setShowSidebar} = useContext(showSidebar)
    sidebarItems.forEach((item) => {
        item.addEventListener('click', () => {
            sidebarItems.forEach((item) => {
                item.classList.remove('active');
            });
            item.classList.add('active');
        });
    });

    useEffect(() => {
        const interval = setInterval(() => {
            if (window.location.pathname === route) { setStatus('active'); }
            if (window.location.pathname !== route && window.location.pathname !== `/organization/setting-organization/${pathName}` && window.location.pathname !== `/report/warningReport/${pathName}`) { setStatus('unActive'); }
        });
        return () => {
            clearInterval(interval);
        };
    }, [route, pathName])

    return (
        <>
            <Link to={route} onClick={()=>setShowSidebar("sidebar-responsive")}><div className={`onSelect ${status}`}><span className="sidebar-font">{title}</span></div> </Link>
        </>
    );
}

export default SidebarItem;